import react, { useEffect } from 'react';
import { useState } from 'react';
import apiClient from '../../utils/API';
import DropdownSearch from '../DropdownSearch';
import sortBy from 'lodash/sortBy'

/**
 * 
 */
export const DegreeVariant = {
    ById: 'ById',
    ByAbbreviation: 'ByAbbreviation'
};

/**
 * 
 */
export default ({ listItems, valueVariant = DegreeVariant.ById, ...props}) => {

    const [items,setItems] = useState([]);

    useEffect(()=>{
        (async ()=>{
            const degrees = await apiClient.get(`/degree-program/degree-programs`);
            const sortedDegrees = sortBy(degrees, x => x.SortOrder);
            const degreeItems = sortedDegrees.map(r => ({            
                label: `${r.Name} (${r.Abbreviation})`,
                value: valueVariant === DegreeVariant.ByAbbreviation ? r.Abbreviation : r.Id
            }));
            setItems(degreeItems);
        })();
    },[]);

    return <DropdownSearch onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} {...props} placeholder="Degree" listItems={items}/>

}
