import { groupBy } from "lodash";
import TranscriptAnswer from "../TranscriptAnswer";

/**
 * prompt for an entire transcript questions' worth of answers
 * 
 * the way we present transcripts is unlike other questions, since we mingle the different answers together into one UI
 * component (to select a delivery method)
 * 
 * @param {*} param0 
 * @returns {Component}
 */
function TranscriptQuestion({ section, disabled, transcriptField, isRequired, formLink, titleHeader, verifyOptionLabel }) {

    const answers = section.answers?.getAll(transcriptField.name);
    const qualifiedGroups = Object.values(groupBy(answers, x => x?.SchoolAnswerId));
    //const isReviewed = true; //transcriptField.level !== "Document";

    return (
        <div  >           
            {qualifiedGroups.map((answerGroup, i) =>
                <TranscriptAnswer schoolAnswers={answerGroup} 
                    formLink={formLink}
                    disabled={disabled} 
                    index={i} 
                    question={transcriptField} 
                    isRequired={isRequired} 
                    verifyOptionLabel={verifyOptionLabel}
                    titleHeader={titleHeader}/>
            )}
        </div>
    );

}

export default TranscriptQuestion;