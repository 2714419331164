import React, { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import Input from "../Input";
import { repeatableFieldName, } from "../Utility/RepeatableField";
import HiddenInput from "../Utility/HiddenInput";
import AnswerLock from "./status/AnswerLock";
import { FieldRow, FieldSlot } from '../Forms/FieldSection';
import { AnswerTypeCollegeProps, UNLISTED_COLLEGECODE } from '../../store/form/fields';
import DegreeDropdown, { DegreeVariant } from '../DataDriven/DegreeDropdown';
import { useEffect, useState } from "react";
import DropdownSearch from "../DropdownSearch";
import apiClient from "../../utils/API";



/**
 * prompt for a college the student has already attended for some period of time
 * 
 * @param {*} param0 
 */
function CollegeHistoryAnswer({ answer, disabled, isPrior = false }) {

    const methods = useFormContext({ mode: "onBlur", });
    const { getValues, watch, setValue } = methods;

    const i = answer.Index;
    const name = answer.FieldKey;
    const idFieldName = repeatableFieldName(name, i, AnswerTypeCollegeProps.ID);
    const currentSchoolCodeFieldName = repeatableFieldName(name, i,  AnswerTypeCollegeProps.CODE);
    const currentCollegeNameOtherFieldName = repeatableFieldName(name, i, AnswerTypeCollegeProps.UNLISTED_NAME);
    const currentStartTermFieldName = repeatableFieldName(name, i, AnswerTypeCollegeProps.START_TERM);
    const currentStartYearFieldName = repeatableFieldName(name, i, AnswerTypeCollegeProps.START_YEAR);
    const currentStopTermFieldName = repeatableFieldName(name, i, AnswerTypeCollegeProps.END_TERM);
    const currentStopYearFieldName = repeatableFieldName(name, i, AnswerTypeCollegeProps.END_YEAR);  
    const currentDegreeFieldName = repeatableFieldName(name, i, AnswerTypeCollegeProps.DEGREE);
    const currentCreditsFieldName = repeatableFieldName(name, i, AnswerTypeCollegeProps.CREDITS);
       
    const collegeCode = watch(currentSchoolCodeFieldName);
    const isUnlistedCollegeSelected = collegeCode?.value === UNLISTED_COLLEGECODE;

    const termOptions = [
        { label: 'Fall', value: 'Fall' },
        { label: 'Winter', value: 'Winter' },
        { label: 'Spring', value: 'Spring' },
        { label: 'Summer', value: 'Summer' }
    ];
    const currentYear = (new Date()).getFullYear();
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
    const yearOptions = range(currentYear + 3, currentYear - 100, -1).map((year) => {
        return {
            label: year,
            value: year,
        };
    });

    var [colleges, setColleges] = useState([]);
    useEffect(() => {
        if (!answer.Year)
            return;

        (async () => {
            const options = await apiClient.getOptionsAsListItems(answer.QuestionKey, answer.Year);
            setColleges(options);
        })();
        
    }, [answer.Year]);

    const isDisabled = disabled || answer.IsLocked;

    // if (answer === null || answer.IsLoading || colleges.length === 0) {
    //     return (
    //         <Fragment key={answer.Id}>
    //         </Fragment>
    //     );
    // }

    return (
        <Fragment key={answer.Id}>
            <div className="rhythm">
                <FieldRow >
                    <FieldSlot>
                        <HiddenInput name={idFieldName} value={answer.Id}/>
                        <DropdownSearch isRequired={!isUnlistedCollegeSelected} 
                            placeholder="College" 
                            name={currentSchoolCodeFieldName}                     
                            listItems={colleges}
                            includeUnlisted={true} 
                            inputLabel={<>College/University Name <AnswerLock answer={answer} /> </>}
                            variant='outlined' />                                           
                    </FieldSlot>
                    {isUnlistedCollegeSelected &&
                        <FieldSlot>
                            <Input disabled={isDisabled} name={currentCollegeNameOtherFieldName}
                                defaultValue={getValues(currentCollegeNameOtherFieldName)}
                                key={currentCollegeNameOtherFieldName}
                                inputLabel="Unlisted College/University Name"
                                variant='outlined'
                                placeholder='College/University Name'
                                isRequired={true} />
                        </FieldSlot>
                    }


                    <FieldSlot >
                        <FieldRow >
                            <FieldSlot>
                                <div>Start Date <span style={{ color: 'red' }}>&nbsp;*</span></div>
                                <div>
                                    <DropdownSearch isRequired={true} 
                                        placeholder="Term" 
                                        name={currentStartTermFieldName}                     
                                        listItems={termOptions} 
                                          /> 
                                    <DropdownSearch isRequired={true}
                                        placeholder="Year" 
                                        name={currentStartYearFieldName}                     
                                        listItems={yearOptions} 
                                        />     
                                    
                                </div>                                                                          
                            </FieldSlot>
                            {isPrior &&
                                <FieldSlot>
                                    <div>Stop Date</div>
                                    <div>
                                        <DropdownSearch isRequired={true} 
                                            placeholder="Term" 
                                            name={currentStopTermFieldName}                     
                                            listItems={termOptions} 
                                            /> 
                                        <DropdownSearch isRequired={true}
                                            placeholder="Year" 
                                            name={currentStopYearFieldName}                     
                                            listItems={yearOptions} 
                                            />   
                                    </div>                                     
                                </FieldSlot>
                            }
                        </FieldRow>

                    </FieldSlot>
                    <FieldSlot>
                        <FieldRow>
                            <FieldSlot>
                                <DegreeDropdown disabled={isDisabled} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                    name={currentDegreeFieldName} key={currentDegreeFieldName} defaultValue={getValues(currentDegreeFieldName)}
                                    inputLabel='Degree Earned' variant='outlined' valueVariant={DegreeVariant.ByAbbreviation} />
                            </FieldSlot>
                            <FieldSlot>
                                <Input disabled={isDisabled} name={currentCreditsFieldName} defaultValue={getValues(currentCreditsFieldName)} key={currentCreditsFieldName} inputLabel='Credits Earned' variant='outlined' />
                            </FieldSlot>
                        </FieldRow>
                    </FieldSlot>
                </FieldRow>
            </div>
        </Fragment>
    );
};

export default CollegeHistoryAnswer;