import ReadOnlyField from '../ReadOnlyField';
import { FIELDS, getField } from '../../store/form/fields';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actions } from '../../store';
import Checkbox from '../Checkbox';
import ConvertCode, { DegreePlans } from '../Utility/ConvertCode';
import CollegeDisplay from '../CollegeDisplay';

export default function AppSnapshotChild ({ year }) {

  const formState = useSelector((state) => state.form);
  
  const fieldKeys = [
    FIELDS.FIRST_NAME,
    FIELDS.LAST_NAME,
    FIELDS.BIRTHDATE,
    FIELDS.OTHER_NAMES,
    FIELDS.GENDER__FEMALE,
    FIELDS.GENDER__MALE,
    FIELDS.GENDER__TRANSMAN,
    FIELDS.GENDER__TRANSWOMAN,
    FIELDS.GENDER__NON_BINARY,
    FIELDS.GENDER__NA,
    FIELDS.PRONOUNS__MALE,
    FIELDS.PRONOUNS__FEMALE,
    FIELDS.PRONOUNS__NEUTRAL,
    FIELDS.PRONOUNS__OTHER,
    FIELDS.ALTERNATE_PHONE,
    FIELDS.HOME_PHONE,
    FIELDS.PERMANENT_ADDRESS,
    FIELDS.MAILING_ADDRESS,
    FIELDS.PRIMARY_CONTACT,
    FIELDS.SSN,
    FIELDS.CITIZENSHIP_STATUS,
    FIELDS.MARITAL_STATUS,
    FIELDS.PARENTS_MARITAL_STATUS,
    FIELDS.ED_LEVEL_FATHER,
    FIELDS.ED_LEVEL_MOTHER,
    FIELDS.HISPANIC,
    FIELDS.ETHNICITY__AM_INDIAN,
    FIELDS.ETHNICITY__ASIAN,
    FIELDS.ETHNICITY__BLACK,
    FIELDS.ETHNICITY__HAWAIIAN_PI,
    FIELDS.ETHNICITY__WHITE,
    FIELDS.ETHNICITY__NA,    
    FIELDS.OREGON_RESIDENT,
    FIELDS.WORKING__CURRENT,
    FIELDS.WORKING__NEXT_YEAR,
    FIELDS.HIGHSCHOOL,
    FIELDS.HIGHSCHOOL_GRAD_DATE,
    FIELDS.COLLEGE_CHOICE,
    FIELDS.COLLEGE_STANDING,
    FIELDS.COLLEGE_DUAL_ENROLLED,
    FIELDS.FINISHED_COMM_COLLEGE,
    FIELDS.FINISHED_BACHELORS_DEGREE,
    FIELDS.UNDERGRAD_GPA,
    FIELDS.GRADUATE_GPA,
    FIELDS.DEGREES,
    FIELDS.PAST_COLLEGE,

    FIELDS.CHILD_CARE_PROVIDER__NAME,
    FIELDS.CHILD_CARE_PROVIDER__ADDRESS,
    FIELDS.CHILD_CARE_PROVIDER__LISTED,
    FIELDS.CHILD_CARE_PROVIDER__REGISTERED,
    FIELDS.CHILD_CARE_PROVIDER__PARENT,
    FIELDS.CHILD_CARE_PROVIDER__STATE,
    FIELDS.CHILD_CARE_PROVIDER__CITY,
    FIELDS.CHILD_CARE_PROVIDER__ZIP,
    FIELDS.CHILD_CARE_PROVIDER__PHONE,
    FIELDS.CHILD_CARE_PROVIDER__START_DATE,
    FIELDS.CHILD_CARE_PROVIDER__NEW,
   
    FIELDS.CHILD_CARE_PROVIDER2__STATE,
    FIELDS.CHILD_CARE_PROVIDER2__CITY,
    FIELDS.CHILD_CARE_PROVIDER2__ZIP,
    FIELDS.CHILD_CARE_PROVIDER2__PHONE,
    FIELDS.CHILD_CARE_PROVIDER2__START_DATE,
    FIELDS.CHILD_CARE_PROVIDER2__NEW,
    FIELDS.CHILD_CARE_PROVIDER2__NAME,
    FIELDS.CHILD_CARE_PROVIDER2__ADDRESS,
    FIELDS.CHILD_CARE_PROVIDER2__LISTED,
    FIELDS.CHILD_CARE_PROVIDER2__REGISTERED,
    FIELDS.CHILD_CARE_PROVIDER2__PARENT,
  ];

  useEffect(() => {
    actions.fetchBasicFields(fieldKeys, year);
  },[]);

  const get = (fieldKey) => formState.fields[fieldKey]?.value;

  const getAddress = (fieldKey) => {
    const street = formState.fields[fieldKey]?.Address.value;
    const city = formState.fields[fieldKey]?.CityId.value;
    const county = formState.fields[fieldKey]?.CountyId.value;
    const zip = formState.fields[fieldKey]?.Zip.value;

    return { street, city, county, zip};
  };

  const getNames = (fieldKey) => {
    const stateObject = formState.fields[fieldKey];
    let string = '';
    for (const i in stateObject) {
        string += stateObject[i].FirstName.value+' '+stateObject[i].LastName.value+'\n'
    }
    return string;
  };

  const getEdLevel = (fieldKey1, fieldKey2) => {
    return { parent1: get(fieldKey1), parent2: get(fieldKey2) };
  };

  const getSchoolCode = (fieldKey) => {
    return formState.fields[fieldKey]?.SchoolCode.value;
  }

  const provider1 = {
    name: get(FIELDS.CHILD_CARE_PROVIDER__NAME),
    street: get(FIELDS.CHILD_CARE_PROVIDER__ADDRESS),
    listed: get(FIELDS.CHILD_CARE_PROVIDER__LISTED),
    registered: get(FIELDS.CHILD_CARE_PROVIDER__REGISTERED),
    parent: get(FIELDS.CHILD_CARE_PROVIDER__PARENT),
    state: get(FIELDS.CHILD_CARE_PROVIDER__STATE),
    city: get(FIELDS.CHILD_CARE_PROVIDER__CITY),
    zip: get(FIELDS.CHILD_CARE_PROVIDER__ZIP),
    phone: get(FIELDS.CHILD_CARE_PROVIDER__PHONE),
    start: get(FIELDS.CHILD_CARE_PROVIDER__START_DATE),
    isNew: get(FIELDS.CHILD_CARE_PROVIDER__NEW),
  };

  const provider2 = {
    name: get(FIELDS.CHILD_CARE_PROVIDER2__NAME),
    street: get(FIELDS.CHILD_CARE_PROVIDER2__ADDRESS),
    listed: get(FIELDS.CHILD_CARE_PROVIDER2__LISTED),
    registered: get(FIELDS.CHILD_CARE_PROVIDER2__REGISTERED),
    parent: get(FIELDS.CHILD_CARE_PROVIDER2__PARENT),
    state: get(FIELDS.CHILD_CARE_PROVIDER2__STATE),
    city: get(FIELDS.CHILD_CARE_PROVIDER2__CITY),
    zip: get(FIELDS.CHILD_CARE_PROVIDER2__ZIP),
    phone: get(FIELDS.CHILD_CARE_PROVIDER2__PHONE),
    start: get(FIELDS.CHILD_CARE_PROVIDER2__START_DATE),
    isNew: get(FIELDS.CHILD_CARE_PROVIDER2__NEW),
  };

  return (
    <>
      <ReadOnlyField 
        name="name" 
        value={get(FIELDS.FIRST_NAME)+' '+get(FIELDS.LAST_NAME)}
      />
      <ReadOnlyField 
        name={getField(FIELDS.OTHER_NAMES).question} 
        value={getNames(FIELDS.OTHER_NAMES)}
      />
      <ReadOnlyField 
        name="Birthday" 
        value={get(FIELDS.BIRTHDATE)}
      />
      <ReadOnlyField 
        name="Gender identity"
        value={
          ( get(FIELDS.GENDER__MALE) === true ? 'Male\n' : '') + 
          ( get(FIELDS.GENDER__FEMALE) === true ? 'Female\n' : '') + 
          ( get(FIELDS.GENDER__TRANSMAN) === true ? 'Trans Male/Trans Man\n' : '') + 
          ( get(FIELDS.GENDER__TRANSWOMAN) === true ? 'Trans Female/Trans Woman\n' : '') + 
          ( get(FIELDS.GENDER__NON_BINARY) === true ? 'Non-binary/Genderqueer/Gender non-conforming\n' : '') + 
          ( get(FIELDS.GENDER__OTHER ) === true ? 'Different Identity' : '')
      }
      />
      <ReadOnlyField 
        name="pronouns" 
        value={
          (( get(FIELDS.PRONOUNS__MALE) === true || get(FIELDS.PRONOUNS__MALE) === "True" ) ? 'He/Him/His\n' : '' ) +
          (( get(FIELDS.PRONOUNS__FEMALE) === true || get(FIELDS.PRONOUNS__FEMALE) === "True" ) ? 'She/Her/Hers\n' : '' ) +
          (( get(FIELDS.PRONOUNS__NEUTRAL) === true || get(FIELDS.PRONOUNS__NEUTRAL) === "True" ) ? 'They/Them/Their\n' : '' ) +
          (( get(FIELDS.PRONOUNS__OTHER) === true || get(FIELDS.PRONOUNS__OTHER) === "True" ) ? 'Other\n' : '' )
        }
      />
      <ReadOnlyField 
        name="Phone number" 
        value={
          ( get(FIELDS.ALTERNATE_PHONE) ? get(FIELDS.ALTERNATE_PHONE)+' (Mobile)\n' : '') + 
          ( get(FIELDS.HOME_PHONE) ? get(FIELDS.HOME_PHONE)+' (Home)\n' : '')
        }
      />
      <ReadOnlyField 
        name="permanent address"
        value={
          <ConvertCode 
            isAddress={true} 
            address={getAddress(FIELDS.PERMANENT_ADDRESS)}
          />
        }
      />
      <ReadOnlyField 
        name="mailing address"
        value={
          <ConvertCode 
            isAddress={true} 
            address={getAddress(FIELDS.MAILING_ADDRESS)}
          />
        }
      />
      <ReadOnlyField 
        name="primary contact"
        value={<ConvertCode isContact={true} contact={formState.fields[FIELDS.PRIMARY_CONTACT]}/>}
      />
      <ReadOnlyField 
        name="social security number"
        value={get(FIELDS.SSN) ? '*********' : ''} 
      />
      <ReadOnlyField 
        name="citizenship status"
        value={<ConvertCode codeType="CITIZEN" code={get(FIELDS.CITIZENSHIP_STATUS)}/>}
      />
      <ReadOnlyField 
        name="marital status"
        value={<ConvertCode codeType="STUMS" code={get(FIELDS.MARITAL_STATUS)}/>}
      />
       <ReadOnlyField 
        name="parents marital status" 
        value={<ConvertCode codeType="STUMS" code={get(FIELDS.PARENTS_MARITAL_STATUS)}/>} 
      />
       <ReadOnlyField 
        name="Parent or guardians' highest completed education" 
        value={<ConvertCode isEdLevel={true} parents={getEdLevel(FIELDS.ED_LEVEL_FATHER, FIELDS.ED_LEVEL_MOTHER)}/>}
      />
      <ReadOnlyField 
        name="are you hispanic or latino/a/x"
        value={
          get(FIELDS.HISPANIC) === "HISPAN" ? 'Yes' : 
          get(FIELDS.HISPANIC) === "NONHISPAN" ? 'No' : 
          'Choose not to say'
        }
      />
      <ReadOnlyField 
        name="racial group or ethnicity"
        value={
          ( get(FIELDS.ETHNICITY__AM_INDIAN) === true ? 'American Indian or Alaska Native\n' : '' ) +
          ( get(FIELDS.ETHNICITY__ASIAN) === true ? 'Asian\n' : '' ) +
          ( get(FIELDS.ETHNICITY__BLACK) === true ? 'Black or African-American\n' : '' ) +
          ( get(FIELDS.ETHNICITY__HAWAIIAN_PI) === true ? 'Native Hawaiian or Pacific Islander\n' : '' ) +
          ( get(FIELDS.ETHNICITY__WHITE) === true ? 'White\n' : '' ) +
          ( get(FIELDS.ETHNICITY__NA) === true ? 'Choose not to say\n' : '' )
      }
      />
       
       <ReadOnlyField 
        name="oregon resident for 12 months preceding enrollment?"
        value={ 
          ( get(FIELDS.OREGON_RESIDENT) === "True" || get(FIELDS.OREGON_RESIDENT) === true ) ? 'Yes' : 
          ( get(FIELDS.OREGON_RESIDENT) === "False" || get(FIELDS.OREGON_RESIDENT) === false ) ? 'No' : ''
        }
      />
       <ReadOnlyField 
        name="are you working 20+ hours a week in the current academic year?" 
        value={ 
          ( get(FIELDS.WORKING__CURRENT) === "True" || get(FIELDS.WORKING__CURRENT) === true ) ? 'Yes' : 
          ( get(FIELDS.WORKING__CURRENT) === "False" || get(FIELDS.WORKING__CURRENT) === false ) ? 'No' : ''
        }
      />
       <ReadOnlyField 
        name="are you planning to work 20+ hours a week in the next academic year?" 
        value={ 
          ( get(FIELDS.WORKING__NEXT_YEAR) === "True" || get(FIELDS.WORKING__NEXT_YEAR) === true ) ? 'Yes' : 
          ( get(FIELDS.WORKING__NEXT_YEAR) === "False" || get(FIELDS.WORKING__NEXT_YEAR) === false ) ? 'No' : ''
        }
      />
      <ReadOnlyField 
        name="high school"
        value={<ConvertCode isHS={true} code={getSchoolCode(FIELDS.HIGHSCHOOL)}/>}
      />
      <ReadOnlyField 
        name="date of high school completion or graduation"
        value={get(FIELDS.HIGHSCHOOL_GRAD_DATE)}
      />
      <ReadOnlyField 
        name="college or university name"
        value={<ConvertCode isCollege={true} code={getSchoolCode(FIELDS.COLLEGE_CHOICE)}/>}
      />
      <ReadOnlyField 
        name={`college standing as of fall ${year}`}
        value={<ConvertCode codeType="YRINCLG" code={get(FIELDS.COLLEGE_STANDING)}/>}
      />
      <div style={{margin: '2rem 1rem'}}>
        <Checkbox 
          useController={false} 
          name={FIELDS.COLLEGE_DUAL_ENROLLED}
          disabled
          label="I will be dual enrolled with another school while attending this school."
        />
      </div>
      <div style={{margin: '2rem auto 0 auto'}} className='divider'/>
      <ReadOnlyField 
        name={`will you have finished two academic years towards a degree or certificate at community college by july 1, ${year}`}
        value={
          ( get(FIELDS.FINISHED_COMM_COLLEGE) === "True" || get(FIELDS.FINISHED_COMM_COLLEGE) === true ) ? 'Yes' : 
          ( get(FIELDS.FINISHED_COMM_COLLEGE) === "False" || get(FIELDS.FINISHED_COMM_COLLEGE) === false ) ? 'No' : ''
        }
      />
      <ReadOnlyField 
        name={`will you have earned your bachelor's degree by fall ${year}`}
        value={
          ( get(FIELDS.FINISHED_BACHELORS_DEGREE) === "True" || get(FIELDS.FINISHED_BACHELORS_DEGREE) === true ) ? 'Yes' : 
          ( get(FIELDS.FINISHED_BACHELORS_DEGREE) === "False" || get(FIELDS.FINISHED_BACHELORS_DEGREE) === false ) ? 'No' : ''
        }
      />  
      <ReadOnlyField 
        name="cumulative undergraduate gpa"
        value={get(FIELDS.UNDERGRAD_GPA)}
      /> 
      <ReadOnlyField 
        name="cumulative graduate gpa"
        value={get(FIELDS.GRADUATE_GPA)}
      />
      <ReadOnlyField 
        name="degrees"
        value={ 
          <DegreePlans degrees={formState.fields[FIELDS.DEGREES]} />
        }
      />
      <div style={{margin: '2rem 2rem 2rem 3rem'}}>
        <b style={{fontSize: '14px', opacity: '.75', letterSpacing: '1px'}}>COLLEGE HISTORY</b>
        <div style={{marginTop: '2rem'}}>
          <CollegeDisplay colleges={formState.fields[FIELDS.PAST_COLLEGE]}/>
        </div>
      </div>
      <div style={{margin: '2rem auto 0 auto'}} className='divider'/>
      <ReadOnlyField 
        name="children in household"
      />
      <ReadOnlyField 
        name="Child providers"
        value={
          <>
            <ConvertCode isProvider={true} provider={provider1}/>
            <ConvertCode isProvider={true} provider={provider2}/>
          </>
        }
      />
    </>
  );
};