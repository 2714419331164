import { useDispatch, useSelector } from "react-redux";
import React, { useState, Fragment } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useLocation } from 'react-router-dom';
import PortalView from "../../components/Global/PortalView";
import EditCitizenShip from "./EditCitizenShip";
import ReadOnly from "./../../components/ReadOnly";
import EditFamilyInfo from "./EditFamilyInfo";
import EditCollegeHistory from "./EditCollegeHistory";
import EditCollegePlan from "./EditCollegePlan";
import EditActivities from "./EditActivities";
import EditTranscripts from "./EditTranscript";
import PrintIcon from "../../components/PrintIcon";
import { useHistory, useParams } from "react-router";
import { FieldRow, FieldSection, FieldSlot } from "../../components/Forms/FieldSection";
import ReadOnlyActivityPicker from "../../components/ActivityPicker/ReadOnly";
import CollegeDisplay from "../../components/CollegeDisplay";
import { AnswerTypeTranscriptProps, FIELDS, TranscriptDeliveryTypes, TRANSCRIPT_QUESTIONS, AnswerTypeHighschoolProps, getField, ActivityTypeCodes } from "../../store/form/fields";
import { actions } from "../../store";
import { useEffect } from "react";
import ConvertCode, { DegreePlans } from "../../components/Utility/ConvertCode";
import FormSection from "../../components/FormSection";
import ProgressBar from "../../components/progress/ProgressBar";
import EditPersonalStatements from "./EditPersonalStatements";
import ReadOnlyField from "../../components/ReadOnlyField";
import apiClient from "../../utils/API";
import RequirementSet from "../../utils/dtos/RequirementSet";
import EditWorkingStudentData from "./EditWorkingStudentData";
import EditHighschoolInfo from "./EditHighschoolInfo";
import EditCollegeOfChoice from "../field-groups/EditCollegeOfChoice";
import _get from "lodash.get";
import Button from "../../components/Button";
import { open } from "../../store/snack/actions";
import {
    compositeFieldname,
    combineProgramStatus,
} from "../../utils";
import CollegeTranscriptUploader from "../../components/TranscriptUploader/College";
import ScholarshipTabs from "../../components/Scholarships/ScholarshipTabs";
import CompleteSubmission from "../../components/CompleteSubmission";
import { CheckCircle } from "@material-ui/icons";
import ApplicationSubmitButton from "../../components/Utility/ApplicationSubmitButton";
import ApplicationInfo from "../../utils/dtos/ApplicationInfo";
import ProgramInfo from "../../utils/dtos/ProgramInfo";
import SectionFormHelper from "../../utils/helper/SectionHelper";
import { useRef } from "react";
import SectionStateIcon from "../../components/progress/SectionStateIcon";
import { Alert } from "@material-ui/core";
import TranscriptInstructions from "./TranscriptInstructions";


const PROGRAM_CODE = "SCH";

/**
 * render the additional scholarship info page
 * @returns {Component} full page
 */
function AdditionalScholarshipInfo() {
    const { year } = useParams();
    const activeYear = parseInt(year);

    const methods = useForm({
        mode: "onBlur",
    });
    const { setValue, handleSubmit, watch } = methods;

    const location = useLocation();
    const anchor = location.hash;
    useEffect(() => {
        if (anchor) {
            const element = document.querySelector(anchor);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [anchor]);

    const [activityBlurb, setActivityBlurb] = useState({});
    useEffect(() => {
        (async () => {
            const codes = await apiClient.get('/code/codes?codeType=ActCatInstructions');
            const blurbs = {
                work: codes.find(x => x.Code === ActivityTypeCodes.WORK)?.Message,
                school: codes.find(x => x.Code === ActivityTypeCodes.SCHOOL)?.Message,
                volunteer: codes.find(x => x.Code === ActivityTypeCodes.VOLUNTEER)?.Message,
            };
            setActivityBlurb(blurbs);
        })();

    }, []);

    const sectionGroup = new SectionFormHelper(useState(null), PROGRAM_CODE);

    const profileInfo = sectionGroup.create(useState(null), null, [
        FIELDS.FIRST_NAME,
        FIELDS.LAST_NAME
    ]);

    const parentSection = sectionGroup.create(useState(null), null, [
        FIELDS.ED_LEVEL_FATHER,
        FIELDS.ED_LEVEL_MOTHER,
        FIELDS.FIRST_GEN_IMMIGRANT
    ]);
    const citizenSection = sectionGroup.create(useState(null), null, [
        FIELDS.CITIZENSHIP_STATUS,
    ]);
    const workingSection = sectionGroup.create(useState(null), null, [
        FIELDS.WORKING__CURRENT,
        FIELDS.WORKING__NEXT_YEAR,
    ]);
    const highschoolSection = sectionGroup.create(useState(null), null, [
        FIELDS.HIGHSCHOOL_GRAD_DATE,
        FIELDS.HIGHSCHOOL,
        FIELDS.HIGHSCHOOL_TYPE,
        FIELDS.ASPIRE__MEMBER,
        FIELDS.ASPIRE__ADVISOR,
        FIELDS.ASPIRE__SITE,
        FIELDS.CORRECTIONAL_FACILITY_HS,
        FIELDS.HIGHSCHOOL_GPA,
        FIELDS.ACT,
        FIELDS.SAT,
        FIELDS.FIFTH_YEAR_SENIOR,
        FIELDS.GED_SCORE,
        FIELDS.GED_IN_PROGRESS_FLAG,
    ]);
    const collegeChoiceSection = sectionGroup.create(useState(null), null, [
        FIELDS.COLLEGE_CHOICE,
        FIELDS.COLLEGE_STANDING,
    ]);
    const degreeSection = sectionGroup.create(useState(null), null, [
        FIELDS.DEGREES,
        FIELDS.CAREER_GOAL,
    ]);
    const collegeHistorySection = sectionGroup.create(useState(null), null, [
        FIELDS.PAST_COLLEGE,
        FIELDS.CURRENT_COLLEGE,
        FIELDS.COLLEGE_HISTORY_COMPLETE,
        FIELDS.FINISHED_COMM_COLLEGE,
        FIELDS.FINISHED_BACHELORS_DEGREE,
        FIELDS.UNDERGRAD_GPA,
        FIELDS.GRADUATE_GPA
    ]);

    const stageOne = sectionGroup.create(useState(null), null, sectionGroup.fieldKeys);
    stageOne.isTrackingOnly = true;


    const stageThree = sectionGroup.create(useState(null), useRef(), [
        FIELDS.FALL_HS_TRANSCRIPTS_DIGITAL,
        FIELDS.FALL_COLLEGE_TRANSCRIPTS_DURING_HS_DIGITAL,
        FIELDS.FALL_COLLEGE_TRANSCRIPTS_RECENT_DIGITAL,
        FIELDS.FALL_COLLEGE_TRANSCRIPTS_PRIOR_DIGITAL,

        //backwards compatibility for display table:
        FIELDS.FALL_COLLEGE_TRANSCRIPTS_MAIL,
        FIELDS.FALL_COLLEGE_TRANSCRIPTS_DIGITAL,
    ]);

    const stageFour = sectionGroup.create(useState(null), useRef(), [
        FIELDS.ACTIVITY_WORK,
        FIELDS.HAS_NO_WORK_ACTIVITIES,
        FIELDS.HAS_NO_SCHOOL_ACTIVITIES,
        FIELDS.HAS_NO_VOLUNTEER_ACTIVITIES,
        FIELDS.ACTIVITIES_COMPLETE
    ]);

    const stageFive = sectionGroup.create(useState(null), useRef(), [
        FIELDS.ESSAY_1,
        FIELDS.ESSAY_2,
        FIELDS.ESSAY_3,
        FIELDS.ESSAY_4,
    ]);



    /* SCHOLARSHIP SELECTION SECTION */
    const [state, setState] = useState({
        totalScholarshipPages: 0,
        isScholarshipsLoading: false,
        isScholarshipStatusLoading: false,
    });

    const [schStatus, setSchStatus] = useState([]); //SCH application status
    const app = new ApplicationInfo(schStatus);

    const [programData, setProgramData] = useState(null);
    const program = new ProgramInfo(programData);
    const currentSeason = program.getSeason(activeYear);

    const schDeadlines = {
        SchSubmitDate: currentSeason?.getDate("END"),
        PriorityEndDate: currentSeason?.getDate("PRIORITY_END"),
        TrascriptEndDate: currentSeason?.getDate("TRANSCRIPT_END"),
        AppealEndDate: currentSeason?.getDate("APPEAL_END"),
    };

    const [scholarshipAppData, setScholarshipAppData] = useState([]); //Holds selected scholarship apps info including Renew apps.  
    const startedScholarships = scholarshipAppData;

    const displayBoolean = (fieldKey) => {

        var answer = sectionGroup.getAnswerByField(fieldKey);
        var value = answer?.Response;

        if (value === true || value === "True" || value === "true")
            return "Yes";
        if (value === false || value === "False" || value === "false")
            return "No";
        return "";
    }

    const [scholarshipInstructions, setScholarshipInstructions] = useState(null);
    useEffect(() => {
        (async() =>{
            const code = await apiClient.getTextCode("StudentMessage", "ScholarshipSelectInstruction");
            setScholarshipInstructions(code?.Message);
        })();
    }, []);

    /**
     * fetch initial states on page-load
     */
    useEffect(
        // eslint-disable-next-line react-hooks/exhaustive-deps
        async () => {
            const programResult = await apiClient.getProgram('SCH');
            setProgramData(programResult);
            const program = new ProgramInfo(programResult);
            const currentSeason = program.getSeason(activeYear);
            if (!currentSeason) {
                dispatch(
                    open({
                        message: "This application is no longer available",
                        severity: "success",
                    })
                );
                history.push({
                    pathname: '/dashboard', state: {
                        from: history.location.pathname
                    }
                })
                return;
            }

            sectionGroup.fetchState(activeYear);
            fetchTranscriptCompletionDetails();

            const [schStatusResult, scholarshipApps] = await Promise.all([
                apiClient.getApplication('SCH', activeYear),
                apiClient.get("/application/search", { year: activeYear, parentCode: 'SCH' }),
            ]);
            setSchStatus(schStatusResult);
            if (!schStatusResult.IsStarted) {
                apiClient.startApplication('SCH', activeYear);
            }

            const programs = await Promise.all(scholarshipApps.map(x => apiClient.getProgram(x.ProgramCode)));
            const appDigests = scholarshipApps.map(app => {
                const program = programs.find(p => p.Code == app.ProgramCode);
                return combineProgramStatus(program, app);
            });
            setScholarshipAppData(appDigests);
        }, []);

    const reduxFormState = useSelector((state) => state.form);
    const [transcriptCompletionData, setTranscriptCompletionData] = useState(null);
    const transcriptRequirements = new RequirementSet(transcriptCompletionData);
    const hsTranscriptRequirement = transcriptRequirements.get(TRANSCRIPT_QUESTIONS.FALL_HS.name);
    const collegeHistoryCompletion = transcriptRequirements.getField(FIELDS.PAST_COLLEGE)

    const [recommendations, setRecommendations] = useState(null);
    /**
     * find recommendation app details on-demand
     * @returns {Array<*>}
     */
    const getRecommendations = async () => {
        if (recommendations !== null)
            return { loadStatus: "OLD", recommendations };

        const recommendedPrograms = await apiClient.get('/recommendations/programs', { year: activeYear });
        setRecommendations(recommendedPrograms);
        return { loadStatus: "NEW", recommendations: recommendedPrograms };
    }
    /**
     * find scholarships student is already in a pool for
     * @returns {Array<*>}
     */
    const getStartedScholarships = () => {
        return scholarshipAppData;
    }

    /**
     * fetch latest completion data
     */
    async function fetchTranscriptCompletionDetails() {
        var fieldKeys = [...stageThree.fieldKeys, "collegehistories"];
        const transcriptCompletion = await apiClient.getCompletionReport(PROGRAM_CODE, activeYear, fieldKeys);
        setTranscriptCompletionData(transcriptCompletion);
    }

    const formState = useSelector((state) => state.form);
    const DEFAULT_FIELD_SECTION_FLAGS = {
        familyInfo: false,
        citizenShipStatus: false,
        collegeHistoryInfo: false,
        workingStudentStatus: false,
        highSchoolStatus: false,
        collegePlanInfo: false,
        scholarships: false,
        transcripts: false,
        activities: false,
        personalStatements: false,
    };
    const [showField, setShowField] = useState(DEFAULT_FIELD_SECTION_FLAGS);
    const history = useHistory();
    const navigateTo = (route) => (e) => {
        e.preventDefault();
        history.push(route);
    };



    const isStageTwoComplete = startedScholarships.length > 0;
    // percent complete, giving a fixed 20% to the profile, 20% to scholarship selection, and 0% to the submit button
    const percentComplete = sectionGroup.isComplete && isStageTwoComplete ? 100 : Math.round(20 + 60 * sectionGroup.percentComplete / 100 + (isStageTwoComplete ? 20 : 0));
    const disableSubmit = !sectionGroup.isComplete;


    useEffect(() => {
        if (!disableSubmit && !app?.isSubmitted) {
            dispatch(
                open({
                    message: "Your scholarship application is now ready to be submitted!",
                    severity: "success",
                })
            );
        }
    }, [disableSubmit]);

    const toggleSection = async (showFieldState) => {
        await sectionGroup.fetchState(activeYear);
        fetchTranscriptCompletionDetails();
        setRecommendations(null);
        actions.purgeDeletedRepeatableFieldIds();
        setShowField(showFieldState);
    };
    const get = (fieldKey, charLimit) => {
        const value = formState.fields[fieldKey]?.value || "";
        if (charLimit && value.length > charLimit) {
            return value.slice(0, charLimit) + "...";
        } else {
            return value;
        }
    };

    const getSchoolCode = (fieldKey) => {
        return formState.fields[fieldKey]?.SchoolCode?.value;
    };

    const getEdLevel = (fieldKey1, fieldKey2) => {
        const parent1 = get(fieldKey1);
        const parent2 = get(fieldKey2);
        return { parent1, parent2 };
    };

    const getCitizenshipCode = (key) => {
        let csItem = citizenshipItems.find((item) => item.Code == key);
        return csItem ? csItem.Message : null;
    };

    const handleCancel = (showFieldState) => {
        setShowField(showFieldState);
    };

    const currentColleges = formState.fields[FIELDS.CURRENT_COLLEGE];
    const pastColleges = formState.fields[FIELDS.PAST_COLLEGE];



    const [isGed, setIsGed] = useState(null);

    useEffect(() => {
        const highschool = sectionGroup.getAnswerByField(FIELDS.HIGHSCHOOL);
        if (!highschool)
            return;

        if (highschool.Type === "Ged" || highschool.Type === "GedHomeSchool") {
            setIsGed(true);
        } else {
            setIsGed(false);
        }
    }, []);


    //=====( TRANSCRIPT DATA PARSING )

    const [hstInfo, setHstInfo] = useState([]);
    useEffect(() => {
        (async () => {
            const highSchoolTranscripts = [
                _get(reduxFormState, `fields.${FIELDS.FALL_HS_TRANSCRIPTS_DIGITAL}`),
                _get(reduxFormState, `fields.${FIELDS.FALL_HS_TRANSCRIPTS_MAIL}`),
                _get(reduxFormState, `fields.${FIELDS.FALL_HS_TRANSCRIPTS_REGISTRAR}`),
            ];

            const fileAvailableSchools = highSchoolTranscripts.filter(
                (hst) => _get(hst, "IsFileAvailable.value") === true
            );

            for (let i = 0; i < fileAvailableSchools.length; i++) {
                const highschoolTranscript = fileAvailableSchools[i];
                const schoolCode = _get(highschoolTranscript, "SchoolCode.value");
                try {
                    const highschool = await apiClient.getHighSchool(schoolCode);
                    highschoolTranscript["_name"] = highschool && highschool.Name;
                } catch (e) { }
            }

            setHstInfo(fileAvailableSchools);
        })();
    }, [reduxFormState]);


    const getReduxField = (fieldKey) => {
        return _get(reduxFormState, `fields.${fieldKey}.value`);
    };

    const hsDigitalSelected = getReduxField(
        compositeFieldname(
            FIELDS.FALL_HS_TRANSCRIPTS_DIGITAL,
            AnswerTypeTranscriptProps.IS_SELECTED
        )
    );
    const hsMailSelected = getReduxField(
        compositeFieldname(
            FIELDS.FALL_HS_TRANSCRIPTS_MAIL,
            AnswerTypeTranscriptProps.IS_SELECTED
        )
    );
    const hsRegistrarSelected = getReduxField(
        compositeFieldname(
            FIELDS.FALL_HS_TRANSCRIPTS_REGISTRAR,
            AnswerTypeTranscriptProps.IS_SELECTED
        )
    );

    useEffect(() => {
        if (hsDigitalSelected) {
            setValue("HSTranscriptSubmissionMethod", TranscriptDeliveryTypes.UPLOAD);
        } else if (hsMailSelected) {
            setValue("HSTranscriptSubmissionMethod", TranscriptDeliveryTypes.MAIL);
        } else {
            setValue(
                "HSTranscriptSubmissionMethod",
                TranscriptDeliveryTypes.REGISTRAR
            );
        }
    }, [hsDigitalSelected, hsMailSelected, hsRegistrarSelected]);



    useEffect(() => {
        const highschool = _get(reduxFormState, `fields.${FIELDS.HIGHSCHOOL}`);
        if (highschool) {
            const highschoolType = _get(highschool, "Type.value");
            if (highschoolType === "Ged") {
                setIsGed(true);
                return;
            }


            if (highschoolType === "GedHomeSchool") {
                setIsGed(true);
                return;
            }


        }
    }, [reduxFormState]);




    // END TRANSCRIPT DATA

    const [isUnlistedCollegeChoice, setUnlistedCollegeChoice] = useState(null);
    useEffect(() => {

        (getSchoolCode(FIELDS.COLLEGE_CHOICE) === null && formState.fields[FIELDS.COLLEGE_CHOICE]?.UnlistedName?.value) ? setUnlistedCollegeChoice(true) : setUnlistedCollegeChoice(false);

    }, [reduxFormState]);

    const onError = (errors, e) => {
        dispatch(
            open({
                message: "There was a problem saving your entries. Please ensure all required transcripts have been uploaded.",
                severity: "success",
            })
        );
        console.log("ERROR", errors, e);
    };

    const dispatch = useDispatch();


    const [showConfirmation, setShowConfirmation] = useState(false);

    /**
     * do actual work of submitting scholarship application for review
     */
    const actualSubmit = async () => {
        const actualSubmission = async (data, e) => {
            await actions.submitForm(data);
            await apiClient.submitApplication('SCH', activeYear);
            setShowConfirmation(true);
            const updatedAppStatus = await apiClient.getApplication(PROGRAM_CODE, activeYear);
            setSchStatus(updatedAppStatus);
        };
        try {
            const execSubmission = handleSubmit(actualSubmission, onError);
            await execSubmission();
            dispatch(
                open({
                    message: "Submitted Successfully",
                    severity: "success",
                })
            );
        } catch (e) {
            console.error(e);
            dispatch(
                open({
                    message: e.message,
                    severity: "success",
                })
            );
        }

    };
    /**
     * resume editing, most likely after submitting
     */
    const onEdit = () => {
        setShowConfirmation(false);
        //no need to reload in this case, since all forms will already be in view state with redux-saved info
    }


    const [citizenshipItems, setCitizenshipItems] = useState([]);

    useEffect(
        // eslint-disable-next-line react-hooks/exhaustive-deps
        async () => {
            const codes = await apiClient.get('/code/codes?codeType=CITIZEN');
            setCitizenshipItems(codes);
        }, []);



    const noCollegeHistoryAnswer = collegeHistorySection.getAnswerByField(FIELDS.COLLEGE_HISTORY_COMPLETE).Response;

    return (
        <FormProvider {...methods}>
            <PortalView>
                <div name="ProfilePage">
                    <div className="page-intro">
                        <div className="wrapper">
                            <div className="page-intro__layout">
                                <div className="page-intro__icon">
                                    <img src="/Profile.svg" alt="Profile icon" />
                                </div>
                                <div className="page-intro__content rhythm">
                                    <h1 className="heading heading--h1">
                                        OSAC Scholarship Application {activeYear} - {activeYear + 1}
                                        <PrintIcon />
                                    </h1>
                                    <p>This section is required to apply for our scholarships. The information in this section helps us recommend scholarships for you. Deadlines as follows: </p>
                                    <p>Last date to submit scholarship application: {schDeadlines.SchSubmitDate}
                                        <br></br> Priority(Early Bird) end date: {schDeadlines.PriorityEndDate}
                                        <br></br> Last date for transcript corrections: {schDeadlines.TrascriptEndDate}
                                        <br></br> Appeal end date: {schDeadlines.AppealEndDate}</p>
                                    <a href="#" onClick={navigateTo("/profile")}>Return to Profile</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {showConfirmation ? (
                        <CompleteSubmission onEdit={onEdit} details={{
                            code: PROGRAM_CODE,
                            year: activeYear,
                            title: "Scholarships Additional Info"
                        }} />
                    ) : (

                        <div className="wrapper wrapper--narrow">
                            <div style={{ marginTop: "1rem" }}>
                                <ProgressBar progress={percentComplete} />
                            </div>

                            <div style={{ border: '1px solid grey', padding: '1rem', marginTop: '1rem' }}>
                                <div style={{ 'fontWeight': 'bold', fontSize: '2rem' }}>
                                    Section 1
                                    <SectionStateIcon section={stageOne} />
                                </div>
                                {!showField.familyInfo ? (
                                    <FormSection
                                        title="My Family Information"
                                        status={<SectionStateIcon section={parentSection} />}
                                        isEditable={false}
                                        onEditClick={() => setShowField({ ...showField, familyInfo: true })}
                                    >
                                        <div className="field-wrapper">
                                            <div className="field-wrapper__title">Parent or guardians’ highest completed education</div>
                                            <ReadOnly name="HighestEd-Parent" value={<ConvertCode isEdLevel={true} parents={getEdLevel(FIELDS.ED_LEVEL_FATHER, FIELDS.ED_LEVEL_MOTHER)} />} />
                                        </div>
                                        <div className="field-wrapper">
                                            <div className="field-wrapper__title">First-Generation Immigrant</div>
                                            <ReadOnly name="working-student-data"
                                                value={displayBoolean(FIELDS.FIRST_GEN_IMMIGRANT)} />
                                        </div>
                                    </FormSection>
                                ) : (
                                    <EditFamilyInfo
                                        section={parentSection}
                                        onChange={() =>
                                            toggleSection({ ...showField, familyInfo: false })}
                                        onCancel={() =>
                                            handleCancel({ ...showField, familyInfo: false })
                                        }
                                    />
                                )}

                                {!showField.citizenShipStatus ? (
                                    <FormSection
                                        title="Citizenship Status"
                                        status={<SectionStateIcon section={citizenSection} />}
                                        isEditable={false}
                                        onEditClick={() =>
                                            setShowField({ ...showField, citizenShipStatus: true })
                                        }
                                    >
                                        <div className="field-wrapper">
                                            <div className="field-wrapper__title">Citizenship Status</div>
                                            <ReadOnly
                                                name="Citizenship-status"
                                                value={getCitizenshipCode(get(FIELDS.CITIZENSHIP_STATUS))}
                                            />
                                        </div>
                                    </FormSection>
                                ) : (
                                    <EditCitizenShip
                                        section={citizenSection}
                                        onChange={() =>
                                            toggleSection({ ...showField, citizenShipStatus: false })
                                        }
                                        onCancel={() =>
                                            handleCancel({ ...showField, citizenShipStatus: false })
                                        }
                                    />
                                )}

                                {'' && <></>}

                                {!showField.workingStudentStatus ? (
                                    <FormSection
                                        title="Working Student Status"
                                        status={<SectionStateIcon section={workingSection} />}
                                        isEditable={false}
                                        onEditClick={() =>
                                            setShowField({ ...showField, workingStudentStatus: true })
                                        }
                                    >
                                        <div className="field-wrapper">
                                            <div className="field-wrapper__title">Are you working 20+ hours/week in the current ({activeYear - 1} - {activeYear}) academic year?</div>
                                            <ReadOnly name="working-student-data"
                                                value={displayBoolean(FIELDS.WORKING__CURRENT)} />
                                        </div>
                                        <div className="field-wrapper">
                                            <div className="field-wrapper__title">Are you planning to work 20+ hours a week in the next academic year ({activeYear} - {activeYear + 1})? * </div>
                                            <ReadOnly name="working-student-data"
                                                value={displayBoolean(FIELDS.WORKING__NEXT_YEAR)} />
                                        </div>
                                    </FormSection>
                                ) : (
                                    <EditWorkingStudentData
                                        section={workingSection}
                                        onChange={() =>
                                            toggleSection({ ...showField, workingStudentStatus: false })
                                        }
                                        onCancel={() =>
                                            handleCancel({ ...showField, workingStudentStatus: false })
                                        }
                                        year={activeYear}
                                    />
                                )}

                                {!showField.highSchoolStatus ? (
                                    <FormSection
                                        title="High School Information"
                                        status={<SectionStateIcon section={highschoolSection} />}
                                        isEditable={false}
                                        onEditClick={() => setShowField({ ...showField, highSchoolStatus: true })}
                                    >
                                        <div className="form-section__content">
                                            <div name="HighschoolInfoForm">
                                                <div className="field-wrapper">
                                                    <div className="field-wrapper__title">
                                                        Type of high school or equivalent
                                                    </div>
                                                    <ReadOnly
                                                        name="HighschoolType"
                                                        value={
                                                            <ConvertCode
                                                                codeType="HSTYPE"
                                                                code={get(FIELDS.HIGHSCHOOL_TYPE)}
                                                            />
                                                        }
                                                    />
                                                </div>

                                                <div className="field-wrapper">
                                                    <div className="field-wrapper__title">High school</div>
                                                    <ReadOnly
                                                        name="Highschool"
                                                        value={
                                                            getSchoolCode(FIELDS.HIGHSCHOOL) ?
                                                                <ConvertCode
                                                                    isHS={true}
                                                                    code={getSchoolCode(FIELDS.HIGHSCHOOL)}
                                                                /> :
                                                                formState.fields[FIELDS.HIGHSCHOOL]?.UnlistedName?.value ?
                                                                    formState.fields[FIELDS.HIGHSCHOOL]?.UnlistedName?.value :
                                                                    "No High School information received"
                                                        }
                                                    />
                                                </div>

                                                <div className="field-wrapper">
                                                    <div className="field-wrapper__title">
                                                        Date of high school completion or graduation
                                                    </div>
                                                    <ReadOnly
                                                        name="Grad-Highschool"
                                                        className="text"
                                                        value={get(FIELDS.HIGHSCHOOL_GRAD_DATE)?.substr(0, 7)}
                                                    />
                                                </div>

                                                <div className="field-wrapper">
                                                    <div className="field-wrapper__title">
                                                        Will you be attending college as a 5th year senior in Fall term {activeYear}?
                                                    </div>
                                                    <ReadOnly
                                                        name="5thYearSenior"
                                                        className="text"
                                                        value={displayBoolean(FIELDS.FIFTH_YEAR_SENIOR)}
                                                    />
                                                </div>

                                                {isGed === false || isGed === null ? (
                                                    <>
                                                        <div className="field-wrapper">
                                                            <div className="field-wrapper__title">
                                                                High school gpa (unweighted, cumulative 7-semester)
                                                            </div>
                                                            <ReadOnly
                                                                name="GPA-Highschool"
                                                                className="text"
                                                                value={get(FIELDS.HIGHSCHOOL_GPA)}
                                                            />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="field-wrapper">
                                                            <div className="field-wrapper__title">GED<sup style={{ verticalAlign: "super", fontSize: ".9rem" }}>&reg;</sup> score</div>

                                                            <ReadOnly
                                                                name="GED-SCore"
                                                                className="text"
                                                                value={get(FIELDS.GED_SCORE)}
                                                            />
                                                        </div>
                                                    </>
                                                )}

                                                <div className="field-wrapper">
                                                    <div className="field-wrapper__title">SAT total score</div>
                                                    <ReadOnly
                                                        name="SAT"
                                                        className="text"
                                                        value={get(FIELDS.SAT)}
                                                    />
                                                </div>

                                                <div className="field-wrapper">
                                                    <div className="field-wrapper__title">ACT total score</div>
                                                    <ReadOnly
                                                        name="ACT"
                                                        className="text"
                                                        value={get(FIELDS.ACT)}
                                                    />
                                                </div>

                                                <div className="field-wrapper">
                                                    <div className="field-wrapper__title">
                                                        Did you work with the aspire program in high school?
                                                    </div>
                                                    <ReadOnly
                                                        name="AspireWork-Highschool"
                                                        className="text"
                                                        value={
                                                            get(FIELDS.ASPIRE__MEMBER) === true ||
                                                                get(FIELDS.ASPIRE__MEMBER) === "True"
                                                                ? "Yes"
                                                                : get(FIELDS.ASPIRE__MEMBER) === false ||
                                                                    get(FIELDS.ASPIRE__MEMBER) === "False"
                                                                    ? "No"
                                                                    : ""
                                                        }
                                                    />
                                                </div>

                                                <div className="field-wrapper">
                                                    <div className="field-wrapper__title">
                                                        Aspire mentor’s name
                                                    </div>
                                                    <ReadOnly
                                                        name="AspireMentorName"
                                                        className="text"
                                                        value={get(FIELDS.ASPIRE__ADVISOR)}
                                                    />
                                                </div>

                                                <div className="field-wrapper">
                                                    <div className="field-wrapper__title">Aspire site name</div>
                                                    <ReadOnly
                                                        name="AspireSiteName"
                                                        className="text"
                                                        value={get(FIELDS.ASPIRE__SITE)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </FormSection>
                                ) : (
                                    <EditHighschoolInfo
                                        section={highschoolSection}
                                        readOnlyHs={true}
                                        hideCorrectional={true}
                                        onChange={() =>
                                            toggleSection({ ...showField, highSchoolStatus: false })
                                        }
                                        onCancel={() =>
                                            handleCancel({ ...showField, highSchoolStatus: false })
                                        }
                                    />
                                )}

                                {!showField.collegeOfChoice ? (
                                    <div id="college-choice">
                                        <FormSection
                                            title="College Choice"
                                            status={<SectionStateIcon section={collegeChoiceSection} />}
                                            isEditable={false}
                                            onEditClick={() =>
                                                setShowField({ ...showField, collegeOfChoice: true })
                                            }
                                        >
                                            <div name="CollegeOfChoiceForm">
                                                <div className="field-wrapper">
                                                    <div className="field-wrapper__title">
                                                        College or University Name
                                                    </div>
                                                    <ReadOnly
                                                        name="CollegeName"
                                                        className="text"
                                                        value={
                                                            isUnlistedCollegeChoice ? formState.fields[FIELDS.COLLEGE_CHOICE]?.UnlistedName?.value :
                                                                <ConvertCode
                                                                    isCollege={true}
                                                                    code={getSchoolCode(FIELDS.COLLEGE_CHOICE)}
                                                                />
                                                        }
                                                    />
                                                </div>

                                                <div className="field-wrapper">
                                                    <div className="field-wrapper__title">
                                                        College Standing as of Fall {activeYear}
                                                    </div>
                                                    <ReadOnly
                                                        name="CollegeCode"
                                                        className="text"
                                                        value={
                                                            <ConvertCode
                                                                codeType="YRINCLG"
                                                                code={get(FIELDS.COLLEGE_STANDING)}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </FormSection>
                                    </div>
                                ) : (
                                    <EditCollegeOfChoice
                                        section={collegeChoiceSection}
                                        onChange={() =>
                                            toggleSection({ ...showField, collegeOfChoice: false })
                                        }
                                        onCancel={() =>
                                            handleCancel({ ...showField, collegeOfChoice: false })
                                        }
                                        year={activeYear}
                                        hideDualEnrolled={false}
                                        isUnlistedCollegeChoice={isUnlistedCollegeChoice}
                                    />
                                )}

                                {!showField.collegePlanInfo ? (
                                    <div id="college-plans">
                                        <FormSection
                                            title="College Plans"
                                            status={<SectionStateIcon section={degreeSection} />}
                                            isEditable={false}
                                            onEditClick={() =>
                                                setShowField({ ...showField, collegePlanInfo: true })
                                            }
                                        >

                                            <div container className="field-wrapper">
                                                <div className="field-wrapper__title">degree plan(s)</div>
                                                <ReadOnly name="DegreePlan" value={<DegreePlans degrees={formState.fields[FIELDS.DEGREES]} />} />
                                            </div>

                                            <div className="field-wrapper">
                                                <div className="field-wrapper__title">{getField(FIELDS.CAREER_GOAL).question}</div>
                                                <ReadOnly name="CareerGoal" value={get(FIELDS.CAREER_GOAL)} />
                                            </div>
                                        </FormSection>
                                    </div>
                                ) : (
                                    <EditCollegePlan
                                        section={degreeSection}
                                        onChange={() =>
                                            toggleSection({ ...showField, collegePlanInfo: false })
                                        }
                                        onCancel={() =>
                                            handleCancel({ ...showField, collegePlanInfo: false })
                                        }
                                        year={activeYear}
                                    />
                                )}

                                {!showField.collegeHistoryInfo ? (
                                    <FormSection
                                        title="College History"
                                        status={<SectionStateIcon section={collegeHistorySection} />}
                                        isEditable={false}
                                        onEditClick={() =>
                                            setShowField({ ...showField, collegeHistoryInfo: true })
                                        }
                                    >
                                        {noCollegeHistoryAnswer ?
                                            (<Fragment>
                                                <FieldSection>
                                                    You have indicated you have never attended college.
                                                </FieldSection>
                                            </Fragment>)
                                            :
                                            (<Fragment>

                                                {collegeHistoryCompletion?.isDuplicated === true &&
                                                    <div>
                                                        <Alert severity="error" className="alert alert--error">
                                                            <p>
                                                                Do not report any college more than once. Even if you took time off from a college, report the earliest term of attendance
                                                                and the most recent term of attendance if you are not still currently attending.
                                                            </p>
                                                        </Alert>
                                                    </div>
                                                }
                                                <div className="field-wrapper">
                                                    <div className="field-wrapper__title">Currently attending colleges</div>


                                                    <div>
                                                        <CollegeDisplay colleges={currentColleges} />
                                                    </div>
                                                </div>

                                                <div className="field-wrapper">
                                                    <div className="field-wrapper__title">Previously attended colleges</div>
                                                    <div>
                                                        <CollegeDisplay colleges={pastColleges} />
                                                    </div>
                                                </div>

                                                <div className="field-wrapper">
                                                    <div className="field-wrapper__title">
                                                        Will you have finished two academic years towards a
                                                        degree or certificate at community college by july 1,{" "}
                                                        {activeYear}?
                                                    </div>
                                                    <ReadOnly name="2YearsCommCollege" value={
                                                        get(FIELDS.FINISHED_COMM_COLLEGE) === true ||
                                                            get(FIELDS.FINISHED_COMM_COLLEGE) === "True"
                                                            ? "Yes"
                                                            : get(FIELDS.FINISHED_COMM_COLLEGE) === false ||
                                                                get(FIELDS.FINISHED_COMM_COLLEGE) === "False"
                                                                ? "No"
                                                                : ""
                                                    }
                                                    />
                                                </div>

                                                <div className="field-wrapper">
                                                    <div className="field-wrapper__title">
                                                        Will you have earned your bachelor’s degree by fall{" "}
                                                        {activeYear}?
                                                    </div>
                                                    <ReadOnly
                                                        name="BachelorDegree"
                                                        value={
                                                            get(FIELDS.FINISHED_BACHELORS_DEGREE) === true ||
                                                                get(FIELDS.FINISHED_BACHELORS_DEGREE) === "True"
                                                                ? "Yes"
                                                                : get(FIELDS.FINISHED_BACHELORS_DEGREE) === false ||
                                                                    get(FIELDS.FINISHED_BACHELORS_DEGREE) === "False"
                                                                    ? "No"
                                                                    : ""
                                                        }
                                                    />
                                                </div>

                                                <div className="field-wrapper">
                                                    <div className="field-wrapper__title">Cumulative undergraduate gpa</div>
                                                    <ReadOnly name="CGPA-Undergrad" value={get(FIELDS.UNDERGRAD_GPA)} />
                                                </div>

                                                <div className="field-wrapper">
                                                    <div className="field-wrapper__title">Cumulative Graduate gpa</div>
                                                    <ReadOnly
                                                        name="CGPA-Undergrad"
                                                        value={get(FIELDS.GRADUATE_GPA)}
                                                    />
                                                </div>
                                            </Fragment>)}
                                    </FormSection>
                                ) : (
                                    <EditCollegeHistory
                                        section={collegeHistorySection}
                                        onChange={() => toggleSection({ ...showField, collegeHistoryInfo: false })}
                                        onCancel={() =>
                                            handleCancel({ ...showField, collegeHistoryInfo: false })
                                        }
                                    />
                                )}
                            </div>

                            <div id="section2" style={{ border: '1px solid grey', padding: '1rem', marginTop: '1rem' }}>
                                <div style={{ 'fontWeight': 'bold', fontSize: '2rem' }}>
                                    Section 2
                                    {startedScholarships.length > 0 &&
                                        <CheckCircle style={{ color: 'green', marginLeft: '1rem' }} />
                                    }
                                    {startedScholarships.length === 0 &&
                                        <CheckCircle style={{ color: 'lightgray', marginLeft: '1rem' }} />
                                    }
                                </div>
                                {!showField.scholarships ? (
                                    <FormSection
                                        title="Select Scholarship(s)"
                                        editButtonText="Add/Remove Scholarships"
                                        isEditable={false}
                                        onEditClick={() =>
                                            setShowField({ ...showField, scholarships: true })
                                        }>
                                        <div className="instructions rhythm rhythm--large">
                                            <div className="rhythm">
                                                <FieldSlot md={12}>

                                                    <Alert severity="info" variant="outlined" >
                                                        <div dangerouslySetInnerHTML={{ __html: scholarshipInstructions }} />
                                                    </Alert>

                                                    {[{ title: 'Selected Scholarships', filter: s => s.Source !== 'Renew' },
                                                    { title: 'Scholarships Renewals', filter: s => s.Source === 'Renew' },
                                                    ].map(t => startedScholarships.some(t.filter) &&
                                                        <div>
                                                            <strong>{t.title}:</strong>
                                                            <ul className="list">
                                                                {startedScholarships.filter(t.filter).map(sch =>
                                                                    <li className="li">
                                                                        {sch.Name}
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                    )}


                                                </FieldSlot>
                                            </div>
                                        </div>
                                    </FormSection>
                                ) : (
                                    <div className="form-section">
                                        <div className="form-section__header">
                                            <h3 className="heading heading--h4">Scholarships</h3>
                                            <div className="form-section__actions">
                                                <Button name='CancelEditBasicInfo' label='Close' variant='text'
                                                    onClick={() =>
                                                        handleCancel({ ...showField, scholarships: false })
                                                    } />
                                            </div>
                                        </div>
                                        <div className="form-section__content">
                                            <ScholarshipTabs
                                                startedScholarshipGetter={getStartedScholarships}
                                                recommendationGetter={getRecommendations}
                                                isLoading={state.isScholarshipsLoading}
                                                year={activeYear}
                                            />
                                            <div className="save-edit-end" >
                                                <Button name='CancelEditBasicInfo' label='Close' variant='text' onClick={() => handleCancel({ ...showField, scholarships: false })} />
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>
                            {startedScholarships.length > 0 &&
                                <div>
                                    <div style={{ border: '1px solid grey', padding: '1rem', marginTop: '1rem' }}>
                                        <div style={{ 'fontWeight': 'bold', fontSize: '2rem' }}>
                                            Section 3
                                            <SectionStateIcon section={stageThree} />
                                        </div>
                                        {!showField.transcripts ? (
                                            <FormSection
                                                title="Transcripts"
                                                isEditable={false}
                                                onEditClick={() =>
                                                    setShowField({ ...showField, transcripts: true })
                                                }
                                            >
                                                <div className="instructions rhythm rhythm--large">
                                                    <div className="rhythm">
                                                        <TranscriptInstructions section={stageThree} requirements={transcriptRequirements} />
                                                        {/*#4155: SCH - External Review Message & Error message showed on Dashboard. */}
                                                        {schStatus?.IsSubmitted && <FieldSlot md={12}>
                                                            <div className="mt-1">
                                                                <strong>Scholarship Application Review Status</strong>
                                                                {schStatus?.IsSubmitted && (() => {
                                                                    switch (schStatus.ReviewStatus) {
                                                                        case 'VALID':
                                                                            return <div className="rhythm" >Review Valid</div>
                                                                        case 'REJECT':
                                                                            return (
                                                                                <div className="rhythm" style={{ color: '#da3754' }}>
                                                                                    <div>Corrections required.  Fix errors by <b>{schDeadlines.TrascriptEndDate}</b> to be considered for scholarships.</div>
                                                                                    <div dangerouslySetInnerHTML={{ __html: schStatus.ReviewInstructions }}></div>
                                                                                </div>)
                                                                        case 'PENDING':
                                                                        case 'PENDINGNEWDOCREJECT':
                                                                        case 'PENDINGNEWDOCVALID':
                                                                        case 'REVIEW1':
                                                                        case 'REVIEW2':
                                                                            return <div className="rhythm" >Awaiting Review</div>
                                                                        default:
                                                                            return <></>
                                                                    }
                                                                })()}
                                                            </div>
                                                        </FieldSlot>
                                                        }
                                                        {(pastColleges || currentColleges) && <FieldSection withDivider={true} title="College Transcript Status">
                                                            <FieldRow>
                                                                <FieldSlot md={12}>
                                                                    <div className="mt-05">
                                                                        <CollegeTranscriptUploader
                                                                            displayOnly={true}
                                                                            transcriptField={TRANSCRIPT_QUESTIONS.FALL_COLLEGE}
                                                                        />
                                                                    </div>
                                                                </FieldSlot>
                                                            </FieldRow>
                                                        </FieldSection>
                                                        }
                                                        {(!transcriptRequirements.isLoaded || !transcriptRequirements.isSkipped(TRANSCRIPT_QUESTIONS.FALL_HS.name) || hsTranscriptRequirement.isOptional)
                                                            && hstInfo.map((hst) => (
                                                                <FieldSection
                                                                    withDivider={true}
                                                                    title="Highschool Transcript Status"
                                                                >
                                                                    <FieldRow>
                                                                        <FieldSlot md={12}>
                                                                            <div className="mt-1">
                                                                                { /** #3959: Display HSName for all HSTypes either HS picker or unlisted/HS name typed. */

                                                                                    <div><ReadOnly name="Highschool"
                                                                                        value={getSchoolCode(FIELDS.HIGHSCHOOL, reduxFormState) ?
                                                                                            <ConvertCode
                                                                                                isHS={true}
                                                                                                code={getSchoolCode(FIELDS.HIGHSCHOOL, reduxFormState)}
                                                                                            />
                                                                                            : getReduxField(compositeFieldname(
                                                                                                FIELDS.HIGHSCHOOL, AnswerTypeHighschoolProps.UNLISTED_NAME), reduxFormState)
                                                                                        }
                                                                                    />
                                                                                    </div>
                                                                                }
                                                                                <h5 className="heading heading--h5">Type: {hst.DeliveryType.value}</h5>
                                                                                <ul className="list"
                                                                                    style={{ color: hst.ReviewStatus.value === 'COMPLETE' || hst.ReviewStatus.value === 'PEND_OSAC' || hst.ReviewStatus.value === 'NA' ? 'black' : '#da3754' }}>
                                                                                    <li className="li"
                                                                                        dangerouslySetInnerHTML={{ __html: hst.ReviewStatusDescription.value }}></li>
                                                                                    {hst.ReviewInstructions.value !== null && hst.ReviewInstructions.value !== '' &&
                                                                                        <li className="li"
                                                                                            dangerouslySetInnerHTML={{ __html: hst.ReviewInstructions.value }}></li>
                                                                                    }
                                                                                </ul>

                                                                            </div>
                                                                        </FieldSlot>
                                                                    </FieldRow>
                                                                </FieldSection>
                                                            ))}
                                                    </div>
                                                </div>
                                            </FormSection>
                                        ) : (
                                            <EditTranscripts
                                                displayInstructions={<TranscriptInstructions section={stageThree} requirements={transcriptRequirements} />}
                                                section={stageThree}
                                                transcriptRequirements={transcriptRequirements}
                                                schStatus={schStatus}
                                                trascriptEndDate={schDeadlines.TrascriptEndDate}
                                                onChange={() =>
                                                    toggleSection({ ...showField, transcripts: false })
                                                }
                                                onCancel={() =>
                                                    handleCancel({ ...showField, transcripts: false })
                                                }
                                            />
                                        )}
                                    </div>
                                    <div style={{ border: '1px solid grey', padding: '1rem', marginTop: '1rem' }}>
                                        <div style={{ 'fontWeight': 'bold', fontSize: '2rem' }}>
                                            Section 4
                                            <SectionStateIcon section={stageFour} />
                                        </div>
                                        {!showField.activities ? (
                                            <FormSection
                                                title="Activities"
                                                isEditable={false}
                                                onEditClick={() =>
                                                    setShowField({ ...showField, activities: true })
                                                }
                                            >
                                                <div name="ActivitiesForm" className="activities">
                                                    <FieldSection
                                                        title="School Activities"
                                                        subtitle={activityBlurb.school}
                                                        withGrid={false}
                                                    >
                                                        {getReduxField(FIELDS.HAS_NO_SCHOOL_ACTIVITIES) ?
                                                            <div style={{ fontWeight: 'bold' }}>I have no school activites</div>
                                                            :
                                                            <ReadOnlyActivityPicker
                                                                activities={formState.fields[FIELDS.ACTIVITY_SCHOOL]}
                                                            />
                                                        }
                                                    </FieldSection>

                                                    <FieldSection
                                                        title="Volunteer Activities"
                                                        subtitle={activityBlurb.volunteer}
                                                        withGrid={false}
                                                    >
                                                        {getReduxField(FIELDS.HAS_NO_VOLUNTEER_ACTIVITIES) ?
                                                            <div style={{ fontWeight: 'bold' }}>I have no volunteer activites</div>
                                                            :
                                                            <ReadOnlyActivityPicker
                                                                activities={formState.fields[FIELDS.ACTIVITY_VOLUNTEER]}
                                                            />
                                                        }
                                                    </FieldSection>

                                                    <FieldSection
                                                        title="Paid Work Activities"
                                                        subtitle={activityBlurb.work}
                                                        withGrid={false}
                                                    >
                                                        {getReduxField(FIELDS.HAS_NO_WORK_ACTIVITIES) ?
                                                            <div style={{ fontWeight: 'bold' }}>I have no work activites</div>
                                                            :
                                                            <ReadOnlyActivityPicker
                                                                activities={formState.fields[FIELDS.ACTIVITY_WORK]}
                                                            />
                                                        }
                                                    </FieldSection>
                                                </div>
                                            </FormSection>
                                        ) : (
                                            <EditActivities
                                                section={stageFour}
                                                onChange={() =>
                                                    toggleSection({ ...showField, activities: false })
                                                }
                                                onCancel={() =>
                                                    handleCancel({ ...showField, activities: false })
                                                }
                                                year={activeYear}
                                            />
                                        )}
                                    </div>
                                    <div style={{ border: '1px solid grey', padding: '1rem', marginTop: '1rem' }}>
                                        <div style={{ 'fontWeight': 'bold', fontSize: '2rem' }}>
                                            Section 5
                                            <SectionStateIcon section={stageFive} />
                                        </div>
                                        {!showField.personalStatements ? (
                                            <FormSection
                                                title="Personal Statements"
                                                isEditable={false}
                                                onEditClick={() =>
                                                    setShowField({ ...showField, personalStatements: true })
                                                }
                                            >
                                                <ReadOnlyField
                                                    name="What are your specific educational plans and career goals and why? What motivates you to achieve them?"
                                                    value={get(FIELDS.ESSAY_1, 120)}
                                                />

                                                <ReadOnlyField
                                                    name="What have you done for your family, school, or community that you care about the most and why?"
                                                    value={get(FIELDS.ESSAY_2, 120)}
                                                />

                                                <ReadOnlyField
                                                    name="Describe a personal accomplishment, impactful change, or experience that has occurred in your life. What skills and strengths were needed to respond, and what did you learn about yourself?"
                                                    value={get(FIELDS.ESSAY_3, 120)}
                                                />

                                                <ReadOnlyField
                                                    name={getField(FIELDS.ESSAY_4).question}
                                                    value={get(FIELDS.ESSAY_4, 120)}
                                                />
                                            </FormSection>
                                        ) : (
                                            <EditPersonalStatements
                                                section={stageFive}
                                                onChange={() =>
                                                    toggleSection({ ...showField, personalStatements: false })
                                                }
                                                onCancel={() =>
                                                    handleCancel({ ...showField, personalStatements: false })
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                            }
                            <div className="grant-action-buttons" style={{ marginTop: '1rem' }}>
                                <ApplicationSubmitButton app={app} sections={{ isComplete: !disableSubmit && startedScholarships.length > 0 }} style={{ width: '135px' }} onClick={actualSubmit} />
                            </div>
                        </div>
                    )}
                </div>
            </PortalView>
        </FormProvider>
    );
}

export default AdditionalScholarshipInfo;
