import { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Button from "../../components/Button";
import { CustomCheckbox } from '../../components/Checkbox';
import PortalView from "../../components/Global/PortalView";
import { useEffect } from "react";
import apiClient from "../../utils/API";
import { goBack } from "../../utils";
import { useHistory, useParams } from "react-router-dom";
import SpinnerSection from "../../components/Utility/SpinnerSection";
import ProgramInfo from "../../utils/dtos/ProgramInfo";
import ApplicationInfo from "../../utils/dtos/ApplicationInfo";
import DeclineAward from "../decline/DeclineAward";
import AwardSummary from "../../components/award/AwardSummary";

/**
 * award acceptance page
 * @returns {Component}
 */
function Offer() {
    const methods = useForm({ mode: "onBlur", });

    const { code, year } = useParams();
    const [isLoading, setLoading] = useState(true);
    const [programData, setProgramData] = useState(null);
    const [applicationData, setApplicationData] = useState(null);
    const [awardsData, setAwardsData] = useState(null);
    const [donorData, setDonorData] = useState(null);
    const isOcf = donorData?.DonorCode === 'OCF';

    const program = new ProgramInfo(programData);
    const application = new ApplicationInfo(applicationData);

    const awardContactEmail = program.primaryEmail;


    useEffect(() => {
        (async () => {
            try {
                const [program, app, awards, donor] = await Promise.all([
                    apiClient.getProgram(code),
                    apiClient.getApplication(code, year),
                    apiClient.getApplicationAwardStatus(code, year),
                    apiClient.getDonor(code)
                ]);

                setProgramData(program);
                setApplicationData(app);
                setAwardsData(awards);
                setDonorData(donor);

            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const history = useHistory();
    const handleCancel = () => {
        goBack({
            history,
            defaultRoute: "/dashboard",
        });
    };

    // Decline the award & go backto dashboard.
    const handleAcceptAward = async () => {
        setSelectedAction("Accept");
    }

    const handleCertifyAcceptance = async () => {
        await apiClient.acceptApplicationAward(code, year);
        goBack({
            history,
            defaultRoute: "/dashboard"
        })
    }

    const handleDeclineAward = async () => {
        setSelectedAction("Decline");
    }

    const [selectedAction, setSelectedAction] = useState(null);
    const handleDeferAward = async () => {

        setSelectedAction("Defer");
    }

    /**
     * link for contacting awards team by email
     * @returns {Component}
     */
    function AwardContactLink() {
        const awardContactLink = "mailto:" + awardContactEmail;

        return <a href={awardContactLink}>{awardContactEmail}</a>
    }



    /**
     * certifications and process for accepting an initial scholarship award
     * 
     * @returns {Component}
     */
    function AcceptScholarship() {

        const [isCertified, setCertified] = useState(false);

        const toggleCertification = () => {
            setCertified(!isCertified);
        }

        return (
            <div className="wrapper rhythm">

                <div className="form-wrapper">
                    <h2 className="heading heading--h2">Award Announcement</h2>

                    <AwardSummary program={programData} awards={awardsData} application={applicationData} />
                   
                    {donorData?.ThankYouContact &&
                        <div>
                            <h3 className="heading heading--h3">Send Your Thank You Letter: </h3>
                            <div style={{ marginLeft: '2em' }}>
                                <div style={{ display: 'inline-block', verticalAlign: 'top' }} >
                                    <a href={`/thankyou/${code}/${year}`}>Via Email</a>
                                </div>
                                <div style={{ display: 'inline-block', verticalAlign: 'middle', margin: '0 1em' }}>
                                    ...or to...
                                </div>
                                <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                                    <div>{program.name}</div>
                                    <div>Care of: {donorData?.Name}</div>
                                    <div>{donorData.ThankYouContact.Address1}</div>
                                    <div>{donorData.ThankYouContact.Address2}</div>
                                    <div>
                                        {donorData.ThankYouContact.City}, &nbsp;
                                        {donorData.ThankYouContact.State} &nbsp;
                                        {donorData.ThankYouContact.Zip} &nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div>
                        <h3 className="heading heading--h3">Scholarship Acceptance</h3>
                        <ul className="list">
                            <li className="li">Failure to accept this award may delay or terminate your award and delay awarding
                                an alternate recipient.</li>
                            <li className="li">If you are not sure about accepting or cannot accept the award, contact OSAC as
                                soon as possible at <AwardContactLink />.
                            </li>
                        </ul>

                        <h3 className="heading heading--h3">Enrollment</h3>
                        <ul className="list">
                            <li className="li">If you transfer schools or become dual enrolled during the academic year, notify
                                OSAC early to allow time to process the change. School changes may result
                                in adjusted or cancelled award amounts.

                            </li>
                            <li className="li">
                                If you are considering a leave of absence, contact OSAC at
                                &nbsp;<AwardContactLink />&nbsp;
                                to determine if that will jeopardize your scholarship eligibility.
                            </li>
                        </ul>

                        <h3 className="heading heading--h3">Scholarship Disbursement</h3>
                        <ul className="list">
                            <li className="li">OSAC sends funds to your school financial aid office prior to the beginning of each
                                term/semester. If you do not attend school for a term(s), you will not receive that
                                portion of your award.</li>
                            <li className="li">If your award is less than $1,000, it will be a one-time disbursement to your school fall term.</li>
                            <li className="li">You must maintain the required enrollment status (see Conditions of Award above) and satisfactory academic progress to receive disbursements
                                throughout the year.&nbsp; </li>
                            <li className="li">Scholarships must be used for educational expenses as defined by your school.&nbsp;
                                These include tuition and fees, room and board, books, transportation, and education-related
                                personal costs.</li>
                        </ul>

                        {isOcf &&
                            <>
                                <h3 className="heading heading--h3">Invitation from The Oregon Community Foundation</h3>
                                <ul className="list">
                                    <li className="li">The Oregon Community Foundation (OCF) is among the nation’s largest community foundations
                                        and sponsors more than 200 private donor scholarship programs administered by OSAC.
                                        OCF is dedicated to improving access to higher education for Oregon students.</li>
                                    <li className="li">If you are a recipient of an OCF scholarship, you may be contacted by OCF staff, donors, or selection committee members for the
                                        purposes of networking and helping to expand educational opportunities.</li>
                                    <li className="li">You will have the option to accept or decline the opportunity to participate; this
                                        will not affect your scholarship award.</li>
                                </ul>
                                <p>
                                    <a href="http://www.oregoncf.org/grants-scholarships/scholarships">Learn more</a>&nbsp;
                                    about the stories behind The Oregon Community Foundation’s (OCF) scholarship program.
                                </p>
                            </>
                        }
                    </div>

                    {!application.isAwarded &&
                        <>
                            <div className="grant-action-buttons" >
                                <Button
                                    name="Accept"
                                    label='Accept'
                                    onClick={handleAcceptAward}
                                />
                                <Button
                                    name="Decline"
                                    label='Decline'
                                    onClick={handleDeclineAward}
                                />

                                <Button
                                    name="Defer"
                                    label='Defer'
                                    onClick={handleDeferAward}
                                />
                                <Button
                                    name="Cancel"
                                    label="Cancel"
                                    variant="text"
                                    onClick={handleCancel}
                                />
                            </div>

                            {selectedAction === "Defer" &&
                                <p>
                                    If you are not attending school this year but will in the future and want to apply for a deferral,
                                    contact OSAC at <AwardContactLink />
                                </p>
                            }
                            {selectedAction === "Accept" &&
                                <>
                                    <ul className="list">
                                        <li className="li">I verify that my current college choice for </li>
                                        <li className="li">I have printed out a copy of the award announcement page that lists the Conditions of the Award (if not, click on the 'Cancel' button to return to the award announcement page). Also, I have printed out a copy of this web page</li>
                                        <li className="li">I have read the Conditions of the Award, and I am in compliance with all of the
                                            Conditions of the Award.</li>
                                        <li className="li">I understand
                                            if I transfer schools or become dual enrolled during the academic year, I must notify
                                            OSAC early to allow time to process the change. If I change schools, OSAC
                                            may adjust or cancel the amount of my award.</li>
                                        <li className="li">I understand if I am considering a leave of absence,
                                            I must check with OSAC to determine if my
                                            leave of absence will jeopardize my award eligibility.</li>
                                        <li className="li">I understand that
                                            OSAC sends funds to my school's financial aid office prior to the beginning of each term/semester. If I do not attend school for a term(s), I will not receive that portion of my award.</li>
                                        <li className="li">I must maintain the required enrollment status (see Conditions of Award) and satisfactory
                                            academic progress, as defined by my school, to receive disbursements throughout
                                            the year.</li>
                                        <li className="li">I understand that awards must be used for educational expenses as defined by my school.
                                            These expenses include tuition and fees, room and board, books, transportation,
                                            and education-related personal costs.</li>
                                    </ul>
                                    <p >
                                        <label>
                                            <CustomCheckbox name="certifiation" onChange={toggleCertification} value={isCertified} />
                                            I have read the above statements and understand and agree to them.
                                            <span className="required"> *</span>
                                        </label>

                                    </p>
                                    <div className="grant-action-buttons" >
                                        <Button name="certify" label="Certify Award Acceptance" variant="text" disabled={!isCertified} onClick={handleCertifyAcceptance} />
                                    </div>


                                </>
                            }
                            {selectedAction === "Decline" &&
                                <DeclineAward code={code} year={year} />
                            }
                        </>
                    }

                </div>
            </div>
        );
    }

    return (
        <FormProvider {...methods}>
            <PortalView>
                <div name="ApplicationsPage" className="Scholarships">
                    <div className="rhythm">
                        <div className="page-intro">
                            <div className="wrapper">
                                <div className="page-intro__layout">
                                    <div className="page-intro__icon">
                                        <img src="/dashboard.svg" alt="Student Portal" style={{ marginTop: ".25rem" }} />
                                    </div>
                                    <div className="page-intro__content rhythm">
                                        <h1 className="heading heading--h1">{program.name}</h1>
                                        <a href="/dashboard">Return to Dashboard</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {isLoading && <SpinnerSection />}
                        {!isLoading && <AcceptScholarship />}

                    </div>
                </div>
            </PortalView>
        </FormProvider>
    );
}

export default Offer;
