import { useSelector } from 'react-redux';
import Button from './Button';
import Spinner from './Utility/Spinner'

/**
 * render the common layout for a form section
 * @param {*} param0 
 *      isEditable is ture when we are in actual edit mode
 * @returns 
 */
export default function FormSection({ title, status, isEditable, onEditClick, onSaveClick, onCancelClick, children, loading, displayOnLoad, editButtonText = 'Edit' }) {
    const reduxFormState = useSelector((state) => state.form);
    const isFetchingData = reduxFormState.isLoading;
    return (
        <div className="form-section">
            <div className="form-section__header">
                <h3 className="heading heading--h4">{status} {title}</h3>
                {!isEditable && onEditClick &&
                    <Button variant="text" label={editButtonText} alt={editButtonText} onClick={onEditClick} disabled={isFetchingData} />
                }
            </div>
            <div className="form-section__content">

                {displayOnLoad && loading &&
                    <Spinner />
                }
                {(!displayOnLoad || !loading) && children}

                {isEditable && (onCancelClick || onSaveClick) &&
                    <div className="save-edit-end">
                        {onCancelClick &&
                            <Button name='CancelEditBasicInfo' label='Cancel' variant='outline' onClick={onCancelClick} disabled={isFetchingData || loading} />
                        }
                        {onSaveClick &&
                            <Button name='SaveBasicInfo' onClick={onSaveClick} label='Save' loading={loading} disabled={isFetchingData || loading} />
                        }
                    </div>
                }

            </div>
        </div>
    );
};
