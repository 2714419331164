import { actions } from '../../store';
import { useSelector } from 'react-redux';
import { useForm, FormProvider } from "react-hook-form";
import { useEffect } from 'react';
import { FieldSection, FieldRow, FieldSlot } from '../../components/Forms/FieldSection';
import { FIELDS, getField } from '../../store/form/fields';
import FormSection from '../../components/FormSection';
import { open } from "../../store/snack/actions";
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import TextAnswer, { TextVariant } from '../../components/answer/TextAnswer';
import { Alert } from '@material-ui/core';

function EditPersonalStatements({ section, onChange, onCancel }) {

  const methods = useForm({
    mode: 'onBlur'
  });
  const { errors, formState, getValues, setValue, handleSubmit } = methods;
  const reduxFormState = useSelector((state) => state.form);
  const isFetchingData = reduxFormState.isLoading;

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();

  const onSubmit = async (data, e) => {
    try {
      setLoading(true)
      const result = await actions.submitForm(data);
      // console.log(data, e);
      if (typeof onChange === 'function') {
        onChange(true);
      }
    } catch (e) {
      dispatch(open({
        message: e.message,
        severity: "success",
      }))
    } finally {
      setLoading(false)
    }

  };

  const onError = (errors, e) => {
    // console.log('ERROR',errors, e);
  }

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])

  const alertUser = e => {
    e.preventDefault()
    e.returnValue = ''
  }


  return (
    <FormProvider {...methods} >
      <form onSubmit={handleSubmit(onSubmit, onError)} >
        <FormSection title='Personal Statements' isEditable={true} onSaveClick={handleSubmit(onSubmit, onError)} onCancelClick={onCancel} loading={loading}>

            <Alert severity="info">
                <p>
                    Complete the 3 required personal statements; the fourth one is optional. Do not exceed the 1,900 character limit. Start with a strong introduction and fully
                    answer each question. Revise for clarity and spelling errors. Get feedback from others. Be honest, authentic, and show your passion.
                </p>
                <p style={{marginTop: '1em'}}>
                    Note: Timeouts may occur, causing you to lose unsaved work. We encourage you to draft your responses in a separate document first. Once finalized, 
                    copy your statements into the scholarship application.
                </p>
            </Alert>

          <FieldSection withDivider={true}>
            <FieldRow>
              <FieldSlot md={12}>
                <br />
                <TextAnswer answer={section.getAnswerByField(FIELDS.ESSAY_1)} variant={TextVariant.ESSAY}
                  inputLabel="What are your specific educational plans and career goals and why? What motivates you to achieve them?"
                  isRequired={true}
                  placeholder='' className="mt-2" characterLimit="1900" />

                <br />
                <TextAnswer answer={section.getAnswerByField(FIELDS.ESSAY_2)} variant={TextVariant.ESSAY}
                  inputLabel="What have you done for your family, school, or community that you care about the most and why?"
                  placeholder='' className="mt-2" characterLimit="1900" />

                <br />
                <TextAnswer answer={section.getAnswerByField(FIELDS.ESSAY_3)} variant={TextVariant.ESSAY}
                  inputLabel="Describe a personal accomplishment, impactful change, or experience that has occurred in your life. What skills and strengths were needed to respond, and what did you learn about yourself?"
                  placeholder='' className="mt-2" characterLimit="1900" />

                <br />
                <TextAnswer answer={section.getAnswerByField(FIELDS.ESSAY_4)} variant={TextVariant.ESSAY}
                  inputLabel={getField(FIELDS.ESSAY_4).question} placeholder='' className="mt-2" characterLimit="1900" />

              </FieldSlot>
            </FieldRow>
          </FieldSection>

        </FormSection>
      </form>
    </FormProvider>
  );
}

export default EditPersonalStatements;
