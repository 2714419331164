
export const FIELDS = {
    FIRST_NAME: 'FIRST_NAME',
    LAST_NAME: 'LAST_NAME',
    MIDDLE_INITIALS: 'MIDDLE_INITIALS',
    BIRTHDATE: 'BIRTHDATE',
    HOME_PHONE: 'HOME_PHONE',
    RESIDENCE_ADDRESS: 'RESIDENCE_ADDRESS',
    PERMANENT_ADDRESS: 'PERMANENT_ADDRESS',
    ALTERNATE_PHONE: 'ALTERNATE_PHONE',
    GENDER__MALE: 'GENDER__MALE',
    GENDER__FEMALE: 'GENDER__FEMALE',
    GENDER__TRANSMAN: 'GENDER__TRANSMAN',
    GENDER__TRANSWOMAN: 'GENDER__TRANSWOMAN',
    GENDER__NON_BINARY: 'GENDER__NON_BINARY',
    GENDER__OTHER: 'GENDER__OTHER',
    GENDER__NA: 'GENDER__NA',
    GED_SCORE: "GED_SCORE",
    GED_IN_PROGRESS_FLAG: "GED_IN_PROGRESS_FLAG",
    ASPIRE__MEMBER: 'ASPIRE__MEMBER',
    ASPIRE__ADVISOR: 'ASPIRE__ADVISOR',
    ASPIRE__SITE: 'ASPIRE__SITE',
    CORRECTIONAL_FACILITY_HS: 'CORRECTIONAL_FACILITY_HS',
    CORRECTIONAL_FACILITY_CC: 'CORRECTIONAL_FACILITY_CC',
    CORRECTIONAL_FACILITY_RELEASE_DATE: 'CORRECTIONAL_FACILITY_RELEASE_DATE',
    CORRECTIONAL_FACILITY_TYPE: 'CORRECTIONAL_FACILITY_TYPE',
    CORRECTIONAL_FACILITY_NAME: 'CORRECTIONAL_FACILITY_NAME',
    CORRECTIONAL_FACILITY_VERIFICATION_ALLOW: 'CORRECTIONAL_FACILITY_VERIFICATION_ALLOW',
    FIFTH_YEAR_SENIOR: 'FIFTH_YEAR_SENIOR',
    HIGHSCHOOL_GPA: 'HIGHSCHOOL_GPA',
    ACT: 'ACT',
    SAT: 'SAT',
    HIGHSCHOOL_GRAD_DATE: 'HIGHSCHOOL_GRAD_DATE',
    ETHNICITY__AM_INDIAN: 'RaceAmIndianFlag',
    ETHNICITY__ASIAN: 'RaceAsianFlag',
    ETHNICITY__BLACK: 'RaceBlackFlag',
    ETHNICITY__HAWAIIAN_PI: 'RaceNativeHiPiFlag',
    ETHNICITY__WHITE: 'RaceWhiteFlag',
    ETHNICITY__NA: 'RaceNAFlag',
    ETHNICITY__OTHER: 'EthnicOther',
    FAFSA_FILING_DATE: 'FAFSA_FILING_DATE',
    SSN: 'SSN',
    TEST_APPLICATION: 'TEST_APPLICATION',
    OREGON_RESIDENT: 'OREGON_RESIDENT',
    WORKING__CURRENT: 'WORKING__CURRENT',
    WORKING__NEXT_YEAR: 'WORKING__NEXT_YEAR',
    FOSTER_CARE: 'FOSTER_CARE',
    EARLY_LEARNING_MAJOR: 'EARLY_LEARNING_MAJOR',
    COLLEGE_DUAL_ENROLLED: 'COLLEGE_DUAL_ENROLLED',
    OSAC_REFERRAL: 'OSAC_REFERRAL',
    PUBLICITY_RELEASE: 'PUBLICITY_RELEASE',
    PRIVACY_AGREEMENT: 'PRIVACY_AGREEMENT',
    MAILING_ADDRESS: 'MAILING_ADDRESS',
    CERTIFY_IDENTITY: 'CERTIFY_IDENTITY',
    CITIZENSHIP_STATUS: 'CITIZENSHIP_STATUS',
    HISPANIC: 'HISPANIC',
    COLLEGE_STANDING: 'COLLEGE_STANDING',
    COLLEGE_CHOICE: 'COLLEGE_CHOICE',
    PRIMARY_CONTACT: 'PRIMARY_CONTACT',
    PRIMARY_PHONE_CELL_FLAG: 'PRIMARY_PHONE_CELL_FLAG',
    ALTERNATE_PHONE_CELL_FLAG: 'ALTERNATE_PHONE_CELL_FLAG',
    COLLEGE_HISTORY_COMPLETE: 'COLLEGE_HISTORY_COMPLETE',
    MARITAL_STATUS: 'MARITAL_STATUS',
    PARENTS_MARITAL_STATUS: 'PARENTS_MARITAL_STATUS',
    ED_LEVEL_FATHER: 'ED_LEVEL_FATHER',
    ED_LEVEL_MOTHER: 'ED_LEVEL_MOTHER',
    FIRST_GEN_IMMIGRANT: 'FIRST_GEN_IMMIGRANT',
    IsReceivingERDCSubsidy: 'IsReceivingERDCSubsidy',
    ERDCSubsidyExpirationDate: 'ERDCSubsidyExpirationDate',
    CHILD_CARE_PROVIDER__NAME: 'CHILD_CARE_PROVIDER__NAME',
    CHILD_CARE_PROVIDER__ADDRESS: 'CHILD_CARE_PROVIDER__ADDRESS',
    CHILD_CARE_PROVIDER__STATE: 'CHILD_CARE_PROVIDER__STATE',
    CHILD_CARE_PROVIDER__CITY: 'CHILD_CARE_PROVIDER__CITY',
    CHILD_CARE_PROVIDER__ZIP: 'CHILD_CARE_PROVIDER__ZIP',
    CHILD_CARE_PROVIDER__PHONE: 'CHILD_CARE_PROVIDER__PHONE',
    CHILD_CARE_PROVIDER__START_DATE: 'CHILD_CARE_PROVIDER__START_DATE',
    CHILD_CARE_PROVIDER__NEW: 'CHILD_CARE_PROVIDER__NEW',
    CHILD_CARE_PROVIDER__LISTED: 'CHILD_CARE_PROVIDER__LISTED',
    CHILD_CARE_PROVIDER__REGISTERED: 'CHILD_CARE_PROVIDER__REGISTERED',
    CHILD_CARE_PROVIDER__PARENT: 'CHILD_CARE_PROVIDER__PARENT',

    // Fields need to be added -> Child Care Grant Application
    CHILD_CARE_PROVIDER2__NAME: 'CHILD_CARE_PROVIDER2__NAME',
    CHILD_CARE_PROVIDER2__ADDRESS: 'CHILD_CARE_PROVIDER2__ADDRESS',
    CHILD_CARE_PROVIDER2__STATE: 'CHILD_CARE_PROVIDER2__STATE',
    CHILD_CARE_PROVIDER2__CITY: 'CHILD_CARE_PROVIDER2__CITY',
    CHILD_CARE_PROVIDER2__ZIP: 'CHILD_CARE_PROVIDER2__ZIP',
    CHILD_CARE_PROVIDER2__PHONE: 'CHILD_CARE_PROVIDER2__PHONE',
    CHILD_CARE_PROVIDER2__START_DATE: 'CHILD_CARE_PROVIDER2__START_DATE',
    CHILD_CARE_PROVIDER2__NEW: 'CHILD_CARE_PROVIDER2__NEW',
    CHILD_CARE_PROVIDER2__LISTED: 'CHILD_CARE_PROVIDER2__LISTED',
    CHILD_CARE_PROVIDER2__REGISTERED: 'CHILD_CARE_PROVIDER2__REGISTERED',
    CHILD_CARE_PROVIDER2__PARENT: 'CHILD_CARE_PROVIDER2__PARENT',

    NATIONAL_GUARD__UNIT: 'NATIONAL_GUARD__UNIT',
    NATIONAL_GUARD__CREDITS_PRESUMMER: 'NATIONAL_GUARD__CREDITS_PRESUMMER',
    NATIONAL_GUARD__CREDITS_FALL: 'NATIONAL_GUARD__CREDITS_FALL',
    NATIONAL_GUARD__CREDITS_WINTER: 'NATIONAL_GUARD__CREDITS_WINTER',
    NATIONAL_GUARD__CREDITS_SPRING: 'NATIONAL_GUARD__CREDITS_SPRING',
    NATIONAL_GUARD__CREDITS_POSTSUMMER: 'NATIONAL_GUARD__CREDITS_POSTSUMMER',

    // OTSG QUESTIONS
    OTSG__ATTENDING_FALL_TERM: 'OTSG__ATTENDING_FALL_TERM',
    OTSG__ATTENDING_WINTER_TERM: 'OTSG__ATTENDING_WINTER_TERM',
    OTSG__ATTENDING_SPRING_TERM: 'OTSG__ATTENDING_SPRING_TERM',
    OTSG__ENROLLED_TRIBE: 'OTSG__ENROLLED_TRIBE',

    UNDERGRAD_GPA: 'UNDERGRAD_GPA',
    GRADUATE_GPA: 'GRADUATE_GPA',
    FINISHED_COMM_COLLEGE: 'FINISHED_COMM_COLLEGE',
    FINISHED_BACHELORS_DEGREE: 'FINISHED_BACHELORS_DEGREE',
    PRONOUNS__FEMALE: 'PRONOUNS__FEMALE',
    PRONOUNS__MALE: 'PRONOUNS__MALE',
    PRONOUNS__NEUTRAL: 'PRONOUNS__NEUTRAL',
    PRONOUNS__OTHER: 'PRONOUNS__OTHER',
    CAREER_GOAL: 'CAREER_GOAL',
    OTHER_NAMES: 'OTHER_NAMES',
    AGE_CHILD: 'AGE_CHILD',
    HIGHSCHOOL_TYPE: 'HIGHSCHOOL_TYPE',
    HIGHSCHOOL: 'HIGHSCHOOL',

    CURRENT_COLLEGE: 'CURRENT_COLLEGE',
    PAST_COLLEGE: 'PAST_COLLEGE',
    DEGREES: 'DEGREES',

    CHAFEE__PRESUMMER_1: 'CHAFEE__PRESUMMER_1',
    CHAFEE__FALL_1: 'CHAFEE__FALL_1',
    CHAFEE__WINTER_1: 'CHAFEE__WINTER_1',
    CHAFEE__SPRING_1: 'CHAFEE__SPRING_1',
    CHAFEE__POSTSUMMER_1: 'CHAFEE__POSTSUMMER_1',
    CHAFEE__PRESUMMER_2: 'CHAFEE__PRESUMMER_2',
    CHAFEE__FALL_2: 'CHAFEE__FALL_2',
    CHAFEE__WINTER_2: 'CHAFEE__WINTER_2',
    CHAFEE__SPRING_2: 'CHAFEE__SPRING_2',
    CHAFEE__POSTSUMMER_2: 'CHAFEE__POSTSUMMER_2',
    CHAFEE__FOSTER_CARE: 'CHAFEE__FOSTER_CARE',
    CHAFEE__FOSTER_DHS: 'CHAFEE__FOSTER_DHS',
    CHAFEE__FOSTER_OTHER_STATE: 'CHAFEE__FOSTER_OTHER_STATE',
    CHAFEE__FOSTER_TRIBAL: 'CHAFEE__FOSTER_TRIBAL',
    CHAFEE__ADOPTED: 'CHAFEE__ADOPTED',
    CHAFEE__TRIBE: 'CHAFEE__TRIBE',
    CHAFEE__OTHER_STATE_ID: 'CHAFEE__OTHER_STATE_ID',
    CHAFEE__FOSTER_QUALIFIED: 'CHAFEE__FOSTER_QUALIFIED',
    CHAFEE__ADOPTION_FINALIZED: 'CHAFEE__ADOPTION_FINALIZED',

    COMM_COLLEGE_START_TERM: 'COMM_COLLEGE_START_TERM',
    CHILDREN: 'CHILDREN',
    NATIONAL_GUARD__BRANCH: 'NATIONAL_GUARD__BRANCH',
    PROOF_GPA__UPLOAD: 'PROOF_GPA__UPLOAD',
    PROOF_GPA__MAIL: 'PROOF_GPA__MAIL',
    PROOF_GPA__REGISTRAR: 'PROOF_GPA__REGISTRAR',

    ACTIVITY_WORK: 'ACTIVITY_WORK',
    ACTIVITY_SCHOOL: 'ACTIVITY_SCHOOL',
    ACTIVITY_VOLUNTEER: 'ACTIVITY_VOLUNTEER',
    HAS_NO_SCHOOL_ACTIVITIES: 'HAS_NO_SCHOOL_ACTIVITIES',
    HAS_NO_VOLUNTEER_ACTIVITIES: 'HAS_NO_VOLUNTEER_ACTIVITIES',
    HAS_NO_WORK_ACTIVITIES: 'HAS_NO_WORK_ACTIVITIES',
    ACTIVITIES_COMPLETE: 'ACTIVITIES_COMPLETE',
    FALL_HS_TRANSCRIPTS_DIGITAL: 'FALL_HS_TRANSCRIPTS_DIGITAL',
    FALL_HS_TRANSCRIPTS_MAIL: 'FALL_HS_TRANSCRIPTS_MAIL',
    FALL_HS_TRANSCRIPTS_REGISTRAR: 'FALL_HS_TRANSCRIPTS_REGISTRAR',

    FALL_COLLEGE_TRANSCRIPTS_DIGITAL: 'FALL_COLLEGE_TRANSCRIPTS_DIGITAL',    
    FALL_COLLEGE_TRANSCRIPTS_MAIL: 'FALL_COLLEGE_TRANSCRIPTS_MAIL',

    FALL_COLLEGE_TRANSCRIPTS_PRIOR_DIGITAL: 'FALL_COLLEGE_TRANSCRIPTS_PRIOR_DIGITAL',    
    FALL_COLLEGE_TRANSCRIPTS_PRIOR_MAIL: 'FALL_COLLEGE_TRANSCRIPTS_PRIOR_MAIL',

    FALL_COLLEGE_TRANSCRIPTS_RECENT_DIGITAL: 'FALL_COLLEGE_TRANSCRIPTS_RECENT_DIGITAL',    
    FALL_COLLEGE_TRANSCRIPTS_RECENT_MAIL: 'FALL_COLLEGE_TRANSCRIPTS_RECENT_MAIL',

    FALL_COLLEGE_TRANSCRIPTS_DURING_HS_DIGITAL: 'FALL_COLLEGE_TRANSCRIPTS_DURING_HS_DIGITAL',    
    FALL_COLLEGE_TRANSCRIPTS_DURING_HS_MAIL: 'FALL_COLLEGE_TRANSCRIPTS_DURING_HS_MAIL',

    ESSAY_1: 'ESSAY_1',
    ESSAY_2: 'ESSAY_2',
    ESSAY_3: 'ESSAY_3',
    ESSAY_4: 'ESSAY_4',
    SPRING_COLLEGE_TRANSCRIPTS_DIGITAL: 'SPRING_COLLEGE_TRANSCRIPTS_DIGITAL',
    SPRING_COLLEGE_TRANSCRIPTS_MAIL: 'SPRING_COLLEGE_TRANSCRIPTS_MAIL',
    SPRING_HS_TRANSCRIPTS_DIGITAL: 'SPRING_HS_TRANSCRIPTS_DIGITAL',
    SPRING_HS_TRANSCRIPTS_MAIL: 'SPRING_HS_TRANSCRIPTS_MAIL',
    CHILD_CARE_EXPENSES_MAIL: 'CHILD_CARE_EXPENSES_MAIL',
    CHILD_CARE_EXPENSES_DIGITAL: 'CHILD_CARE_EXPENSES_DIGITAL',
    FUND_UPLOAD_MAIL: "FUND_UPLOAD_MAIL",
    FUND_UPLOAD_DIGITAL: "FUND_UPLOAD_DIGITAL",

    IS_NATIONAL_GUARD_MEMBER: "IS_NATIONAL_GUARD_MEMBER",
    IS_TEACHER_CANDIDATE_MEMBER: "IS_TEACHER_CANDIDATE_MEMBER",
    HAS_DEPENDENT_CHILDREN: "HAS_DEPENDENT_CHILDREN",   

    TEACHER_WORKING: "TeacherWorking",
    TEACHER_WORKING20HOURS: 'TeacherWorking20Hours',
    TEACHER_WORKINGKTHRU12: 'TeacherWorkingKthru12',
    TEACHER_ROLE: 'TeachingRole',
    TEACHER_DISTRICT: 'TeachingDistrict',
    UNLISTED_TEACHER_DISTRICT: 'UnlistedTeachingDistrict',
    TEACHER_FLUENCY: 'TeacherFluency',
    TEACHER_LISENCE_BEGIN: 'TeacherLicenseBegin',
    TEACHER_LICENSE_COMPLETE: 'TeacherLicenseProgramComplete',
    TEACHER_LICENSE_TYPE: 'TeacherLicenseType',
    TEACHER_LICENSE_ENDORSEMENTS: 'TeacherLicenseEndorsements',
    TEACHER_SCHOLARS_REFERRAL: 'TeacherScholarsAppReferral'
}

/**
 * how do the transcript answer fields tie together to make a specific question answer
 */
export const TRANSCRIPT_QUESTIONS = {
    // spring grades, most colleges
    SPRING_COLLEGE: {
        name : "SpringCollegeTranscripts",
        description: "College Transcripts",
        mailField : FIELDS.SPRING_COLLEGE_TRANSCRIPTS_MAIL,
        uploadField : FIELDS.SPRING_COLLEGE_TRANSCRIPTS_DIGITAL,
        term : 'SPRING',
        level: 'College'
    },
    SPRING_HS: {
        name : "SpringHighSchoolTranscript",
        description: "High School Transcripts",
        mailField : FIELDS.SPRING_HS_TRANSCRIPTS_MAIL,
        uploadField : FIELDS.SPRING_HS_TRANSCRIPTS_DIGITAL,
        term : 'SPRING',
        level: 'HighSchool'       
    },
    // fall grades, all colleges up to fall
    FALL_COLLEGE: {
        name : "FallCollegeTranscripts",
        mailField : FIELDS.FALL_COLLEGE_TRANSCRIPTS_MAIL,
        uploadField : FIELDS.FALL_COLLEGE_TRANSCRIPTS_DIGITAL,
        level: 'College'
    },
    // fall grades within last 10 years
    FALL_COLLEGE_RECENT:{
        name: "FallCollegeRecentTranscripts",
        description: "Recent College Transcripts",
        mailField : FIELDS.FALL_COLLEGE_TRANSCRIPTS_RECENT_MAIL,
        uploadField : FIELDS.FALL_COLLEGE_TRANSCRIPTS_RECENT_DIGITAL,
        level: 'College'
    },
    // fall grades, over 10 years ago
    FALL_COLLEGE_PRIOR:{
        name: "FallCollegePriorTranscripts",
        description: "Prior College Transcripts",
        mailField : FIELDS.FALL_COLLEGE_TRANSCRIPTS_PRIOR_MAIL,
        uploadField : FIELDS.FALL_COLLEGE_TRANSCRIPTS_PRIOR_DIGITAL,
        level: 'College' 
    },
    // any fall grades taken while also attending high school
    FALL_COLLEGE_DURING_HS: {
        name: "FallCollegeDuringHighSchoolTranscripts",
        description: "College Transcripts From During High School",
        mailField : FIELDS.FALL_COLLEGE_TRANSCRIPTS_DURING_HS_MAIL,
        uploadField : FIELDS.FALL_COLLEGE_TRANSCRIPTS_DURING_HS_DIGITAL,
        level: 'College'   
    },
    FALL_HS: {
        name: "FallHighSchoolTranscript",
        description: "High School Transcript",
        mailField : FIELDS.FALL_HS_TRANSCRIPTS_MAIL,
        uploadField : FIELDS.FALL_HS_TRANSCRIPTS_DIGITAL,
        registrarField : FIELDS.FALL_HS_TRANSCRIPTS_REGISTRAR,
        level: 'HighSchool'    
    },
    PROOF_OF_GPA: {
        name: "ProofOfGpa",
        description: "Proof of GPA",
        mailField : FIELDS.PROOF_GPA__MAIL,
        uploadField : FIELDS.PROOF_GPA__UPLOAD,
        registrarField : FIELDS.PROOF_GPA__REGISTRAR,    
        level: 'HighSchool'  
    },
    CHILDCARE_EXPENSES: {
        name: "ProofOfChildcareExpense",
        description: "Proof of Child Care Expenses",
        mailField : FIELDS.CHILD_CARE_EXPENSES_MAIL,
        uploadField : FIELDS.CHILD_CARE_EXPENSES_DIGITAL,
        level: 'Document'  
    }
};



export const REPEATABLE_FIELDS = [
    FIELDS.AGE_CHILD,
    FIELDS.OTHER_NAMES,
    FIELDS.ACTIVITY_SCHOOL,
    FIELDS.ACTIVITY_VOLUNTEER,
    FIELDS.ACTIVITY_WORK,
    FIELDS.PAST_COLLEGE,
    FIELDS.CURRENT_COLLEGE,
    FIELDS.DEGREES,
    FIELDS.CHILDREN,
    FIELDS.TEACHER_LICENSE_ENDORSEMENTS
    // FIELDS.SPRING_COLLEGE_TRANSCRIPTS_DIGITAL,
];

export const ID = 'Id';

export const AnswerTypeAddressProps = {
    ID,
    ADDRESS: 'Address',
    COUNTY: 'CountyId',
    STATE: 'StateId',
    CITY: 'CityId',
    ZIP: 'Zip',
    UNLISTED_STATE_NAME: 'UnlistedStateName',
    UNLISTED_CITY_NAME: 'UnlistedCityName',
}

export const UNLISTED_COLLEGECODE = "999999";

export const AnswerTypeCollegeProps = {
    ID,
    SCHOOL_CODE: 'SchoolCode',
    START_TERM: 'StartTerm',
    START_YEAR: 'StartYear',
    END_TERM: 'StopTerm',
    END_YEAR: 'StopYear',   
    CREDITS: 'CreditsEarned',
    DEGREE: 'DegreeEarned',
    CODE: 'SchoolCode',
    UNLISTED_CODE: 'UnlistedCode',
    UNLISTED_NAME: 'UnlistedName',
    UNLISTED_STATE_NAME: 'UnlistedStateName',
    UNLISTED_CITY_NAME: 'UnlistedCityName',
    ADDRESS: 'Address',
    COUNTY: 'CountyId',
    STATE: 'StateId',
    CITY: 'CityId',
    ZIP: 'Zip',
    TYPE: 'Type',
}

export const AnswerTypeContactProps = {
    ID,
    LAST_NAME: 'LastName',
    FIRST_NAME: 'FirstName',
    RELATION: 'RelationshipTypeId',
    RELATION_UNLISTED: 'UnlistedRelationship',
    INCLUDE_EMAILS: 'IncludeInEmails',
    EMAIL: 'Email',
    CITY: 'CityId',
    COUNTY: 'CountyId',
    STATE: 'StateId',
    UNLISTED_STATE_NAME: 'UnlistedStateName',
    UNLISTED_CITY_NAME: 'UnlistedCityName',
    ZIP: 'Zip',
    ADDRESS: 'Address',
    PHONE: 'HomePhone',
    SEND_TEXTS: 'IsHomePhoneText',
    LOCATION_NAME: 'LocationName'
}

export const AnswerTypeMemberProps = {
    ID,
    FULL_NAME: 'FullName',
    RELATION: 'RelationshipCode',
    RELATION_UNLISTED: 'UnlistedRelationship',
    INCLUDE_EMAILS: 'IncludeInEmails',
    EMAIL: 'Email',
    UNLISTED_STATE_NAME: 'UnlistedStateName',
    UNLISTED_CITY_NAME: 'UnlistedCityName',
    PHONE: 'HomePhone',
    LOCATION_NAME: 'LocationName',
    QUALIFIED_BY_MEMBER: 'IsQualifiedByMembership'
}

export const AnswerTypeNameProps = {
    ID,
    FIRST_NAME: 'FirstName',
    LAST_NAME: 'LastName',
    MIDDLE_NAME: 'MiddleName'
}

export const AnswerTypeHighschoolProps = {
    ID,
    SCHOOL_CODE: 'SchoolCode',
    START: 'AttendedStart',
    END: 'AttendedStop',
    CREDITS: 'CreditsEarned',
    DEGREE: 'DegreeEarned',
    UNLISTED_CODE: 'UnlistedCode',
    UNLISTED_NAME: 'UnlistedName',
    UNLISTED_STATE_NAME: 'UnlistedStateName',
    UNLISTED_CITY_NAME: 'UnlistedCityName',
    ADDRESS: 'Address',
    COUNTY: 'CountyId',
    STATE: 'StateId',
    CITY: 'CityId',
    ZIP: 'Zip',
    TYPE: 'Type'
}

export const AnswerTypeActivityProps = {
    ID,
    TITLE: 'Title',
    START_DATE: 'StartDate',
    END_DATE: 'StopDate',
    FREQUENCY: 'FrequencyCode',
    TYPE: 'ActivityTypeCode',
    ACCOMPLISHMENTS: 'Accomplishments',
    HOURS_PER_UNIT: 'HoursPerUnit',
    TOTAL_HOURS: 'TotalHours'
}

export const AnswerTypeDegreeProps = {
    ID,
    PRIMARY_MAJOR: 'CIPMajorId',
    SECONDARY_MAJOR: 'CIPMajorId2',
    EXPECTED_GRADUATION_DATE: 'GraduationDate',
    DEGREE: 'DegreeId',
}

export const AnswerTypeChildProps = {
    ID,
    AMOUNT: 'AverageCareExpense',
    BIRTHDATE: 'BirthDate',
    CITIZENSHIP: 'CitizenshipCode',
    FIRST_NAME: 'FirstName',
    GENDER: 'GenderCode',
    CHILD_CARE_NEEDED: 'IsChildCareNeeded',
    EXTRA_CARE_NEEDED: 'IsExtraCareNeeded',
    LAST_NAME: 'LastName',
    MIDDLE_INITIAL: 'MiddleInitial',
    _FILE: '_FILE'
}

export const AnswerTypeTranscriptProps = {
    ID,
    IS_SELECTED: 'IsSelected',
    IS_FILE_AVAILABLE: 'IsFileAvailable',
    DELIVERY_TYPE: 'DeliveryType',
    SCHOOL_CODE: 'SchoolCode',
    SCHOOL_ANSWER_ID: 'SchoolAnswerId',
    REVIEW_STATUS_DESCRIPTION: 'ReviewStatusDescription',
    IS_CERTIFIED: 'IsCertified',
    _FILE: '_FILE'
}

export const AnswerTypeDocumentProps = {
    ID,
    IS_SELECTED: 'IsSelected',
    IS_CERTIFIED: 'IsCertified',
    IS_FILE_AVAILABLE: 'IsFileAvailable',
    DELIVERY_TYPE: 'DeliveryType',
    REVIEW_STATUS_DESCRIPTION: 'ReviewStatusDescription',
    _FILE: '_FILE'
}

export const TranscriptDeliveryTypes = {
    UPLOAD: 'Upload',
    MAIL: 'Mail',
    REGISTRAR: 'Registrar'
}

export const ActivityTypeCodes = {
    WORK: 'WRK',
    SCHOOL: 'SCH',
    VOLUNTEER: 'VOL'
}

export const TranscriptTypeCodes = {
    UPLOAD: 'Upload',
    MAIL: 'Mail',
    REGISTRAR: 'Registrar'
}

export const DocumentDeliveryTypes = {
    UPLOAD: 'Upload',
    MAIL: 'Mail',
}

// Basic Field Types
export const TextFieldType = {
    AnswerType: "Text"
}

export const DateFieldType = {
    AnswerType: "Date"
}

export const BooleanFieldType = {
    AnswerType: "Boolean",
    IsNullable: true,
}

export const DecimalFieldType = {
    AnswerType: "Decimal"
}

export const IntegerFieldType = {
    AnswerType: "Integer"
}

// Composite Field Types
export const CollegeFieldType = {
    AnswerType: "College",
    Props: AnswerTypeCollegeProps
}

export const ContactFieldType = {
    AnswerType: "Contact",
    Props: AnswerTypeContactProps
}

export const NameFieldType = {
    AnswerType: "Name",
    Props: AnswerTypeNameProps
}

export const AddressFieldType = {
    AnswerType: "Address",
    Props: AnswerTypeAddressProps
}

export const ActivityFieldType = {
    AnswerType: "Activity",
    Props: AnswerTypeActivityProps
}

export const DegreeFieldType = {
    AnswerType: "Degree",
    Props: AnswerTypeDegreeProps
}

export const HighSchoolFieldType = {
    AnswerType: "HighSchool",
    Props: AnswerTypeHighschoolProps
}

export const ChildFieldType = {
    AnswerType: "Dependent",
    Props: AnswerTypeChildProps
}

export const TranscriptFieldType = {
    AnswerType: "Transcript",
    Props: AnswerTypeTranscriptProps
}

export const MembershipFieldType = {
    AnswerType: 'Membership',
    Props: AnswerTypeMemberProps
}

export const DocumentFieldType = {
    AnswerType: 'Document',
    Props: AnswerTypeDocumentProps
}

// Field -> QuestionKey mapping


export const QuestionKeyMapping = {
    [FIELDS.FIRST_NAME]: {
        ...TextFieldType,
        QuestionKey: 'AppStudentLogin.FirstName',
        Title: 'First name'
    },
    [FIELDS.LAST_NAME]: {
        ...TextFieldType,
        QuestionKey: 'AppStudentLogin.LastName',
        Title: 'Last name'
    },
    [FIELDS.MIDDLE_INITIALS]: {
        ...TextFieldType,
        QuestionKey: 'AppStudentLogin.MiddleInitial',
        Title: 'Middle initial'
    },
    [FIELDS.BIRTHDATE]: {
        ...DateFieldType,
        QuestionKey: 'AppStudentLogin.Birthdate',
        Title: 'Birthdate',
    },
    [FIELDS.HOME_PHONE]: {
        ...TextFieldType,
        QuestionKey: 'homePhone',
        Title: 'Home phone'
    },
    [FIELDS.PRIMARY_PHONE_CELL_FLAG]: {
        ...BooleanFieldType,
        QuestionKey: 'PrimaryPhoneCellFlag',        
    },
    [FIELDS.ALTERNATE_PHONE_CELL_FLAG]: {
        ...BooleanFieldType,
        QuestionKey: 'AlternatePhoneCellFlag'
    },
    [FIELDS.ALTERNATE_PHONE]: {
        ...TextFieldType,
        QuestionKey: 'alternatePhone',
        Title: 'Phone'
    },
    [FIELDS.RESIDENCE_ADDRESS]: {
        ...AddressFieldType,
        QuestionKey: 'ResidenceAddress',
        Title: 'Residence Address'
    },
    [FIELDS.PERMANENT_ADDRESS]: {
        ...AddressFieldType,
        QuestionKey: 'PermanentAddress',
        Title: 'Permanent Address'
    },
    [FIELDS.GENDER__MALE]: {
        ...BooleanFieldType,
        QuestionKey: 'GenderMale',
        Title: 'Male'
    },
    [FIELDS.GENDER__FEMALE]: {
        ...BooleanFieldType,
        QuestionKey: 'GenderFemale',
        Title: 'Female'
    },
    [FIELDS.GENDER__TRANSMAN]: {
        ...BooleanFieldType,
        QuestionKey: 'GenderTransman',
        Title: 'Trans man'
    },
    [FIELDS.GENDER__TRANSWOMAN]: {
        ...BooleanFieldType,
        QuestionKey: 'GenderTranswoman',
        Title: 'Trans woman'
    },
    [FIELDS.GENDER__NON_BINARY]: {
        ...BooleanFieldType,
        QuestionKey: 'GenderNonbinary',
        Title: 'Nonbinary'
    },
    [FIELDS.GENDER__OTHER]: {
        ...BooleanFieldType,
        QuestionKey: 'GenderOther'
    },
    [FIELDS.GENDER__NA]: {
        ...BooleanFieldType,
        QuestionKey: 'GenderNA'
    },
    [FIELDS.ASPIRE__MEMBER]: {
        ...BooleanFieldType,
        QuestionKey: 'ASPIREmemberFlag'
    },
    [FIELDS.ASPIRE__ADVISOR]: {
        ...TextFieldType,
        QuestionKey: 'ASPIREadvisorName'
    },
    [FIELDS.ASPIRE__SITE]: {
        ...TextFieldType,
        QuestionKey: 'ASPIREsiteName'
    },
    [FIELDS.CORRECTIONAL_FACILITY_HS]: {
        ...TextFieldType,
        QuestionKey: 'AttendWhileInCorrectionalFacility'
    },
    [FIELDS.CORRECTIONAL_FACILITY_TYPE]: {
        ...IntegerFieldType,
        QuestionKey: 'CorrectionalFacilityType',
        IsSelectable: true
    },
    [FIELDS.CORRECTIONAL_FACILITY_CC]: {
        ...TextFieldType,
        QuestionKey: 'CorrectionalFacilityCompletion'
    },
    [FIELDS.CORRECTIONAL_FACILITY_RELEASE_DATE]: {
        ...DateFieldType,
        QuestionKey: 'CorrectionalFacilityReleaseDate'
    },
    [FIELDS.CORRECTIONAL_FACILITY_NAME]: {
        ...TextFieldType,
        QuestionKey: 'OtherCorrectionalFacilityName'
    },
    [FIELDS.CORRECTIONAL_FACILITY_VERIFICATION_ALLOW]: {
        ...TextFieldType,
        QuestionKey: 'AllowCorrectionalFacilityVerification'
    },
    [FIELDS.FIFTH_YEAR_SENIOR]: {
        ...TextFieldType,
        QuestionKey: 'HighSchoolClassStanding',
        Title: '5th Year Senior'
    },
    [FIELDS.HIGHSCHOOL_GPA]: {
        ...DecimalFieldType,
        QuestionKey: 'HsGpa',
        Title: 'High School GPA (Unweighted, Cumulative)'
    },
    [FIELDS.ACT]: {
        ...TextFieldType,
        QuestionKey: 'ACT',
        Title: 'ACT Score'
    },
    [FIELDS.SAT]: {
        ...IntegerFieldType,
        QuestionKey: 'SATTotal',
        Title: 'Total SAT'
    },
    [FIELDS.HIGHSCHOOL_GRAD_DATE]: {
        ...DateFieldType,
        QuestionKey: 'HsGradDate',
        Title: 'High School Graduation Date'
    },
    // OTSG QUESTIONS
    [FIELDS.OTSG__ATTENDING_FALL_TERM]: {
        ...BooleanFieldType,
        QuestionKey: 'AttendingFallTerm'
    },
    [FIELDS.OTSG__ATTENDING_WINTER_TERM]: {
        ...BooleanFieldType,
        QuestionKey: 'AttendingWinterTerm'
    },
    [FIELDS.OTSG__ATTENDING_SPRING_TERM]: {
        ...BooleanFieldType,
        QuestionKey: 'AttendingSpringTerm'
    },
    [FIELDS.OTSG__ENROLLED_TRIBE]: {
        ...TextFieldType,
        QuestionKey: 'EnrolledTribe'
    },
    [FIELDS.ETHNICITY__AM_INDIAN]: {
        ...BooleanFieldType,
        QuestionKey: 'RaceAmIndianFlag'
    },
    [FIELDS.ETHNICITY__ASIAN]: {
        ...BooleanFieldType,
        QuestionKey: 'RaceAsianFlag'
    },
    [FIELDS.ETHNICITY__BLACK]: {
        ...BooleanFieldType,
        QuestionKey: 'RaceBlackFlag'
    },
    [FIELDS.ETHNICITY__HAWAIIAN_PI]: {
        ...BooleanFieldType,
        QuestionKey: 'RaceNativeHiPiFlag'
    },
    [FIELDS.ETHNICITY__WHITE]: {
        ...BooleanFieldType,
        QuestionKey: 'RaceWhiteFlag'
    },
    [FIELDS.ETHNICITY__NA]: {
        ...BooleanFieldType,
        QuestionKey: 'RaceNAFlag'
    },
    [FIELDS.FAFSA_FILING_DATE]: {
        ...DateFieldType,
        QuestionKey: 'DateFafsaFiled'
    },
    [FIELDS.SSN]: {
        ...TextFieldType,
        QuestionKey: 'appstudentlogin.ssn'
    },
    [FIELDS.TEST_APPLICATION]: {
        ...BooleanFieldType,
        QuestionKey: 'TestAppFlag',
        IsNullable: false,
        Title: 'Test Applicant'

    },
    [FIELDS.OREGON_RESIDENT]: {
        ...BooleanFieldType,
        QuestionKey: 'residencyFlag'
    },
    [FIELDS.WORKING__CURRENT]: {
        ...BooleanFieldType,
        QuestionKey: 'workingYearOneFlag',
        Title: 'Working 20+ hours current academic year'

    },
    [FIELDS.WORKING__NEXT_YEAR]: {
        ...BooleanFieldType,
        QuestionKey: 'workingYearTwoFlag',
        Title: 'Working 20+ hours next academic year'
    },
    [FIELDS.FOSTER_CARE]: {
        ...BooleanFieldType,
        QuestionKey: 'FosterYouthFlag'
    },
    [FIELDS.EARLY_LEARNING_MAJOR]: {
        ...BooleanFieldType,
        QuestionKey: 'IsCareerEarlyLearning'
    },
    [FIELDS.COLLEGE_DUAL_ENROLLED]: {
        ...BooleanFieldType,
        QuestionKey: 'CollegeChoice.IsDualEnrolled'
    },
    [FIELDS.OSAC_REFERRAL]: {
        ...TextFieldType,
        QuestionKey: 'Wheredidyouhear'
    },
    [FIELDS.PUBLICITY_RELEASE]: {
        ...BooleanFieldType,
        QuestionKey: 'publicityReleaseFlag'
    },
    [FIELDS.PRIVACY_AGREEMENT]: {
        ...BooleanFieldType,
        QuestionKey: 'certificationFlag'
    },
    [FIELDS.MAILING_ADDRESS]: {
        ...AddressFieldType,
        QuestionKey: 'MailingAddress',
        Title: 'Mailing Address'
    },
    [FIELDS.CERTIFY_IDENTITY]: {
        ...BooleanFieldType,
        QuestionKey: 'IsIdentityCertified'
    },
    [FIELDS.CITIZENSHIP_STATUS]: {
        ...TextFieldType,
        QuestionKey: 'CitizenCode',
        Title: 'Citizenship',
        IsSelectable: true
    },
    [FIELDS.HISPANIC]: {
        ...TextFieldType,
        QuestionKey: 'HispEthnicCode',
        IsSelectable: true
    },
    [FIELDS.COLLEGE_STANDING]: {
        ...TextFieldType,
        QuestionKey: 'YRINCLGcode',
        IsSelectable: true
    },
    [FIELDS.COLLEGE_CHOICE]: {
        ...CollegeFieldType,
        QuestionKey: 'CollegeChoice',
        Title: 'College',
        IsSelectable: true
    },
    [FIELDS.PRIMARY_CONTACT]: {
        ...ContactFieldType,
        QuestionKey: 'AdditionalContact.Primary',
        Title: "Primary Residence"

    },
    [FIELDS.COLLEGE_HISTORY_COMPLETE]: {
        ...BooleanFieldType,
        QuestionKey: 'IsCollegeHistoryComplete'
    },
    [FIELDS.MARITAL_STATUS]: {
        ...TextFieldType,
        QuestionKey: 'StumsCode',
        Title: "Student Marital Status",
        IsSelectable: true
    },
    [FIELDS.PARENTS_MARITAL_STATUS]: {
        ...TextFieldType,
        QuestionKey: 'ParmsCode',
        Title: "Parents' Marital Status",
        IsSelectable: true
    },
    [FIELDS.ED_LEVEL_FATHER]: {
        ...TextFieldType,
        QuestionKey: 'EdLevelCodeFather',
        Title: "Parent 2 Education Level",
        IsSelectable: true

    },
    [FIELDS.ED_LEVEL_MOTHER]: {
        ...TextFieldType,
        QuestionKey: 'EdLevelCodeMother',
        Title: "Parent 1 Education Level",
        IsSelectable: true
    },
    [FIELDS.FIRST_GEN_IMMIGRANT]: {
        ...BooleanFieldType,
        QuestionKey: 'FirstGenImmigrant',
        Title: 'First Generation Immigrant'
    },
    [FIELDS.ERDCSubsidyExpirationDate]: {
        ...DateFieldType,
        QuestionKey: 'ERDCSubsidyExpirationDate',
        Title: 'ERDC Subsidy Expiration Date'
    },
    [FIELDS.FIRST_GEN_IMMIGRANT]: {
        ...BooleanFieldType,
        QuestionKey: 'FirstGenImmigrant',
        Title: 'First Generation Immigrant'
    },
    [FIELDS.CHILD_CARE_PROVIDER__NAME]: {
        ...TextFieldType,
        QuestionKey: 'ChildcareApp.Provider1Name'
    },
    [FIELDS.CHILD_CARE_PROVIDER__ADDRESS]: {
        ...TextFieldType,
        QuestionKey: 'ChildcareApp.Provider1Address'
    },
    [FIELDS.CHILD_CARE_PROVIDER__STATE]: {
        ...TextFieldType,
        QuestionKey: 'ChildcareApp.Provider1.state'
    },
    [FIELDS.CHILD_CARE_PROVIDER__CITY]: {
        ...TextFieldType,
        QuestionKey: 'ChildcareApp.Provider1.city'
    },
    [FIELDS.CHILD_CARE_PROVIDER__ZIP]: {
        ...TextFieldType,
        QuestionKey: 'ChildcareApp.Provider1.zip'
    },
    [FIELDS.CHILD_CARE_PROVIDER__PHONE]: {
        ...TextFieldType,
        QuestionKey: 'ChildcareApp.Provider1.phone'
    },
    [FIELDS.CHILD_CARE_PROVIDER__START_DATE]: {
        ...DateFieldType,
        QuestionKey: 'ChildcareApp.Provider1.startedOnDate'
    },
    [FIELDS.CHILD_CARE_PROVIDER__NEW]: {
        ...BooleanFieldType,
        QuestionKey: 'ChildcareApp.Provider1.newProviderFlag'
    },
    [FIELDS.CHILD_CARE_PROVIDER__LISTED]: {
        ...BooleanFieldType,
        QuestionKey: 'ChildcareApp.Provider1DHSlistedFlag'
    },
    [FIELDS.CHILD_CARE_PROVIDER__REGISTERED]: {
        ...BooleanFieldType,
        QuestionKey: 'ChildcareApp.Provider1DHSregisterCertFlag'
    },
    [FIELDS.CHILD_CARE_PROVIDER__PARENT]: {
        ...BooleanFieldType,
        QuestionKey: 'ChildcareApp.Provider1birthPsppartnerFlag'
    },
    [FIELDS.CHILD_CARE_PROVIDER2__NAME]: {
        ...TextFieldType,
        QuestionKey: 'ChildcareApp.Provider2.Name'
    },
    [FIELDS.CHILD_CARE_PROVIDER2__ADDRESS]: {
        ...TextFieldType,
        QuestionKey: 'ChildcareApp.Provider2.Address'
    },
    [FIELDS.CHILD_CARE_PROVIDER2__STATE]: {
        ...TextFieldType,
        QuestionKey: 'ChildcareApp.Provider2.state'
    },
    [FIELDS.CHILD_CARE_PROVIDER2__CITY]: {
        ...TextFieldType,
        QuestionKey: 'ChildcareApp.Provider2.city'
    },
    [FIELDS.CHILD_CARE_PROVIDER2__ZIP]: {
        ...TextFieldType,
        QuestionKey: 'ChildcareApp.Provider2.zip'
    },
    [FIELDS.CHILD_CARE_PROVIDER2__PHONE]: {
        ...TextFieldType,
        QuestionKey: 'ChildcareApp.Provider2.phone'
    },
    [FIELDS.CHILD_CARE_PROVIDER2__START_DATE]: {
        ...TextFieldType,
        QuestionKey: 'ChildcareApp.Provider2.startedOnDate'
    },
    [FIELDS.CHILD_CARE_PROVIDER2__NEW]: {
        ...BooleanFieldType,
        QuestionKey: 'ChildcareApp.Provider2.newProviderFlag'
    },
    [FIELDS.CHILD_CARE_PROVIDER2__LISTED]: {
        ...BooleanFieldType,
        QuestionKey: 'ChildcareApp.Provider2DHSlistedFlag'
    },
    [FIELDS.CHILD_CARE_PROVIDER2__REGISTERED]: {
        ...BooleanFieldType,
        QuestionKey: 'ChildcareApp.Provider2DHSregisterCertFlag'
    },
    [FIELDS.CHILD_CARE_PROVIDER2__PARENT]: {
        ...BooleanFieldType,
        QuestionKey: 'ChildcareApp.Provider2birthPsppartnerFlag'
    },
    [FIELDS.NATIONAL_GUARD__UNIT]: {
        ...TextFieldType,
        QuestionKey: 'AssignedUnit',
        IsSelectable: true
    },
    [FIELDS.NATIONAL_GUARD__CREDITS_PRESUMMER]: {
        ...IntegerFieldType,
        QuestionKey: 'AnticipatedCreditsPreSummer'
    },
    [FIELDS.NATIONAL_GUARD__CREDITS_FALL]: {
        ...IntegerFieldType,
        QuestionKey: 'AnticipatedCreditsFall'
    },
    [FIELDS.NATIONAL_GUARD__CREDITS_WINTER]: {
        ...IntegerFieldType,
        QuestionKey: 'AnticipatedCreditsWinter'
    },
    [FIELDS.NATIONAL_GUARD__CREDITS_SPRING]: {
        ...IntegerFieldType,
        QuestionKey: 'AnticipatedCreditsSpring'
    },
    [FIELDS.NATIONAL_GUARD__CREDITS_POSTSUMMER]: {
        ...IntegerFieldType,
        QuestionKey: 'AnticipatedCreditsPostSummer'
    },
    [FIELDS.UNDERGRAD_GPA]: {
        ...DecimalFieldType,
        QuestionKey: 'collegeGPA',
        Title: "Undergraduate GPA"
    },
    [FIELDS.GRADUATE_GPA]: {
        ...DecimalFieldType,
        QuestionKey: 'graduateGPA',
        Title: "Graduate GPA"
    },
    [FIELDS.FINISHED_COMM_COLLEGE]: {
        ...BooleanFieldType,
        QuestionKey: 'finishedCommClgFlag',
        Title: "Finiched Community College"
    },
    [FIELDS.FINISHED_BACHELORS_DEGREE]: {
        ...BooleanFieldType,
        QuestionKey: 'finished4yearClgFlag',
        Title: "Finished 4-Year Degree"
    },
    [FIELDS.PRONOUNS__FEMALE]: {
        ...BooleanFieldType,
        QuestionKey: 'FemininePronouns',
        Title: "She/Her"
    },
    [FIELDS.PRONOUNS__MALE]: {
        ...BooleanFieldType,
        QuestionKey: 'MasculinePronouns',
        Title: "He/Him"
    },
    [FIELDS.PRONOUNS__NEUTRAL]: {
        ...BooleanFieldType,
        QuestionKey: 'NeutralPronouns',
        Title: "They/Them"
    },
    [FIELDS.PRONOUNS__OTHER]: {
        ...TextFieldType,
        QuestionKey: 'OtherPronouns',
        Title: "Other Pronouns"
    },
    [FIELDS.CAREER_GOAL]: {
        ...TextFieldType,
        QuestionKey: 'careerField',
        Title: "Your Intended Career/Profession"

    },
    [FIELDS.OTHER_NAMES]: {
        ...NameFieldType,
        QuestionKey: 'AppStudentLogin.OtherNames',
        Title: "Your Additional or Previous Name(s)"
    },
    [FIELDS.AGE_CHILD]: {
        ...TextFieldType,
        QuestionKey: 'AgeChild'
    },
    [FIELDS.HIGHSCHOOL_TYPE]: {
        ...IntegerFieldType,
        QuestionKey: 'HsTypeCode'
    },
    [FIELDS.HIGHSCHOOL]: {
        ...HighSchoolFieldType,
        QuestionKey: 'HighSchool',
        Title: "High School",
        IsSelectable: true
    },
    [FIELDS.ACTIVITY_SCHOOL]: {
        ...ActivityFieldType,
        QuestionKey: 'Activities',
        Title: 'Activities'
    },
    [FIELDS.ACTIVITY_VOLUNTEER]: {
        ...ActivityFieldType,
        QuestionKey: 'Activities',
        Title: 'Activities'
    },
    [FIELDS.ACTIVITY_WORK]: {
        ...ActivityFieldType,
        QuestionKey: 'Activities',
        Title: 'Activities'
    },
    [FIELDS.HAS_NO_WORK_ACTIVITIES]: {
        ...BooleanFieldType,
        QuestionKey: 'HasNoWorkActivities'
    },
    [FIELDS.HAS_NO_SCHOOL_ACTIVITIES]: {
        ...BooleanFieldType,
        QuestionKey: 'HasNoSchoolActivities'
    },
    [FIELDS.HAS_NO_VOLUNTEER_ACTIVITIES]: {
        ...BooleanFieldType,
        QuestionKey: 'HasNoVolunteerActivities'
    },
    [FIELDS.ACTIVITIES_COMPLETE]: {
        ...BooleanFieldType,
        QuestionKey: 'ActivitiesChartFlag'
    },
    [FIELDS.PAST_COLLEGE]: {
        ...CollegeFieldType,
        QuestionKey: 'collegehistories',
        Title: 'College History'
    },
    [FIELDS.CURRENT_COLLEGE]: {
        ...CollegeFieldType,
        QuestionKey: 'collegehistories',
        Title: 'College History'
    },
    [FIELDS.DEGREES]: {
        ...DegreeFieldType,
        QuestionKey: 'Degrees',
        Title: 'Degrees'
    },
    [FIELDS.CHAFEE__PRESUMMER_1]: {
        ...BooleanFieldType,
        QuestionKey: 'ChafeeApp.TermSummer0PrevFlag'
    },
    [FIELDS.CHAFEE__FALL_1]: {
        ...BooleanFieldType,
        QuestionKey: 'ChafeeApp.TermFall1PrevFlag'
    },
    [FIELDS.CHAFEE__WINTER_1]: {
        ...BooleanFieldType,
        QuestionKey: 'ChafeeApp.TermWinter2PrevFlag'
    },
    [FIELDS.CHAFEE__SPRING_1]: {
        ...BooleanFieldType,
        QuestionKey: 'ChafeeApp.TermSpring3PrevFlag'
    },
    [FIELDS.CHAFEE__POSTSUMMER_1]: {
        ...BooleanFieldType,
        QuestionKey: 'ChafeeApp.TermSummer4PrevFlag'
    },
    [FIELDS.CHAFEE__PRESUMMER_2]: {
        ...BooleanFieldType,
        QuestionKey: 'ChafeeApp.TermSummer0Flag'
    },
    [FIELDS.CHAFEE__FALL_2]: {
        ...BooleanFieldType,
        QuestionKey: 'ChafeeApp.TermFall1Flag'
    },
    [FIELDS.CHAFEE__WINTER_2]: {
        ...BooleanFieldType,
        QuestionKey: 'ChafeeApp.TermWinter2Flag'
    },
    [FIELDS.CHAFEE__SPRING_2]: {
        ...BooleanFieldType,
        QuestionKey: 'ChafeeApp.TermSpring3Flag'
    },
    [FIELDS.CHAFEE__POSTSUMMER_2]: {
        ...BooleanFieldType,
        QuestionKey: 'ChafeeApp.TermSummer4Flag'
    },
    [FIELDS.HIGHSCHOOL_TYPE]: {
        ...TextFieldType,
        QuestionKey: 'HsTypeCode',
        IsSelectable: true
    },
    [FIELDS.CHAFEE__FOSTER_CARE]: {
        ...BooleanFieldType,
        QuestionKey: 'ChafeeApp.FosterCareFlag'
    },
    [FIELDS.CHAFEE__FOSTER_DHS]: {
        ...BooleanFieldType,
        QuestionKey: 'ChafeeApp.FCbyDHSflag'
    },
    [FIELDS.CHAFEE__FOSTER_OTHER_STATE]: {
        ...BooleanFieldType,
        QuestionKey: 'ChafeeApp.FCbyOtherStateFlag'
    },
    [FIELDS.CHAFEE__FOSTER_TRIBAL]: {
        ...BooleanFieldType,
        QuestionKey: 'ChafeeApp.FCbyTribalFlag'
    },
    [FIELDS.CHAFEE__ADOPTED]: {
        ...BooleanFieldType,
        QuestionKey: 'ChafeeApp.FFYadoptedFlag'
    },
    [FIELDS.CHAFEE__TRIBE]: {
        ...TextFieldType,
        QuestionKey: 'ChafeeApp.FCtribe'
    },
    [FIELDS.CHAFEE__OTHER_STATE_ID]: {
        ...IntegerFieldType,
        QuestionKey: 'ChafeeApp.FCbyOtherStateId'
    },
    [FIELDS.CHAFEE__FOSTER_QUALIFIED]: {
        ...BooleanFieldType,
        QuestionKey: 'ChafeeApp.FormerFosterCareQualifiedFlag'
    },
    [FIELDS.CHAFEE__ADOPTION_FINALIZED]: {
        ...TextFieldType,
        QuestionKey: 'ChafeeApp.FinalizedOnOrAfterGivenDate'
    },
    [FIELDS.COMM_COLLEGE_START_TERM]: {
        ...TextFieldType,
        QuestionKey: 'OregonPromiseApp.OrpCohort',
        IsSelectable: true
    },
    [FIELDS.CHILDREN]: {
        ...ChildFieldType,
        QuestionKey: 'ChildcareApp.Children'
    },
    [FIELDS.NATIONAL_GUARD__BRANCH]: {
        ...TextFieldType,
        QuestionKey: 'NationalGuardBranch',
        IsSelectable: true
    },
    [FIELDS.PROOF_GPA__UPLOAD]: {
        ...TranscriptFieldType,
        QuestionKey: 'ProofOfGpa'
    },
    [FIELDS.PROOF_GPA__MAIL]: {
        ...TranscriptFieldType,
        QuestionKey: 'ProofOfGpa'
    },
    [FIELDS.PROOF_GPA__REGISTRAR]: {
        ...TranscriptFieldType,
        QuestionKey: 'ProofOfGpa'
    },
    [FIELDS.FALL_HS_TRANSCRIPTS_DIGITAL]: {
        ...TranscriptFieldType,
        QuestionKey: 'FallHighSchoolTranscript'
    },
    [FIELDS.FALL_HS_TRANSCRIPTS_MAIL]: {
        ...TranscriptFieldType,
        QuestionKey: 'FallHighSchoolTranscript'
    },
    [FIELDS.FALL_HS_TRANSCRIPTS_REGISTRAR]: {
        ...TranscriptFieldType,
        QuestionKey: 'FallHighSchoolTranscript'
    },
    [FIELDS.FALL_COLLEGE_TRANSCRIPTS_DIGITAL]: {
        ...TranscriptFieldType,
        QuestionKey: 'FallCollegeTranscripts'
    },
    [FIELDS.FALL_COLLEGE_TRANSCRIPTS_MAIL]: {
        ...TranscriptFieldType,
        QuestionKey: 'FallCollegeTranscripts'
    }, 
    [FIELDS.FALL_COLLEGE_TRANSCRIPTS_DURING_HS_DIGITAL]: {
        ...TranscriptFieldType,
        QuestionKey: TRANSCRIPT_QUESTIONS.FALL_COLLEGE_DURING_HS.name
    },
    [FIELDS.FALL_COLLEGE_TRANSCRIPTS_DURING_HS_MAIL]: {
        ...TranscriptFieldType,
        QuestionKey: TRANSCRIPT_QUESTIONS.FALL_COLLEGE_DURING_HS.name
    },
    [FIELDS.FALL_COLLEGE_TRANSCRIPTS_PRIOR_DIGITAL]: {
        ...TranscriptFieldType,
        QuestionKey: TRANSCRIPT_QUESTIONS.FALL_COLLEGE_PRIOR.name
    },
    [FIELDS.FALL_COLLEGE_TRANSCRIPTS_PRIOR_MAIL]: {
        ...TranscriptFieldType,
        QuestionKey: TRANSCRIPT_QUESTIONS.FALL_COLLEGE_PRIOR.name
    },
    [FIELDS.FALL_COLLEGE_TRANSCRIPTS_RECENT_DIGITAL]: {
        ...TranscriptFieldType,
        QuestionKey: TRANSCRIPT_QUESTIONS.FALL_COLLEGE_RECENT.name
    },
    [FIELDS.FALL_COLLEGE_TRANSCRIPTS_RECENT_MAIL]: {
        ...TranscriptFieldType,
        QuestionKey: TRANSCRIPT_QUESTIONS.FALL_COLLEGE_RECENT.name
    },
    [FIELDS.GED_IN_PROGRESS_FLAG]: {
        ...BooleanFieldType,
        QuestionKey: 'GEDinProgressFlag'
    },
    [FIELDS.GED_SCORE]: {
        ...TextFieldType,
        QuestionKey: 'GEDscore',
        Title: 'GED'
    },
    [FIELDS.ESSAY_1]: {
        ...TextFieldType,
        QuestionKey: 'Essays/ps1',
        Title: "Personal Statement #1"
    },
    [FIELDS.ESSAY_2]: {
        ...TextFieldType,
        QuestionKey: 'Essays/ps2',
        Title: "Personal Statement #2"
    },
    [FIELDS.ESSAY_3]: {
        ...TextFieldType,
        QuestionKey: 'Essays/ps3',
        Title: "Personal Statement #3"
    },
    [FIELDS.ESSAY_4]: {
        ...TextFieldType,
        QuestionKey: 'Essays/ps4',
        Title: "Personal Statement #4",
        Question: "Optional: Is there any additional information you would like the selection committee members to know? This could include financial situations not reflected on the FAFSA or ORSAA or other information not covered in the application. This statement is not required and will not have any negative effect on the application if left blank."
    },
    [FIELDS.SPRING_COLLEGE_TRANSCRIPTS_DIGITAL]: {
        ...TranscriptFieldType,
        QuestionKey: 'SpringCollegeTranscripts'
    },
    [FIELDS.SPRING_COLLEGE_TRANSCRIPTS_MAIL]: {
        ...TranscriptFieldType,
        QuestionKey: 'SpringCollegeTranscripts'
    },
    [FIELDS.SPRING_HS_TRANSCRIPTS_DIGITAL]: {
        ...TranscriptFieldType,
        QuestionKey: 'SpringHighSchoolTranscript'
    },
    [FIELDS.SPRING_HS_TRANSCRIPTS_MAIL]: {
        ...TranscriptFieldType,
        QuestionKey: 'SpringHighSchoolTranscript'
    },
    [FIELDS.CHILD_CARE_EXPENSES_DIGITAL]: {
        ...DocumentFieldType,
        QuestionKey: 'ProofOfChildcareExpense'
    },
    [FIELDS.CHILD_CARE_EXPENSES_MAIL]: {
        ...DocumentFieldType,
        QuestionKey: 'ProofOfChildcareExpense'
    },
    [FIELDS.ETHNICITY__OTHER]: {
        ...TextFieldType,
        QuestionKey: 'EthnicOther'
    },
    [FIELDS.HAS_DEPENDENT_CHILDREN]: {
        ...BooleanFieldType,
        QuestionKey: 'HasDependentChildren'
    },
    [FIELDS.IS_NATIONAL_GUARD_MEMBER]: {
        ...BooleanFieldType,
        QuestionKey: 'IsNationalGuardMember'
    },
    [FIELDS.IS_TEACHER_CANDIDATE_MEMBER]: {
        ...BooleanFieldType,
        QuestionKey: 'IsTeacherCandidateMember'
    },
    [FIELDS.TEACHER_WORKING]: {
        ...BooleanFieldType,
        QuestionKey: "TeacherWorking"
    },
    [FIELDS.TEACHER_WORKING20HOURS]: {
        ...BooleanFieldType,
        QuestionKey: 'TeacherWorking20Hours',
    },
    [FIELDS.TEACHER_WORKINGKTHRU12]: {
        ...BooleanFieldType,
        QuestionKey: 'TeacherWorkingKthru12',
    },
    [FIELDS.TEACHER_ROLE]: {
        ...TextFieldType,
        QuestionKey: 'TeachingRole',
    },
    [FIELDS.TEACHER_DISTRICT]: {
        ...TextFieldType,
        QuestionKey: 'TeachingDistrict',
    },
    [FIELDS.UNLISTED_TEACHER_DISTRICT]: {
        ...TextFieldType,
        QuestionKey: 'UnlistedTeachingDistrict',
    },
    [FIELDS.TEACHER_FLUENCY]: {
        ...TextFieldType,
        QuestionKey: 'TeacherFluency',
    },
    [FIELDS.TEACHER_LISENCE_BEGIN]:{
        ...DateFieldType,
        QuestionKey:  'TeacherLicenseBegin',
    },
    [FIELDS.TEACHER_LICENSE_COMPLETE]: {
        ...DateFieldType,
        QuestionKey: 'TeacherLicenseProgramComplete',
    },
    [FIELDS.TEACHER_LICENSE_TYPE]:{
        ...TextFieldType,
        QuestionKey:  'TeacherLicenseType',
    },
    [FIELDS.TEACHER_LICENSE_ENDORSEMENTS]: {
        ...BooleanFieldType,
        QuestionKey: 'TeacherLicenseEndorsements',
    },
    [FIELDS.TEACHER_SCHOLARS_REFERRAL]: {
        ...TextFieldType,
        QuestionKey: 'TeacherScholarsAppReferral'
    }
}

class FieldDescriptor{

    constructor(fieldKey, data){
        this._fieldKey = fieldKey;
        this._data = data;
    }
    
    get fieldKey(){
        return this.fieldKey
    }

    get questionKey(){
        return this._data.QuestionKey;
    }

    get question(){
        return this._data.Question ?? this._data.Title ?? this._data.Name;
    }
}

/**
 * get slightly smarter description of each front-end question field
 * @param {string} fieldKey 
 * @returns 
 */
export function getField(fieldKey){
    const data = QuestionKeyMapping[fieldKey];
    if(!data)
        return null;

    return new FieldDescriptor(fieldKey, data);
}
/**
 * 
 * @param {string} questionKey 
 * @returns {*} first QuestionKeyMapping for entry
 */
export const getQuestionInfo = (questionKey) => {
    if (!questionKey)
        return null;

    questionKey = questionKey.toLowerCase();
    const map = Object.values(QuestionKeyMapping).find(q => q.QuestionKey.toLowerCase() === questionKey);
    return map;
}