import FormSerializer from "../../store/form/serializer";

/**
 * interprets a completion report from the student API
 */
class RequirementSet {

    /**
     * 
     * @param {Array} data dto list provided by student API for completion reqiurements
     */
    constructor(data) {
        this._data = data;
    }

    /**
     * is this info ready for use?
     * @returns {Boolean}
     */
     get isLoaded(){
        return this._data != null;
    }

    /**
     * get question requirements for specific question (and program if multiple programs were queried)     
     * @param {string} questionKey 
     * @returns {QuestionRequirement} object describing requirement and completion for question +
     */
    get(questionKey) {
        if (questionKey == null)
            return null;
        if (this._data == null)
            return null;

        questionKey = questionKey.toLowerCase();        
        const questionData = this._data.filter(x => x.QuestionKey.toLowerCase() === questionKey);               
        return new QuestionRequirement(questionData);
    }

    /**
     * get completion by field key
     * @param {string} fieldKey 
     * @returns 
     */
    getField(fieldKey){
        const questionKey = FormSerializer.getQuestionKey(fieldKey);
        return this.get(questionKey);
    }

    /**
     * number of questions in this set
     * @returns {Number}
     */
    get length(){
        if (this._data == null)
            return 0;

        return this._data.length;
    }

    /**
     * number of questions in this set that are actually complete
     * @returns {Boolean}
     */
    get completionScore(){
        if (this._data == null)
            return 0;
        return this._data.filter(x => x.IsComplete).length;
    }

    /**
     * is every question in this set satisfied within it's specified scope
     * @returns {Boolean}
     */
    get isComplete(){
        if (this._data == null)
            return false;  

        return this._data.every(x => x.IsComplete);
    }

    /**
     * convenience method. Just shorthand for get(questionkey).isRequired
     * @param {String} questionKey 
     * @returns {Boolean}
     */
    isRequired(questionKey) {
        return this.get(questionKey)?.isRequired === true;
    }

    /**
     * convenience method. Just shorthand for get(questionkey).isSkipped
     * @param {String} questionKey 
     * @returns {Boolean}
     */
    isSkipped(questionKey){
        return this.get(questionKey)?.isSkipped === true;
    }

    /**
     * gets a subset of this requirement set
     * @param {Array<String>} questionKeys fields we'd like to check status of
     * @returns {RequirementSet}
     */
    getSection(questionKeys){
        if (this._data == null)
            return new RequirementSet(null);

        questionKeys = questionKeys.map(x => x.toLowerCase());
        const subset = this._data.filter(x => questionKeys.includes(x.QuestionKey.toLowerCase()));  
        return new RequirementSet(subset);
    }

    /**
     * like getSection, but uses fieldKeys instead of questionKeys
     * @param {Array<String>} questionKeys fields we'd like to check status of
     * @returns {RequirementSet}
     */
    getSectionByFields(fieldKeys){
        const questionKeys = fieldKeys.map(x => FormSerializer.getQuestionKey(x));
        return this.getSection(questionKeys);
    }

    /**
     * view just those question requirements that have not been satisfied
     * @returns {Array<QuestionRequirement>}
     */
    get incompleteQuestions(){
        const incompleteDtos = this._data.filter(x => !x.IsComplete);
        //TODO: groupby for multiple-program scope. 
        //WARNING: only works for single-program scope right now
        return incompleteDtos.map(x => new QuestionRequirement([x]));
    }
   
}

/**
 * summary of requirements for one specific question, within scope defined in original RequirementSet
 */
class QuestionRequirement {

    /**
     * just the completion requirements that pertain to this specific question
     * @param {Array} data 
     */
    constructor(data){
        this._data = data;
    }

    /**
     * have all the question requirements in this scope been satisfied
     * @returns {Boolean}
     */
    get isComplete(){
        return this._data.every(x => x.IsComplete);
    }

    /**
     * does at least one program claim this qestions's answer(s) is inconsistent with some other answer
     */
    get isInconsistent(){
        return this._data.some(x => x.InCompleteReason === "InconsistentAnswer");
    }
    
    /**
     * does at least one program claim this qestions's answer(s) indicates they are ineligible for the program
     */
    get isDisqualifying(){
        return this._data.some(x => x.InCompleteReason === "DisqualifyingAnswer");
    }

    /**
     * 
     */
    get isDuplicated(){
        return this._data.some(x => x.InCompleteReason === "DuplicateInformation");
    }

    /**
     * is there some interesting reason we are not considering this complete. Like beyond information just being missing,
     * is there some special inconsistencies the student needs to remedy
     * @returns {boolean}
     */
    get isInvalidReason(){
        return this.isInconsistent || this.isDisqualifying || this.isDuplicated;    
    }

    /**
     * within the scope specified, is this an "eitheror" question. The alternate question needs to be infered by context
     * @returns {Boolean}
     */
    get isEitherOr(){       
        return !this.isRequired && this._data.some(x => x.RequirementType == "EitherOr"); 
    }

    /**
     * within the scope specified, is this question required
     * @returns {Boolean}
     */
    get isRequired() {       
        return this._data.some(x => x.RequirementType == "Required"); //if any program requires it, they all
    }

    /**
     * within scope specified, is this question optional
     * @returns {Boolean}
     */
    get isOptional() { 
        if  (this._data.length == 0)
            return false;
        return this._data.every(x => x.RequirementType == "Optional" || x.RequirementType == "Skipped");
    }

    /**
     * within scope specified, is this question even being prompted? Or was it considered not relvant to ask given student's situation
     * @returns {Boolean}
     */
    get isSkipped() {
        if  (this._data.length == 0)
            return false;
        return this._data.every(x => x.RequirementType == "Skipped");
    }


}

export default RequirementSet