import Checkbox from './../../components/Checkbox';
import { useForm, FormProvider } from "react-hook-form";
import React, { useState, useEffect, Fragment } from 'react';
import CollegePicker from '../../components/CollegePicker-modified';
import { FieldSection, FieldRow, FieldSlot } from '../../components/Forms/FieldSection';
import { FIELDS } from '../../store/form/fields';
import FormSection from '../../components/FormSection';
import { useDispatch } from 'react-redux';
import BooleanAnswer from '../../components/answer/BooleanAnswer';
import DecimalAnswer from '../../components/answer/DecimalAnswer';
import Button from '../../components/Button';
import HiddenInput from '../../components/Utility/HiddenInput';
import apiClient from '../../utils/API';
import RequirementSet from '../../utils/dtos/RequirementSet';
import { Alert } from '@material-ui/core';

/**
 * college history section of Scholarships Application
 * @param {*} param0 
 * @returns {Component}
 */
function EditCollegeHistoryInfo({ onChange, onCancel, section }) {

    const year = section.year;
    const commCollegeYear = year;
    const bachelorYear = year;

    const methods = useForm({
        mode: 'onBlur'
    });
    const { handleSubmit, watch, getValues, setValue, register } = methods;

    const [loading, setLoading] = useState(false);
    const [completionData, setCompletionData] = useState(null);
    const dispatch = useDispatch();
    const watchNoLetterGrades = watch("NoLetterGrades")
    const watchFinishedCc = watch(FIELDS.FINISHED_COMM_COLLEGE)
    const watchFinishedBd = watch(FIELDS.FINISHED_BACHELORS_DEGREE)
    const watchUndergradGpa = watch(FIELDS.UNDERGRAD_GPA)
    const watchGraduateGpa = watch(FIELDS.GRADUATE_GPA)  
    const sectionCompletion = new RequirementSet(completionData);
    const collegeCompletion = sectionCompletion.getField(FIELDS.PAST_COLLEGE);

    /**
     * fetch most recent completion report, also saves into state
     * @returns {RequirementSet}
     */
    async function getCompletionReport() {
        const completion = await apiClient.getCompletionReport("SCH", section.year, [FIELDS.PAST_COLLEGE]);
        setCompletionData(completion);    
        return new RequirementSet(completion);
    }

    /**
     * actual core work of saving answers
     * @param {*} data 
     */
    async function submitAnswers(data) {
       
        if (watchNoLetterGrades === true) {
            data[FIELDS.UNDERGRAD_GPA] = "0.01"
        }

        await section.saveAnswers(data, dispatch);    
        var completionResult = await getCompletionReport();      
        return completionResult;
    }

    const onSubmit = async (data) => {
        
        const completionResult = await submitAnswers(data);   
        const collegeCompletion = completionResult.getField(FIELDS.PAST_COLLEGE);     
              
        //supress onchange response if student needs to see some special completion feedback
        if (typeof onChange === 'function' /*&& !collegeCompletion.isInvalidReason*/) {
            onChange(true);
        }     
    };

    const onError = (e) => {        
        const data = getValues();
        submitAnswers(data); //save what we have, but alert valiation errors anyhow
    }

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }
    }, [])

    const alertUser = e => {
        e.preventDefault()
        e.returnValue = ''
    }

    const [currentCollege, setCurrentCollege] = useState()
    const [pastCollege, setPastCollege] = useState()

    function collegePickerCallback(current, past) {
        setPastCollege(past?.length > 0)
        setCurrentCollege(current?.length > 0)
    }

    //=====( NO COLLEGE HISTORY LOGIC )
    const [noCollegeHistory, setNoCollegeHistory] = useState(null);
    const [hideNoCollegeHistory, setHideNoCollegeHistory] = useState(true);

    useEffect(() => {
        let noHistoryAnswer = section.getAnswerByField(FIELDS.COLLEGE_HISTORY_COMPLETE).Response;
        setNoCollegeHistory(noHistoryAnswer ? true : null)

        getCompletionReport();

    }, [])

    function handleToggle() {
        setNoCollegeHistory(noCollegeHistory ? null : true)
    }

    useEffect(() => {
        if (noCollegeHistory !== true
            && (currentCollege
                || pastCollege
                || watchFinishedCc === 'True'
                || watchFinishedBd === 'True'
                || watchUndergradGpa
                || watchGraduateGpa)) {
            setNoCollegeHistory(false);
            setHideNoCollegeHistory(true);
        }
        else {
            setHideNoCollegeHistory(false);
        }
    }, [currentCollege, pastCollege, watchFinishedCc, watchFinishedBd, watchUndergradGpa, watchGraduateGpa])

    //=====( RENDER RETURN )

    return (
        <FormProvider {...methods} >
            <form onSubmit={handleSubmit(onSubmit, onError)} >
                <FormSection title='College History' isEditable={true} onSaveClick={handleSubmit(onSubmit, onError)} onCancelClick={onCancel} loading={loading}>
                    <HiddenInput
                        name={FIELDS.COLLEGE_HISTORY_COMPLETE}
                        value={noCollegeHistory}
                    />
                    {!hideNoCollegeHistory ?
                        <FieldSection>
                            <FieldSlot>
                                <Checkbox
                                    useController={false}
                                    onChange={handleToggle}
                                    value={noCollegeHistory}
                                    label={'I have no college history.'}
                                />
                            </FieldSlot>
                        </FieldSection>
                        : null}

                    {!noCollegeHistory ?
                        <Fragment>
                            <FieldSection>
                                <FieldRow>                                   
                                    <Alert severity='info'>
                                        List each college you have attended. You may only list each college once. You cannot enter the same college in both the "Currently 
                                        Attending College(s)" and "Previously Attended College(s)" sections. If you had a gap in attendance or pursued multiple degrees, 
                                        enter your very first date of attendance <i>Date Started</i> through the most recent date you attended <i>Date Ended</i>.
                                    </Alert>
                                </FieldRow>
                            </FieldSection>

                            {collegeCompletion?.isDuplicated === true &&
                                <div item>                                 
                                    <Alert severity="error" className="alert alert--error">
                                        <p>
                                            Do not report any college more than once. Even if you took time off from a college, report the earliest term of attendance
                                            and the most recent term of attendance if you are not still currently attending.
                                        </p>
                                    </Alert>
                                </div>
                            }
                            <div className="rhythm">
                                <CollegePicker collegePickerCallback={collegePickerCallback} section={section} />
                            </div>

                            <div className='divider' />

                            <FieldSection isRequired title={"Will you have finished two academic years towards a degree or certificate at community college by July 1, " + commCollegeYear + "?"}>
                                <FieldRow>
                                    <FieldSlot>
                                        <BooleanAnswer answer={section.getAnswerByField(FIELDS.FINISHED_COMM_COLLEGE)} value={watchFinishedCc} isRequired={true} />
                                    </FieldSlot>
                                </FieldRow>
                            </FieldSection>

                            <FieldSection isRequired title={"Will you have earned your Bachelor’s degree by fall " + bachelorYear + "?"}>
                                <FieldRow>
                                    <FieldSlot>
                                        <BooleanAnswer answer={section.getAnswerByField(FIELDS.FINISHED_BACHELORS_DEGREE)} value={watchFinishedBd} isRequired={true} />
                                    </FieldSlot>
                                </FieldRow>
                            </FieldSection>

                            <FieldSection title="Cumulative Undergraduate GPA" withDivider={true} isRequired={currentCollege || pastCollege}>
                                <FieldRow>
                                    <FieldSlot>
                                        <DecimalAnswer disabled={watchNoLetterGrades}
                                            answer={section.getAnswerByField(FIELDS.UNDERGRAD_GPA)}
                                            isRequired={currentCollege || pastCollege}
                                            inputLabel='Undergraduate GPA' />
                                    </FieldSlot>
                                    <FieldSlot container alignItems="flex-end">
                                        <div>
                                            <Checkbox name='NoLetterGrades' label="My college did not assign grades." />
                                        </div>
                                    </FieldSlot>
                                </FieldRow>
                            </FieldSection>
                            <FieldSection title="Cumulative Graduate GPA" withDivider={true}>
                                <FieldRow>
                                    <FieldSlot>
                                        <DecimalAnswer disabled={watchNoLetterGrades} answer={section.getAnswerByField(FIELDS.GRADUATE_GPA)} inputLabel='Graduate GPA' />
                                    </FieldSlot>
                                </FieldRow>
                            </FieldSection>
                        </Fragment>
                        : null}

                    {noCollegeHistory ?
                        <Fragment>
                            <FieldSection>
                                You have indicated you have never attended college.
                                <FieldSlot>
                                    <Button label='Provide College history' onClick={handleToggle}
                                    />
                                </FieldSlot>
                            </FieldSection>
                        </Fragment>
                        : null}

                </FormSection>
            </form>
        </FormProvider>
    );
}

export default EditCollegeHistoryInfo;
