import axios from "axios";
import path from "path";
import { getCurrentYear, getLatestActiveYear } from ".";
import auth from "../auth";
import striptags from "striptags";
import moment from "moment";
import maxBy from 'lodash/maxBy'
import {FIELDS} from "../store/form/fields";
import FormSerializer from "../store/form/serializer";

const cookie = ``;

if (cookie) {
  document.cookie = cookie;
}

const PAGINATION_LIMIT_DEFAULT = 10;

class APIClient {
  constructor(host, preffix) {
    this.host = new URL(host);
    this.preffix = preffix;
  }

  static get Methods() {
    return {
      GET: "GET",
      POST: "POST",
      PUT: "PUT",
      DELETE: "DELETE",
    };
  }

  static clientFactory() {
    return new APIClient(
      process.env.REACT_APP_API_HOST,
      process.env.REACT_APP_API_PREFFIX
    );
  }

  request = async (endpoint, params, method, isBlob = false) => {
    if (this.preffix) {
      endpoint = path.join(this.preffix, endpoint);
    }

    const url = new URL(endpoint, this.host);
    const { searchParams } = url;

    let options;

    method = method || APIClient.Methods.GET;

    options = {
      method,
      headers: {
        Authorization: `Bearer ${auth.getIdToken()}`,
      },
      responseType: isBlob ? "blob" : "json",
    };

    // // console.log(auth.getIdToken());

    if (method === APIClient.Methods.GET && params) {
      Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((ithValue) => searchParams.append(key, ithValue));
        } else {
          searchParams.append(key, value);
        }
      });
    } else if (
      method === APIClient.Methods.POST ||
      method === APIClient.Methods.PUT ||
      method === APIClient.Methods.DELETE
    ) {
      options.data = params;
    }

    options.url = url.toString();

    try {
      const response = await axios(options);
      return response.data;
    } catch (err) {
      if (err?.response?.status === 401) {
        await auth.logout();
      }
      throw err;
    }
  };

  get = async (endpoint, params, isBlob) => {
    return this.request(endpoint, params, APIClient.Methods.GET, isBlob);
  };

  delete = async (endpoint, params) => {
    return this.request(endpoint, params, APIClient.Methods.DELETE);
  };

  put = async (endpoint, params) => {
    return this.request(endpoint, params, APIClient.Methods.PUT);
  };

  post = async (endpoint, params) => {
    return this.request(endpoint, params, APIClient.Methods.POST);
  };

  acceptApplicationAward = async (programCode, year ) => {
    return apiClient.put(`/award/accept`, {
      ProgramCode: programCode,
      Year: year
    });
  };

  declineApplicationAward = async (
    programCode,
    year,
    reason
  ) => {
    return apiClient.delete(`/award/decline`, {
      ProgramCode: programCode,
      Year: year,
      Reason: reason
    });
  };

  removeApplication = async (
    programCode,
    year
  ) => {
    return apiClient.delete(`/application`, {
      ProgramCode: programCode,
      Year: year
    });
  };

  /*
   TODO: !Figure out a work around for null instances to replace getCurrentYear()!
          Did not touch because it can cause issues. Maybe use getLatestActiveYear(PROGRAM_CODE)
          and then pass program code in api call... Or save active years to redux for easy access
          and prevent unnecessary API calls.
  */
  submitApplication = async (
    programCode,
    year = getCurrentYear(), // <----- TODO: See previous comment
  ) => {
    return apiClient.put(`/application/submit`, {
      ProgramCode: programCode,
      Year: year
    });
  };

  startApplication = async (
    programCode,
    year
  ) => {
    return apiClient.post(`/application`, {
      ProgramCode: programCode,
      Year: year
    });
  };

  getAccount = () => {
    return apiClient.get("/account");
  };


  /**
   * get status of a specific application
   * @deprecated see getApplication to get past array results
   * @param {String} programCode 
   * @param {Number} year 
   * @returns {*}
   */
  getApplicationStatus = async (
    programCode,
    year
  ) => {
    return apiClient.get(
      `/application?lookup.programCode=${programCode}&lookup.year=${year}`
    );
  };
  /**
   * get status of specific application
   * @param {String} programCode 
   * @param {Number} year 
   * @returns {Object} dto describing recent application status
   */
  getApplication = async (programCode, year) => {
    const result = await apiClient.get(
      `/application?lookup.programCode=${programCode}&lookup.year=${year}`
    );
    if (result.length == 0)
      return null;
    return result[0];
  };

  getApplicationAwardStatus = async (
    programCode,
    year
  ) => {
    return apiClient.get(
      `/award?lookup.programCode=${programCode}&lookup.year=${year}`
    );
  };

  getFileFromQuestionKey = async ({
    questionKey,
    id,
    year,
  }) => {
    const opts = {
      "lookup.questionKey": questionKey,
      "lookup.id": id,
    }

    if (year) {
      opts['lookup.year'] = year
    }

    return apiClient.get(
      `/file`,
      opts,
      true
    );
  };


  attachFileToQuestionKey = async ({
    file,
    questionKey,
    id,
    year
  }) => {
    let endpoint = `/file`;
    if (this.preffix) {
      endpoint = path.join(this.preffix, endpoint);
    }

    const url = new URL(endpoint, this.host);
    const { searchParams } = url;

    const formData = new FormData();
    formData.append("file", file);
    searchParams.append("lookup.questionKey", questionKey);
    searchParams.append("lookup.id", id);

    if (year) {
      searchParams.append("lookup.year", year)
    }

    return axios({
      method: "post",
      url: url.toString(),
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${auth.getIdToken()}`,
      },
    }).then(({ data }) => data);
  };

  getAccount = () => {
      return apiClient.get("/account");
  }

  verifyAccount = (params) => {
      return apiClient.post("/account/verification", params)
  }


  /**
   * fetch the program description
   * @param {String} code
   * @returns {*} raw object describing program. See ProgramInfo
   */
  getProgram = (code) => {
    return apiClient.get(`/program/${code}`);
  }

  /**
   * get available options for the given question
   * 
   * @param {string} questionKey 
   * @param {number} year 
   * @returns 
   */
  getOptions = (questionKey, year) =>{
    return apiClient.get('/answer/options', {
        questionKey: questionKey,
        year: year
    });
  }
  
  /**
   * get avalable options for given question as dropdown-compatible list values
   * 
   * @param {string} questionKey 
   * @param {number} year 
   * @returns 
   */
  getOptionsAsListItems = async (questionKey, year) => {
    var options = await this.getOptions(questionKey, year);
    return options.map((option) => ({
      label: option.Name,
      value: option.Value,
    }));
  }

  /**
   * fetch info on the donor providing for this program
   * @param {*} code 
   * @returns {*} raw object desribing the program donor
   */
  getDonor = (code) => {
    return apiClient.get(`donor/program/${code}`);
  }
  /**
   * fetch the description of a specific program season, calendar and all
   * @param {String} code
   * @param {Numeric} year
   * @returns {*} raw object describing season. See ProgramSeason
   */
  getSeason = (code, year) => {
    return apiClient.get(`/program/season/${code}/${year}`)
  }

  getHighSchool = (code) => {
      return apiClient.get(`/high-school/${code}`);
  }

  getCollege = (code) => {
      return apiClient.get(`/college/${code}`);
  }

  getCity = (id) => {
    return apiClient.get(`/city/${id}`);
  }

  /**
   * get descriptive text for code. 
   * 
   * Remember some code descriptions contain HTML
   * @param {string} codeType 
   * @param {string} code 
   * @returns 
   */
  getTextCode = (codeType, code) => {
    return apiClient.get(`/code?code=${code}&codeType=${codeType}`);
  }


  getProfileStatus = async () => {

    // Basic Info
    const fieldKeys = [
      FIELDS.FIRST_NAME,
      FIELDS.LAST_NAME,
      FIELDS.BIRTHDATE,
      FIELDS.GENDER__MALE,
      FIELDS.GENDER__FEMALE,
      FIELDS.GENDER__NON_BINARY,
      FIELDS.GENDER__OTHER,
      FIELDS.GENDER__TRANSMAN,
      FIELDS.GENDER__TRANSWOMAN,
      FIELDS.GENDER__NA,
      FIELDS.PRONOUNS__FEMALE,
      FIELDS.PRONOUNS__MALE,
      FIELDS.PRONOUNS__NEUTRAL,
      FIELDS.PRONOUNS__OTHER,
      FIELDS.CERTIFY_IDENTITY,

      // Contact Info
      FIELDS.HOME_PHONE,
      FIELDS.PERMANENT_ADDRESS,

      // About Me Info
      FIELDS.SSN,
      FIELDS.HISPANIC,
      FIELDS.CORRECTIONAL_FACILITY_HS,
      FIELDS.IS_NATIONAL_GUARD_MEMBER,
      FIELDS.HAS_DEPENDENT_CHILDREN,
      FIELDS.OTSG__ENROLLED_TRIBE,
      FIELDS.ETHNICITY__AM_INDIAN,
      FIELDS.ETHNICITY__ASIAN,
      FIELDS.ETHNICITY__BLACK,
      FIELDS.ETHNICITY__HAWAIIAN_PI,
      FIELDS.ETHNICITY__WHITE,
      FIELDS.ETHNICITY__NA,
      FIELDS.TEST_APPLICATION,
      FIELDS.FOSTER_CARE,      

      // Highschool Info
      FIELDS.HIGHSCHOOL_TYPE,
      FIELDS.HIGHSCHOOL,
      FIELDS.HIGHSCHOOL_GRAD_DATE,
      FIELDS.CORRECTIONAL_FACILITY_CC,
      FIELDS.HIGHSCHOOL_GPA,
      FIELDS.GED_SCORE,

      // Account Info
      FIELDS.PRIVACY_AGREEMENT,
      FIELDS.PUBLICITY_RELEASE,
    ];

      const data = {
        "lookup.questionKeys": fieldKeys.map((fieldKey) =>
          FormSerializer.getQuestionKey(fieldKey)
        ),
      }
      
      const result = await apiClient.get("/answer/iscomplete", data);
      return result;
  }

  /**
   * are the following fields complete to the satisfaction of the top-tier programs or not.
   * @param {Array<String>} fieldKeys 
   * @returns {Boolean}
   */
  checkForComplete = async (fieldKeys) => {
    const data = {
      "lookup.questionKeys": fieldKeys.map((fieldKey) =>
        FormSerializer.getQuestionKey(fieldKey)
      ),
    }
    return await apiClient.get("/answer/iscomplete", data);
  }
  /**
   * get details of completion status for the given fields
   * @param {String} programCode 
   * @param {Numeric} year
   * @param {Array<String>} fieldKeys 
   * @returns {Array} completion report for the given program/fields
   */
  getCompletionReport = async (programCode, year, fieldKeys) => {
    const scope = {
      programCodes: [programCode],
      year: year,
      questionKeys: fieldKeys.map((fieldKey) => FormSerializer.getQuestionKey(fieldKey)
      ),
    }
    return await apiClient.get("/answer/completion", scope);
  }

  getGedCountyList = async () => {
    return await apiClient.get('/high-school/high-schools/GED')
  }

  checkForAppliedScholarships = async  () => {
    let results = await this.get('/application/search?isActive=true&parentCode=SCH');
    console.log('checkforapplied results', results)
    let started = []
    for(let item in results) {
      console.log('item', results[item].Source)
      if(results[item].Source == 'Apply' || results[item].Source == 'Scoop' || results[item].Source == 'Auto') {
        started.push(results[item])
      }
    }
    console.log('checkhasscholarships started', started)
    return started
  }

  /**
   * @deprecated comes with a pretty high performance hit
   * @param {*} filteringOptions 
   * @param {*} tab 
   * @param {*} year 
   * @returns 
   */
  getScholarships = async (filteringOptions = {}, tab, year) => {

    filteringOptions["parentProgram"] = "SCH";
    filteringOptions["isActive"] = true;

    if (!filteringOptions["phrases"]) {
      delete filteringOptions["phrases"];
    }

    let url = '/program/search'
    switch (tab) {
      case 0:
      case 1:
        url = `/application/search`
        filteringOptions["parentCode"] = "SCH"
        delete filteringOptions["parentProgram"]
        break;
      case 2:
        url = '/recommendations/programs'
        break;
      case 3:
        filteringOptions['isMembershipVerified'] = true;
        break
      case 4:
        break;
      default:
        break;
    }

    if (year)
      filteringOptions['lookup.year'] = year;
    else
     { // If year is not passed, get the Active Year for the Scholarship program.       
      const yearRetrieved = await getLatestActiveYear('SCH');
      filteringOptions['lookup.year'] = yearRetrieved;
    }

    // pages are 0-indexed in API
    if (typeof filteringOptions["currentPage"] === "number") {
      filteringOptions["currentPage"] -= 1;
    }
    const response = await this.get(url, filteringOptions);
    let programs = response.PageOfPrograms

    console.log('scholarship raw search results', response)

    switch (tab) {
      case 0:
      case 1:
      case 2:
        programs = response
        break;
      case 3:
        break;
      case 4:
        break;
      default:
        break;
    }
    if (tab === 0 || tab === 1) {

      const programRequests = [];

      // #3881/SP-324: Scholarship Renewal Process: Renewal application with pending status (should not appear to student)
      let actualPrograms =programs; // API end point is returning all records, including PEND. 
      console.log("actual programs: ", actualPrograms.length);
    
      // Filter the records with "PEND" ReviewStatus. 
      let filteredPrograms = actualPrograms.filter(actualProgram => actualProgram?.ReviewStatus !== 'PEND');      
      console.log("filtered programs: ", filteredPrograms.length);

      programs = filteredPrograms;

      for(let i = 0; i < programs.length; i++) {

        const program = programs[i];

        programRequests.push(this.get(`/program/${program.ProgramCode}`))
      }
      const results = await Promise.all(programRequests)
      programs = results.map((program,index) => {
        return {
          ...program,
          ...results[index]
        }
      })
    }

    const scholarships = await Promise.all( programs.map(async(scholarship, index) => {
      const activeSeasons = scholarship?.ActiveSeasons;
      const recentSeason = maxBy(activeSeasons, "Year");
      const resolvedYear = recentSeason?.Year || year;

      let activeSeason = (scholarship?.ActiveSeasons || []).find(
        ({ Year }) => Year === resolvedYear
      );

      // #3701 Bug fix: Set the flag to 'Matched to Your Profile'.  
      const schProgram = await apiClient.get("application", {programCode: scholarship.Code, year: resolvedYear});
      const isMatched = schProgram[0].IsRecommended; 
      
      /* 
       #3701 Bug fix: Set the flag, to display 'Additional information needed' message & 'Documents Required for This Scholarship' icon. 
       isDocsReq === true : scholarship  program needs additional information/docs and student submitted all information.
       isDocsReq === false : scholarship  program needs additional information/docs and student did NOT submitted all information.
       isDocsReq !== null :  scholarship  program does not need any additional information from student.
       */
      const isComplete = await apiClient.get("application/iscomplete", {programCode: scholarship.Code, stage: 'Application', year: resolvedYear});
      

      let deadline = "";
      let listRequirements = [];

      if (activeSeason) {
        listRequirements = activeSeason.Notes.map(({ Description }) => ({
          value: striptags(Description),
        }));
        deadline = activeSeason?.Calendar?.find(({ Code }) => Code === "END") || scholarship.DeadlineT;
        if (deadline) {
          //TODO (alejandro): Change date parsing format & timezone as PST
          deadline = moment(deadline.Date).format("MMMM Qo YYYY");
        }
      }
      return {
        // @TODO: for some perplexing reason, this is all hardcoded
        Name: scholarship.Name,
        Code: scholarship.Code,
        membershipRequired: scholarship.IsMembershipVerified,
        // hard coded fields
        Description: scholarship.Description,
        progress: 0, // ??? WHY?
        deadline,
        //dates: { start: "2021", end: "2022" },
        terms: "Fall, Winter, Spring",
        dateReceived: "5/14/21",
        appReceived: true,
        appSubmitted: true,
        tentativeApproval: true,
        awardAmount: 284,
        listRequirements,
        selected: true,
        matched: isMatched,
        docsRequired: isComplete,
        isComplete: isComplete,
        year: recentSeason?.Year || year,
      };
    }));
    // console.log("Innocent Scholarships", scholarships)
    // console.log("get scholarships response TotalCount", response?.TotalCount)
    const totalPages = Math.ceil(
      Number(response.TotalCount) / PAGINATION_LIMIT_DEFAULT
    );
    // console.log("get scholarships response totalPages", totalPages)
    return { scholarships, totalPages };
  };
}

const apiClient = APIClient.clientFactory();

export default apiClient;
