import { useEffect, useState } from "react";
import { FIELDS, TRANSCRIPT_QUESTIONS } from "../../store/form/fields";
import moment from "moment";
import apiClient from "../../utils/API";

/**
 * instrutions for all transcripts we are requesting from student
 * 
 * @param {*} param0 
 * @returns 
 */
function TranscriptInstructions({section, requirements}){

    
    /**
     * determine best instruction type key for given answer 
     * (will be consolidated later)
     * @param {*} answer DTO
     * @returns {Array<string>}
     */
    function getInstructionType(answer){
        const question = answer.QuestionKey;
        const requirement = requirements.get(answer.QuestionKey);
        if (requirement.isSkipped)
            return null;

        if (question.toUpperCase() === TRANSCRIPT_QUESTIONS.FALL_COLLEGE_RECENT.name.toUpperCase()){
            const college = section.getAnswerById(answer.SchoolAnswerId);
            if (!college?.StopYear)
                return 'StartingCollege';

            return 'RecentCollege';
        }
          
        if (question.toUpperCase() === TRANSCRIPT_QUESTIONS.FALL_COLLEGE_PRIOR.name.toUpperCase()) {
            return 'PriorCollege';
        }
            
        if (question.toUpperCase() === TRANSCRIPT_QUESTIONS.FALL_HS.name.toUpperCase()){
            const highschool = section.getAnswerByField(FIELDS.HIGHSCHOOL);
            const hsType = highschool?.Type;
            if (hsType === 'Ged' || hsType == 'GedHomeSchool'){
                return 'GED';
            }       
            if (hsType === 'HomeSchool') {
                const manualState = highschool.StateId;
                if (manualState !== 38)
                    return 'OutofStateHomeSchool';

                return 'OregonHomeSchool';
            }
                                        
            const gradDateResponse = section.getAnswerByField(FIELDS.HIGHSCHOOL_GRAD_DATE)?.Response;            
            if (gradDateResponse && moment(gradDateResponse).isAfter(`${section.year-1}-09-01`)) {                                                                                   
                    return 'CurrentHighSchool';
            }
            if (requirement.isOptional)
                return null; //NOTE: staff insist no instructions are needed here. Still waiting to see if the other shoe drops
           
            return 'PriorHighSchool';
               
        }
        if (question.toUpperCase() === TRANSCRIPT_QUESTIONS.FALL_COLLEGE_DURING_HS.name.toUpperCase()){
            return null;
        }
        return null;
    }

    /**
     * 
     * @returns {Array<string>} unique list of instruction types
     */
    function getAllInstructionTypes(){
        if (section.isAnswerLoading)
            return [];

        const transcripts = section.getAllAnswers().answers;     
        if (transcripts.length === 0){
            return ['NoTranscripts'];          
        }
        const types = transcripts.map(answer => getInstructionType(answer)).filter(t => t !== null);
        const uniqueTypes = [...new Set(types)];
        return uniqueTypes;
    }

    const transcriptInstructionTypes = getAllInstructionTypes();    
    const [transcriptInstructions, setTranscriptInstructions] = useState([]);
    
  
    useEffect(() => {
        
        (async() =>{
            try {         
                const instructions = await Promise.all(transcriptInstructionTypes.map(t => apiClient.getTextCode('SchTransInstruction', t)));
        
                setTranscriptInstructions(instructions);
            }
            catch(e){
                console.log(e);
            }
        })();   

    }, [JSON.stringify(transcriptInstructionTypes)]); //look for change in contents of array

  
      

    return (
        <div>
            <h2 className="heading heading--h2">Transcript Instructions</h2>
            <ul className="list">
                {transcriptInstructions.map((instruction, index) => (
                    <li key={index}>
                        <div dangerouslySetInnerHTML={{__html: instruction.Message}}></div>                      
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default TranscriptInstructions;