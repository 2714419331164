import auth from "../../auth";
import { getCurrentYear } from "../../utils";
import apiClient from "../../utils/API";
import {
  AnswerTypeAddressProps,
  AddressFieldType,
  AnswerTypeNameProps,
  DateFieldType,
  FIELDS,
  AnswerTypeContactProps,
  ContactFieldType,
  CollegeFieldType,
  AnswerTypeCollegeProps,
  QuestionKeyMapping,
  BooleanFieldType,
  REPEATABLE_FIELDS,
  AnswerTypeTranscriptProps,
  TranscriptFieldType,
  AnswerTypeDocumentProps,
  DocumentFieldType
} from "./fields";
import FormSerializer from "./serializer";
import moment from "moment";
import xss from "xss";
import _get from "lodash.get";
import store from "..";

export const FormActionTypes = {
  UPDATE_FIELD: "UPDATE_FIELD",
  SET_FIELD_LOADING_STATE: "SET_FIELD_LOADING_STATE",
  BULK_UPDATE_FIELDS: "BULK_UPDATE_FIELDS",
  ADD_DELETED_REPEATABLE_FIELD_ID: "ADD_DELETED_REPEATABLE_FIELD_ID",
  PURGE_DELETED_REPEATABLE_FIELD_IDS: "PURGE_DELETED_REPEATABLE_FIELD_IDS",
};

const applyXss = (val) => {
  if (typeof val === "object" && val) {
    return Object.entries(val).reduce((acc, [key, value]) => {
      acc[key] = value;
      console.log({
        key,
        value,
        xssValue: xss(value),
      });
      return acc;
    }, {});
  } else {
    return xss(val);
  }
};

export const setFieldLoadingState = (isLoading) => async (dispatch) => {
  dispatch({
    type: FormActionTypes.SET_FIELD_LOADING_STATE,
    isLoading,
  });
};

export const updateField = ({ name, value }) => async (dispatch) => {
  dispatch({
    type: FormActionTypes.UPDATE_FIELD,
    field: {
      name,
      value,
    },
  });
};

export const addDeletedRepeatableFieldId = ({ name, id }) => async (
  dispatch
) => {
  dispatch({
    type: FormActionTypes.ADD_DELETED_REPEATABLE_FIELD_ID,
    name,
    id,
  });
};

export const purgeDeletedRepeatableFieldIds = () => async (dispatch) => {
  dispatch({
    type: FormActionTypes.PURGE_DELETED_REPEATABLE_FIELD_IDS,
  });
};

export const deleteRepeatableFields = async () => {
  const state = store.getState();
  const deletedDict = Object.entries(state.form.deletedRepeatableFieldIds);

  let promises = [];
  for (let i = 0; i < deletedDict.length; i++) {
    const [fieldKey, deletedIdArray] = deletedDict[i];
    for (let j = 0; j < deletedIdArray.length; j++) {
      const id = deletedIdArray[j];
      const answer = {
        ...FormSerializer.buildBaseAnswer(fieldKey),
        Id: id,
      };
      console.log('delete request data in deleteRepeatableFields:', answer)
      promises.push(apiClient.delete(`/answer`, answer));
    }
  }
  await Promise.all(promises);
};

export const fetchBasicFields = (fieldKeys, year = null) => async (dispatch) => {
  dispatch({
    type: FormActionTypes.SET_FIELD_LOADING_STATE,
    isLoading: true,
  });

  console.log('fetch basic fields year passed in:' + year)

  let data = {
    "lookup.questionKeys": fieldKeys.map((fieldKey) =>
      FormSerializer.getQuestionKey(fieldKey)
    ),
    "lookup.Year" : year
  }
  if(!year) {
    data = {
      "lookup.questionKeys": fieldKeys.map((fieldKey) =>
        FormSerializer.getQuestionKey(fieldKey)
      ),
    }
  }

  let answers = await apiClient.get("/answer", data);

  const reduxFields = FormSerializer.parseBulkAnswers(answers);

  // if activity fields, map PresentActivity value to activity
  for (let field in reduxFields) {
    if( field === FIELDS.ACTIVITY_SCHOOL || 
        field === FIELDS.ACTIVITY_WORK || 
        field === FIELDS.ACTIVITY_VOLUNTEER){

      reduxFields[field].map((activity)=>{
        if(activity.StopDate.value)
        {
          activity.PresentActivity = false
        }
        else{
          activity.PresentActivity = true
        }
      })
    }
  }

  let pastColleges = reduxFields[FIELDS.PAST_COLLEGE]
  if (pastColleges && pastColleges.length > 0) {
    const currentColleges = pastColleges.filter(pc => pc.StopTerm?.value === null)
    pastColleges = pastColleges.filter(pc => pc.StopTerm?.value !== null);

    if (currentColleges && currentColleges.length > 0) {
      reduxFields[FIELDS.CURRENT_COLLEGE] = currentColleges;
      reduxFields[FIELDS.PAST_COLLEGE] = pastColleges;
    }
  }

  dispatch({
    type: FormActionTypes.BULK_UPDATE_FIELDS,
    fields: reduxFields,
  });

  dispatch({
    type: FormActionTypes.SET_FIELD_LOADING_STATE,
    isLoading: false,
  });
  return answers;
  // console.log('answers retrieved: ', answers);
};

const ifFileExistsAttachToQuestionKey = async (
  answer,
  reactHookFormValueDict
) => {
  const file =
    reactHookFormValueDict &&
    reactHookFormValueDict[AnswerTypeTranscriptProps._FILE];
  const id = answer[AnswerTypeTranscriptProps.ID];

  if ((answer.AnswerType === TranscriptFieldType.AnswerType || answer.AnswerType === DocumentFieldType.AnswerType) && file) {
    await apiClient.attachFileToQuestionKey({
      id,
      file,
      questionKey: answer.QuestionKey,
      year: answer.Year,
    });
  }
};

const updateBasicFields = async (
  fieldValueDict,
  projection = [],
  exclusions = [],
  year = undefined
) => {
  // console.log('XXXXXXXXXXXXXX updateBasicFields called')
  // console.log("Data", fieldValueDict);
  // console.log("Excluding the following keys", exclusions);
  // console.log("Manully defined year", year)
  const results = []

  // temp - only send the following fields keys
  let answers;
  if (projection.length) {
    let answers = [];
    projection.forEach((_fieldKey) => {
      // console.log("field key: ",_fieldKey);
      if (Object.keys(fieldValueDict).includes(_fieldKey)) {
        const [, value] = Object.entries(fieldValueDict).find(
          ([fieldKey, _]) => fieldKey === _fieldKey
        );
        // console.log("field val: ",value);
        const answer = FormSerializer.serializeBasicFieldForUpdate(
          _fieldKey,
          value,
          { year }
        );
        if (answer) {
          ifFileExistsAttachToQuestionKey(answer, value);
          answers.push(answer);
        }
      }
    });
    // console.log("answers posted: ",answers);
    results.push(await apiClient.put("/answer", answers));

  }

  let updatedRepetableFieldIds = {};

  // Interate throught react hook form values and submit fields for updating in bulk PUT nedpoint
  answers = Object.entries(fieldValueDict).reduce(
    (_answers_, [fieldKey, value]) => {
      // if we don't want to submit a certain set of fields, skip appending them to bulk PUT
      if (exclusions.includes(fieldKey)) return _answers_;

      // if value is an array this is a repetable field
      if (Array.isArray(value)) {
        for (let index = 0; index < value.length; index++) {
          const repetableField = value[index];
          if (!repetableField) continue;
          const id = repetableField?.Id;
          // if repeatable field has id we will append it to the bulk PUT endpoint to update it's value(s)
          const hasId = typeof id === "number";
          // console.log("Has id?", repetableField)

          if (hasId) {
            // add to map so that we don't delete this answer in the repeatable fields deletion block
            if (hasId) updatedRepetableFieldIds[id] = 1;
              //console.log(`Field ${fieldKey}.${index} submitted for updating`, {id,});
              //console.log('repeatable field val:' ,repetableField)
            const answer = FormSerializer.serializeBasicFieldForUpdate(
              fieldKey,
              repetableField,
              { id }
            );
            ifFileExistsAttachToQuestionKey(answer, repetableField);
            _answers_.push(answer);
          }
        }
      } else {
        const answer = FormSerializer.serializeBasicFieldForUpdate(
          fieldKey,
          value,
          { year }
        );
        // console.log('individual answer:', answer)
        if (answer) {
          // check if answer is transcript, if so, attach file to question key
          ifFileExistsAttachToQuestionKey(answer, value);
          _answers_.push(answer);
        }
      }

      return _answers_;
    },
    []
  );
  if (answers.length) {
    const result = await apiClient.put("/answer", answers);
    results.push(result);
  }

  let promises = [];

  // POST individual new (without id) repeatable field entities for creation on API
  for (let i = 0; i < REPEATABLE_FIELDS.length; i++) {
    const repeatableFieldKey = REPEATABLE_FIELDS[i];
    const reduxFieldValues = fieldValueDict[repeatableFieldKey];

    if (!reduxFieldValues) continue;

    for (let j = 0; j < reduxFieldValues.length; j++) {
      const reduxFieldValue = reduxFieldValues[j];
      if (!reduxFieldValue) continue;
      const id = reduxFieldValue.Id;
      const hasId = typeof id === "number";
      if (!hasId) {
        const answer = FormSerializer.serializeBasicFieldForUpdate(
          repeatableFieldKey,
          reduxFieldValue
        );
        switch (repeatableFieldKey) {
          case FIELDS.OTHER_NAMES:
            promises.push(apiClient.post(`/answer/other-name`, answer));
            break;
          case FIELDS.ACTIVITY_WORK:
          case FIELDS.ACTIVITY_VOLUNTEER:
          case FIELDS.ACTIVITY_SCHOOL:
            // await activity promises or else activities will save randomly
            promises.push(await apiClient.post(`/answer/activity`, answer));
            break;
          case FIELDS.PAST_COLLEGE:
          case FIELDS.CURRENT_COLLEGE:
            if (typeof(answer.AttendedStop) !== 'string') {
              answer.AttendedStop = null;
            }
            //console.log('school post data', answer)
            promises.push(apiClient.post(`/answer/school`, answer));
            break;
          case FIELDS.DEGREES:
            promises.push(apiClient.post(`/answer/degree`, answer));
            break;
          case FIELDS.AGE_CHILD:
            promises.push(apiClient.post(`/answer/text`, answer));
            break;
          case FIELDS.CHILDREN:
            promises.push(apiClient.post(`/answer/child`, answer));
            break;
          default:
            break;
        }
      }
    }
  }
  await Promise.all(promises);

  //DELETE individual repeatable fields that have not been re-submitted for updating
  const state = store.getState();
  const deletedDict = Object.entries(state.form.deletedRepeatableFieldIds);

  promises = [];

  for (let i = 0; i < deletedDict.length; i++) {
    const [fieldKey, deletedIdArray] = deletedDict[i];
    for (let j = 0; j < deletedIdArray.length; j++) {
      const id = deletedIdArray[j];
      // if the id has been re-used for a new field, don't delete the entity
      if (updatedRepetableFieldIds[id]) continue;
      const answer = {
        ...FormSerializer.buildBaseAnswer(fieldKey),
        Id: id,
        Year: year
      };
      console.log('delete request data in updateBasicFields:', answer)
      promises.push(apiClient.delete(`/answer`, answer));
    }
  }
  await Promise.all(promises);

  return results;
};

export const submitForm = (fieldValueDict, ...args) => async (dispatch) => {
  // will handle updating every field type: basic, and composite fields
  return await updateBasicFields(fieldValueDict, ...args);
};

export const bulkUpdateFields = (fields) => async (dispatch) => {
  dispatch({
    type: FormActionTypes.BULK_UPDATE_FIELDS,
    fields,
  });
};
