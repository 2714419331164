import { useSelector } from 'react-redux';
import Checkbox from './../../components/Checkbox';
import { useForm, FormProvider } from "react-hook-form";
import { useState, useEffect } from 'react';
import { FieldSection, FieldRow, FieldSlot } from '../../components/Forms/FieldSection';
import { FIELDS } from '../../store/form/fields';
import FormSection from '../../components/FormSection';
import _get from 'lodash.get';
import { useDispatch } from 'react-redux';
import CollegeChoiceAnswer from '../../components/answer/CollegeChoiceAnswer';
import ClassStanding from "../../components/answer/question/ClassStanding";

/**
 * Standard college of choice section
 * 
 * @param {*} param0 
 * @returns 
 */
function EditCollegeOfChoice({ year = 0, hideDualEnrolled = true, onChange, onCancel, status, section }) {

    // const authState = useSelector((state) => state.auth);
    const reduxFormState = useSelector((state) => state.form);
    
    const methods = useForm({
        mode: 'onBlur'
    });
    const { setValue, handleSubmit, watch } = methods;

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();

    const onSubmit = async (data, e) => {
        data[FIELDS.COLLEGE_DUAL_ENROLLED] = (data[FIELDS.COLLEGE_DUAL_ENROLLED] == 'True' || data[FIELDS.COLLEGE_DUAL_ENROLLED] == true) ? true : false
    
        await section.saveAnswers(data, dispatch);       
        if (typeof onChange === 'function') {
            onChange(true);
        }        
    
    };

    const onError = () => {
        // console.log('ERROR',errors, e);
    }

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }
    }, [])
    const alertUser = e => {
        e.preventDefault()
        e.returnValue = ''
    }

  
    return (
        <FormProvider {...methods} >
            <form onSubmit={handleSubmit(onSubmit, onError)} >
                {/* NOTE: extra save button is located within FormSection component */}
                <FormSection title='College Choice' isEditable={true} onSaveClick={handleSubmit(onSubmit, onError)} onCancelClick={onCancel} loading={loading} status={status}>
                    
                    <CollegeChoiceAnswer answer={section.getAnswerByField(FIELDS.COLLEGE_CHOICE)} />                                                                
         
                    
                    {!hideDualEnrolled &&
                        <FieldSection>
                            <FieldRow>
                                <FieldSlot>
                                    <Checkbox name={FIELDS.COLLEGE_DUAL_ENROLLED} label="I will be dual enrolled with another school while attending this school." />
                                </FieldSlot>
                            </FieldRow>
                        </FieldSection>
                    }
                
                    <ClassStanding answer={section.getAnswerByField(FIELDS.COLLEGE_STANDING)} />                   
                </FormSection>
            </form>
        </FormProvider>
    );
}

export default EditCollegeOfChoice;
