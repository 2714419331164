import { actions } from "../../store";
import FormSerializer from "../../store/form/serializer";
import apiClient from "../API";
import AnswerSet from "../dtos/AnswerSet";
import { open } from "../../store/snack/actions";
import { QuestionKeyMapping } from "../../store/form/fields";
import AnswerDto from "../dtos/AnswerDto";
import AnswerUpdateResult from "../dtos/AnswerUpdateResult";
import RequirementSet from "../dtos/RequirementSet";

/**
 * track a collection of questions chopped into sections
 */
export class SectionFormHelper {

    /**
     * constructor
     * @param {ReactState} state
     */
    constructor(state, programCode) {
        this._sections = [];

        this._state = state[0]; //don't use this for individual section state, only overall state
        this._setState = state[1];
        this._isTrackingOnly = false;
        this._programCode = programCode;
    }

    /**
     * 
     * @param {*} state 
     * @param {*} ref 
     * @param {*} fieldKeys 
     * @returns {SectionHelper}
     */
    create(state, ref, fieldKeys, title) {
        var section = new SectionHelper(this, state, ref, fieldKeys, title);
        this._sections.push(section);
        return section;
    }

    /**
     * year of answers this section is focusing on
     */
    get year() {
        return this._state?.year;
    }

    /**
     * all section in htis group
     * @returns {Array<SectionHelper>}
     */
    get sections() {
        return this._sections;
    }

    /**
     * @returns {Array<string>}
     */
    get fieldKeys() {
        const allKeys = this._sections.reduce(function (a, b) { return a.concat(b._fieldKeys); }, []);
        return [...new Set(allKeys)]; //ensure distinct keys 
    }


    /**
     * is the entire group completed
     * @returns {Boolean}
     */
    get isComplete() {
        return this._sections.every(a => a.isComplete);
    }

    /**
     * the section we would recommend the student focus on 
     * @returns {SectionHelper}
     */
    get recommendedSection() {
        for (let section of this._sections) {
            if (!section.isComplete)
                return section;
        }
        return null;
    }

    /**
     * percent complete BY SECTION (not per question)
     * @returns {Number}
     */
    get percentComplete() {
        if (this._sections.length == 0)
            return 0;

        let totalScore = 0;
        let completeScore = 0;
        for (let section of this._sections) {
            if (section.isTrackingOnly)
                continue;

            totalScore++;
            if (section.isComplete)
                completeScore++;
        }
        return completeScore * 100 / totalScore;
    }

    /**
     * get wrapped answers as fetched by the API. Does not track changes to answers in current form
     * @returns {AnswerSet}
     */
    get answers() {
        return new AnswerSet(this._state?.answers);
    }

    /**
     * what program, is this section acting on behalf of (eg. if section forms are for a specific program's application)
     */
    get programCode(){
        return this._programCode;
    }

    /**
     * fetch the status for all sections. Useful for initializations
     * @param {Number} year application year, sets state year moving forward
     */
    async fetchState(year) {
        var answerData = await this.fetchAnswers(year);

        for (let section of this._sections) {
            section.updateCompletion();
        }
        return answerData;
    }

    /**
     * similar to fetchState but skips loading of the completion status. Just the answers
     * 
     * @param {number} year 
     */
    async fetchAnswers(year) {
        const answerData = await actions.fetchBasicFields(this.fieldKeys, year);        
        this._state = { 
            ...this._state, 
            answers: answerData, 
            year: year ?? new AnswerSet(answerData).year,  //if year was not explicity provided we'll have to sort it out for ourselves  
        };    
        this._updateState();

        return answerData;
    }

    _updateState() {
        this._setState(this._state);
    }

    /**
    * get a specific answer by it's form field key
    * @param {String} fieldKey 
    * @returns {*} raw answer DTO
    */
    getAnswerByField(fieldKey) {
        const questionKey = FormSerializer.getQuestionKey(fieldKey);
        let answer = this.answers.get(questionKey);
        if (!answer) {
            //a placeholder to tide us over until it loads
            return {
                QuestionKey: questionKey,
                Year: this.year,
                FieldKey: fieldKey,
                IsLoading: true,
                section: this,
            };
        }
        
        answer.FieldKey = fieldKey;
        answer.IsLoading = false;
        answer.section = this; //provide source context
        return answer;
    }

    getAnswerById(id){
        const answer = this._state?.answers.find(a => a.Id === id);  
        if (!answer)
            return null;
        answer.IsLoading = false;
        answer.section = this; //provide source context
        return answer;
    }


    /**
     * get all answers. 
     * @param {*} fieldKey form field key
     * @param {(*) => Boolean} subsetFilter if fieldKey represents a subset of the question key, define it's limiting criteria here
     */
    getAllAnswersByField(fieldKey, subsetFilter = null) {
        const questionKey = FormSerializer.getQuestionKey(fieldKey); //field key
        let answers = this.answers.getAll(questionKey);
        if (subsetFilter)
            answers = answers.filter(subsetFilter);

        //add contextual information
        for (let i in answers) {
            let answer = answers[i];
            answer.Index = i;
            answer.FieldKey = fieldKey;
            answer.IsLoading = false;
            answer.section = this;
        }
        return answers;
    }

}

/**
 * tie together common UI behviors of sections
 */
class SectionHelper {

    /**
     * 
     * @param {SectionFormHelper} parentGroup 
     * @param {*} state 
     * @param {*} ref (optional)
     * @param {Array<String>} fieldKeys 
     * @param {String} title fix a title to this section (optional)
     */
    constructor(parentGroup, state, ref, fieldKeys, title) {
        this._parentGroup = parentGroup;
        this._state = state[0] ? state[0] : { isComplete: null };
        this._setState = state[1];
        this._ref = ref;
        this._fieldKeys = fieldKeys;
        this._title = title;
        this._isCompletionVerbose = false;

        this.onView = () => { };
        this.onSave = () => { };
        this.onEdit = () => { };
    }

    /**
     * 
     */
    get isCompletionVerbose() {
        return this._isCompletionVerbose;
    }

    set isCompletionVerbose(value) {
        this._isCompletionVerbose = value;
    }

    get year() {
        return this._parentGroup.year;
    }

    get title() {
        if (!this._title)
            return null;
        return this._title
    }

    get ref() {
        return this._ref;
    }

    /**
     * get answer info from latest API answer state
     * TODO: this returns ALL answers. Should probably provide subset based on _fieldKeys
     * @returns {AnswerSet} answers 
     */
    get answers() {
        return this._parentGroup.answers;
    }

    /**
     * get only those answers that are relevant to this section
     * @returns {AnswerSet} answers
     */
    getAllAnswers(){
        const localAnswers = [];
        for (let fieldKey of this._fieldKeys){
            localAnswers.push(...this.getAllAnswersByField(fieldKey));
        }
        return new AnswerSet(localAnswers)
    }

    get fieldKeys() {
        return this._fieldKeys;
    }

    /**
     * get a specific answer by it's form field key
     * @param {string} fieldKey 
     * @returns {*} raw answer DTO
     */
    getAnswerByField(fieldKey) {
        return this._parentGroup.getAnswerByField(fieldKey);
    }

    /**
     * get by answer id
     * @param {number} id 
     * @returns {*} raw answer DTO
     */
    getAnswerById(id) {
        return this._parentGroup.getAnswerById(id);
    }

    /**
     * get all answers. 
     * @param {*} fieldKey form field key
     * @param {(*) => Boolean} subsetFilter if fieldKey represents a subset of the question key, define it's limiting criteria here
     */
    getAllAnswersByField(fieldKey, subsetFilter = null) {
        return this._parentGroup.getAllAnswersByField(fieldKey, subsetFilter);
    }


    /**
     * do ALL standard work of saving answers. Assume section is being handled autonomously
     * 
     * @param {*} data answer DTO looking form results
     * @param {Dispatch} dispatch a useDispatch 
     */
    async legacySaveAnswers(data, dispatch, exclusions) {
        await this._doAnswerSave(data, dispatch, true, exclusions);
    }

    /**
     * 
     * @param {*} data form data, slightly cleaned up
     * @param {Dispatch} dispatch 
     * @returns {Array<AnswerUpdateResult>}
     */
    async saveAnswers(data, dispatch) {
        return await this._doAnswerSave(data, dispatch, false);
    }

    /**
     * internal work of saving answers depending on the answer DTO aggregator we want to use
     * @param {*} data 
     * @param {*} dispatch 
     * @param {*} isLegacyAnswerAggregator 
     * @param {Array} disregardData -- used for legacy save process
     */
    async _doAnswerSave(data, dispatch, isLegacyAnswerAggregator, exclusions) {
        try {
            var results;
            if (isLegacyAnswerAggregator) {
                var rawResults = await actions.submitForm(data, [], exclusions);
                results = new AnswerUpdateResult(rawResults);
            }
            else {
                results = await this._putAnswers(data);                
            }            
            var successMessage = `Saved ${this.title ?? ''} section succesfully. `;    
            if (results.isAwardImpacted){
                successMessage += "This change may affect your eligibility for the grants and/or scholarships you have submitted an application for and/or may have been awarded. Your information is currently being reviewed by OSAC staff to determine your continued eligibility."
            }

            await this.updateCompletion();
            dispatch(
                open({
                    message: successMessage,
                    severity: "success",
                })
            );

            return results;

        } catch (ex) {
            dispatch(
                open({
                    message: ex.message,
                    severity: "success",
                })
            );
            return null; //no results to provide in error case
        }


    }

    /**
     * common save cleanup on action-form
     */
    save() {
        this._state = { ...this._state, isEditable: false };
        this._updateState();
        (async () => {

            //grrr, we have to refresh ALL questions for fellow sections to render their views correctly :-(
            await actions.purgeDeletedRepeatableFieldIds();
            const answerData = await actions.fetchBasicFields(this._parentGroup.fieldKeys, this.year);

            this._parentGroup._state = { ...this._parentGroup._state, answers: answerData };
            this._parentGroup._updateState();
            this.onSave();
            this.updateCompletion();
        })();
    }

    /**
     * common switch to view state for section
     */
    view() {
        this._state = { ...this._state, isEditable: false };
        this._updateState();
        this.onView();
    }

    /**
     * commonwork to switch section to an edit state
     */
    edit() {
        this._state = { ...this._state, isEditable: true };
        this._updateState();
        this.onEdit();
    }

    /**
     * @returns {Boolean}
     */
    get isEditable() {
        return this._state.isEditable === true;
    }

    /**
     * @returns {Boolean}
     */
    get isComplete() {
        return this._state.isComplete;
    }

    /**
     * standard summary of completion status
     * @returns {String}
     */
    get completionState() {
        if (this._state.isComplete)
            return "complete";
        else
            return "incomplete";

        //todo: what is inProgress?
    }

    /**
     * @returns {Boolean}
     */
    get isCompletionLoading() {
        return this._state.isComplete === null;
    }

    /**
     * @returns {Boolean}
     */
    get isAnswerLoading() {
        return this._parentGroup.answers.isLoading;
    }

    /**
     * all question keys referred to by this field
     * @returns {Array<string>}
     */
    get questionKeys() {
        const allKeys = this._fieldKeys.map((fieldKey) => FormSerializer.getQuestionKey(fieldKey));
        return [...new Set(allKeys)]; //ensure distinct keys
    }

    /**
     * track a section of meta or umbrella information. Not an actual form section 
     */
    get isTrackingOnly() {
        return this._isTrackingOnly;
    }

    /**
     * mark a section as an umbrella or meta section as opposed to an actual form section
     */
    set isTrackingOnly(value) {
        this._isTrackingOnly = value;
    }

    get programCode() {
        return this._parentGroup._programCode;
    }
    /**
     * get most recent verbose completion report
     */
    get completion() {
        return new RequirementSet(this._state.completionReport);
    }


    async updateCompletion() {
        const data = {
            "questionKeys": this.questionKeys,
            "year": this.year,
               //TODO: have API support a program code property here. I _think_ we don't yet need it for the 2-3 areas currently using this helper
        }
        let currentProgramCode = this.programCode;
        if (currentProgramCode){
            data.programCodes = currentProgramCode
        }

        if (this.isCompletionVerbose) {
            const completionReport = await apiClient.get("/answer/completion", data);
            this._state = { 
                ...this._state, 
                isComplete: completionReport.every(q => q.IsComplete === true),
                completionReport : completionReport
            };
        }
        else {
            const isComplete = await apiClient.get("/answer/iscomplete", data);
            this._state = { 
                ...this._state, 
                isComplete: isComplete,
                completionReport : null
            };
        }
        
        this._updateState();
    }

    /**
     * get starter answer data apporpriate for the given quesiton
     * @param {string} questionKey 
     * @param {number} fieldId 
     * @returns {AnswerDto}
     */
    createAnswer(questionKey, fieldId = 0) {
        var answer = AnswerDto.createOfType(questionKey, fieldId);
        answer._data.Year = this.year;
        return answer;
    }
    /**
     * eventual replacement we can use to perform answer updates
     * 
     * TODO: not all answer types are supported yet. Fill in support for new answer/component types as needed.
     * @param {*} data form data
     * @returns {Array<AnswerUpdateResult>} answer update result DTOs
     */
    async _putAnswers(data) {
            
        const nextAnswerState = [];
        const updatingAnswers = [];
        const answerUpdateTasks = [];     
        
        function getDataForAnswer(questionKey){

            function getFieldKeys(){          
                let matchingFields = Object.entries(QuestionKeyMapping).filter(e => e[1].QuestionKey.toLowerCase() === questionKey.toLowerCase()).map(e => e[0]);              
                if (matchingFields.length === 0)
                    return [questionKey]; //no preregistered field
                else 
                    return matchingFields;                 
            }
    
            var fieldKeys = getFieldKeys();
            if (fieldKeys.length === 1)
                return data[fieldKeys[0]];
        
            const remergedData = [];
            for (let fieldKey of fieldKeys){
                let fieldData = data[fieldKey];
                if (fieldData)
                    remergedData.push(...fieldData);                                
            }
            return remergedData;
        }
      
        
        function getAddEndpoint(key){
            if (!key)
                throw Error("Can't get endpoint when no question key provided");

            key = key.toLowerCase();
            switch (key) {
                case 'degrees':
                    return 'answer/degree';
                case 'collegehistories':
                    return 'answer/school';
                case 'activities':
                    return 'answer/activity';
                default:
                    return null;
            }
        }
            
        const answers = this._parentGroup._state.answers.map(x => new AnswerDto(x));
        // of answers already loaded into state, figure out which ones need to be (1) updated (2) deleted, or (3) left alone
        for (let answer of answers) {
                                                                   
            //make sure we're even processing this answer right now
            if (answer.isLocked || !this.questionKeys.map(x => x.toLowerCase()).includes(answer.questionKey.toLowerCase())){
                nextAnswerState.push(answer.dto);  //pass along state, leave alone
                continue; 
            }           
            
            var addEndpoint = getAddEndpoint(answer.questionKey); //can anwers be added/removed at all?
            var fieldData = getDataForAnswer(answer.questionKey);
            answer.updateFromField(fieldData);

            if (addEndpoint && answer.isDeleted) {
                answerUpdateTasks.push((async () => {
                    var result = await apiClient.delete('/answer', answer.locatorDto);
                    //don't pass into updated answer state, effectively removing it
                    return [result];
                })());  
            }
            else {                                
                updatingAnswers.push(answer.updateDto);
                nextAnswerState.push(answer.dto);
            }
        }
        //once we've gone through all the answers, tally up any that are just straight updates. Process in bulk.
        if (updatingAnswers.length > 0)
            answerUpdateTasks.push(apiClient.put('/answer', updatingAnswers));

        // finally, sift through actual fields to see any answers outside of state that need to be added to it
        for (let questionKey of this.questionKeys){
            var addEndpoint = getAddEndpoint(questionKey); 
            if (!addEndpoint) //adding not supported
                continue;

            var fieldData = getDataForAnswer(questionKey);
            var untrackedFields = fieldData?.filter(x => !answers.some(a => a.fieldId == x.Id));
                                 
            if (untrackedFields){
                for (let field of untrackedFields) {               
                    var answer = this.createAnswer(questionKey, field.Id);                        
                    answer.updateFromField(fieldData)                      
                    answerUpdateTasks.push((async () => {
                        const result = await apiClient.post(addEndpoint, answer.updateDto);
                        answer.id = result.Id; //get resulting answer id
                        nextAnswerState.push(answer.dto);
                        return [result];
                    })());              
                }  
            }
                                                         
        }
        
        // NOW we can send out all the requests for updates!
        const resultClumps = await Promise.all(answerUpdateTasks);

        this._parentGroup._state = { 
            ...this._parentGroup._state, 
            answers: nextAnswerState,  
        };
        this._parentGroup._updateState();
     
        return new AnswerUpdateResult(resultClumps);
    }


    _updateState() {
        this._setState(this._state);
    }


}

export default SectionFormHelper;