import { actions } from './../../store';
import { useDispatch, useSelector } from 'react-redux';
import Input from './../../components/Input';
import { AnswerTypeNameProps, FIELDS, getField } from '../../store/form/fields';
import MaskedDatePicker from './../../components/MaskedDatePicker';
import { useForm, FormProvider } from "react-hook-form";
import { Delete as DeleteIcon } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import Button from '../../components/Button';
import RepeatableField, { repeatableFieldName } from '../../components/Utility/RepeatableField';
import { FieldSection, FieldRow, FieldSlot } from '../../components/Forms/FieldSection';
import HiddenInput from '../../components/Utility/HiddenInput';
import FormSection from '../../components/FormSection';
import { open } from "../../store/snack/actions";
import CertifyAnswer from '../../components/answer/CertifyAnswer';
import DateAnswer from '../../components/answer/DateAnswer';
import TextAnswer from '../../components/answer/TextAnswer';

function EditBasicInfo({section, onChange, onCancel }) {
  const formState = useSelector((state) => state.form);

  const methods = useForm({
    mode: 'onBlur'
  });
  const { getValues, setValue, handleSubmit, setError, clearErrors, watch, formState: localFormState } = methods;
  const watchLastName = watch(FIELDS.LAST_NAME, false)
  const watchBirthday = watch(FIELDS.BIRTHDATE, false)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch();


  const onSubmit = async (data, e) => {
    // console.log("Data", data)
    try {
      setLoading(true)

      const result = await actions.submitForm(data, [],
        [`${FIELDS.LAST_NAME}-confirm`,
        `${FIELDS.BIRTHDATE}-confirm`,
        //'PronounVerify',
        'GenderVerify',
      ]);


      if(typeof onChange === 'function'){
        onChange(true);
      }

    } catch(e) {
      dispatch(open({
        message: e.message,
        severity: "success",
      }))
      console.error({e})
    } finally {
      setLoading(false)
    }
  };

  const onError = (errors) => {
    console.log("ERROR", errors);
    dispatch(
      open({
        message: "This section is not complete. Be sure to resolve all errors above.",
        severity: "success",
      }));
  }

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, []);

  const validateConfirm = (value, compareValue) => {
    // console.log(value, compareValue)
    if (value === compareValue) {
      return true
    } else {
      return "Does not match"
    }
  }

  const validateDate = (value) => {
    if (value !== 'Invalid date') {
      return true
    } else {
      return "Please enter a valid date"
    }
  }

  const [genderError, setGenderError] = useState(null)
  const validateGender = (value) => {
    const genderFields = [
      FIELDS.GENDER__FEMALE,
      FIELDS.GENDER__MALE,
      FIELDS.GENDER__TRANSWOMAN,
      FIELDS.GENDER__TRANSMAN,
      FIELDS.GENDER__NON_BINARY,
      FIELDS.GENDER__OTHER,
      FIELDS.GENDER__NA
    ]
    const genderValues = genderFields.map(f => getValues(f))
    const isGenderFilled = genderValues.some(gv => gv === 'True' || gv === true)
    if (isGenderFilled === true) {
      setGenderError(null)
      return true
    } else {
      setGenderError("Please select at least one Gender Identity")
      return false
    }
  }

  const handleBirthdayChange = (value) => {
    setValue(FIELDS.BIRTHDATE, value)
  }

  const alertUser = e => {
    e.preventDefault()
    e.returnValue = ''
  }

  const get = (fieldKey) => formState.fields[fieldKey]?.value;


  useEffect(() => {
    setValue(`${FIELDS.LAST_NAME}-confirm`, formState.fields[FIELDS.LAST_NAME]?.value)
  },
  [formState.fields[FIELDS.LAST_NAME]?.value])

  const [confirmBirthdate, setConfirmBirthdate] = useState(null)
  useEffect(() => {
    setValue(`${FIELDS.BIRTHDATE}-confirm`, formState.fields[FIELDS.BIRTHDATE]?.value)
    setConfirmBirthdate(formState.fields[FIELDS.BIRTHDATE]?.value)
  },
  [formState.fields[FIELDS.BIRTHDATE]?.value])


  return (
    <FormProvider {...methods} >
      <form onSubmit={handleSubmit(onSubmit, onError)} >
        <FormSection 
          title='Basic Information'
          isEditable={true} 
          onSaveClick={handleSubmit(onSubmit, onError)} 
          onCancelClick={onCancel} 
          loading={loading}>
          <FieldSection title="Name">
            <FieldRow>
              <FieldSlot >
                <TextAnswer onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} answer={section.getAnswerByField(FIELDS.FIRST_NAME)} inputLabel='Legal First Name' placeholder="Legal First Name" variant='outlined' isRequired={true} />
              </FieldSlot>
              <FieldSlot>
                <TextAnswer onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} answer={section.getAnswerByField(FIELDS.MIDDLE_INITIALS)} inputLabel='Legal Middle Name/Initial' placeholder="Legal Middle Name Initial" variant='outlined' />
              </FieldSlot>
              <FieldSlot>
                <TextAnswer onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} answer={section.getAnswerByField(FIELDS.LAST_NAME)} inputLabel='Legal Last Name' placeholder="Legal Last Name" variant='outlined' isRequired={true} />
              </FieldSlot>
              <FieldSlot>
                <Input onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} name={`${FIELDS.LAST_NAME}-confirm`} inputLabel='Confirm Legal Last Name' placeholder="Legal Last Name" variant='outlined' isRequired={true} rules={{ validate: value => validateConfirm(value, watchLastName) }}/>
              </FieldSlot>
            </FieldRow>
          </FieldSection>

          <FieldSection title={getField(FIELDS.OTHER_NAMES).question}>
            <RepeatableField name={FIELDS.OTHER_NAMES}>
              {(indices, handleAddIndex, handleDeleteIndex, previouslyDeletedIndices) => {
                return (
                  <>
                    {indices.map((i,index) => {
                      const [firstNameFieldName, middleNameFieldName, lastNameFieldName, id] = [
                        repeatableFieldName(FIELDS.OTHER_NAMES, i, AnswerTypeNameProps.FIRST_NAME),
                        repeatableFieldName(FIELDS.OTHER_NAMES, i, AnswerTypeNameProps.MIDDLE_NAME),
                        repeatableFieldName(FIELDS.OTHER_NAMES, i, AnswerTypeNameProps.LAST_NAME),
                        repeatableFieldName(FIELDS.OTHER_NAMES, i, AnswerTypeNameProps.ID)
                      ]

                      return (
                        <FieldRow>
                          <FieldSlot>
                            <HiddenInput name={id}/>
                            <Input
                              name={firstNameFieldName}
                              defaultValue={getValues(firstNameFieldName)}
                              key={firstNameFieldName}
                              inputLabel='First Name'
                              variant='outlined'
                              isRequired={true}
                              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                              />
                          </FieldSlot>
                          <FieldSlot>
                            <Input
                              name={middleNameFieldName}
                              defaultValue={getValues(middleNameFieldName)}
                              key={middleNameFieldName}
                              inputLabel='Middle Name'
                              variant='outlined'
                              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                              />
                          </FieldSlot>
                          <FieldSlot>
                            <Input
                              name={lastNameFieldName}
                              defaultValue={getValues(lastNameFieldName)}
                              key={lastNameFieldName}
                              inputLabel='Last Name'
                              variant='outlined'
                              isRequired={true}
                              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                              />
                            <DeleteIcon style={{ color: 'rgba(255, 0, 0, 0.5)' }}onClick={handleDeleteIndex(i)} />
                          </FieldSlot>
                        </FieldRow>
                      )
                    })}
                    <FieldRow>
                      <FieldSlot style={{ marginTop: '0.5rem' }}>
                        <Button name='AddName' label='+ Add name' variant='text' style={{ textTransform: "capitalize" }} onClick={handleAddIndex} />
                      </FieldSlot>
                    </FieldRow>
                  </>
                )
              }}
            </RepeatableField>
          </FieldSection>

          <FieldSection title="Birthdate">
            <FieldRow>
              <FieldSlot>
                <DateAnswer 
                    rules={{ validate: value => validateDate(value) }}
                    answer={section.getAnswerByField(FIELDS.BIRTHDATE)}                 
                    minDate={new Date("01-01-1934")} 
                    disableFuture={true} name={FIELDS.BIRTHDATE} 
                    label='Birthdate' isRequired={true} 
                    onChange={handleBirthdayChange} />
              </FieldSlot>
              <FieldSlot>
                <MaskedDatePicker minDate={new Date("01-01-1934")} disableFuture={true} name={`${FIELDS.BIRTHDATE}-confirm`} label='Confirm Birthdate' isRequired={true} rules={{ validate: value => validateConfirm(value, watchBirthday) }} defaultValue={confirmBirthdate} />
              </FieldSlot>
              <FieldSlot></FieldSlot>
            </FieldRow>
          </FieldSection>


          <FieldSection title="Gender Identity" isRequired>
            <HiddenInput name="GenderVerify" rules={{ validate: value => validateGender(value) }}></HiddenInput>
            <FieldRow>
              <FieldSlot>
                { genderError && <div style={{ marginTop: '.75rem', color: 'red'}}>{genderError}</div> }

                <CertifyAnswer label="Female" answer={section.getAnswerByField(FIELDS.GENDER__FEMALE)} />
                <CertifyAnswer label="Male" answer={section.getAnswerByField(FIELDS.GENDER__MALE)} />
                <CertifyAnswer label="Trans Female/Trans Woman" answer={section.getAnswerByField(FIELDS.GENDER__TRANSWOMAN)} />
                <CertifyAnswer label="Trans Male/Trans Man" answer={section.getAnswerByField(FIELDS.GENDER__TRANSMAN)} />
                <CertifyAnswer label="Non-binary/Genderqueer/Gender non-conforming" answer={section.getAnswerByField(FIELDS.GENDER__NON_BINARY)} />
                <CertifyAnswer label="Different Identity" answer={section.getAnswerByField(FIELDS.GENDER__OTHER)} />
                <CertifyAnswer label="Choose not to say" answer={section.getAnswerByField(FIELDS.GENDER__NA)} />
               
              </FieldSlot>
            </FieldRow>
          </FieldSection>

          <FieldSection title="Pronouns" isRequired={false}>

          <HiddenInput name="PronounVerify"/>
            <FieldRow>
              <FieldSlot>
                <CertifyAnswer label="She/Her/Hers" answer={section.getAnswerByField(FIELDS.PRONOUNS__FEMALE)} />
                <CertifyAnswer label="He/Him/His" answer={section.getAnswerByField(FIELDS.PRONOUNS__MALE)} />
                <CertifyAnswer label="They/Them/Their" answer={section.getAnswerByField(FIELDS.PRONOUNS__NEUTRAL)} />               
              </FieldSlot>
              <TextAnswer onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} answer={section.getAnswerByField(FIELDS.PRONOUNS__OTHER)} 
                    inputLabel='Other Pronouns' placeholder="Enter other preferred pronouns" variant='outlined'/>
            </FieldRow>
          </FieldSection>

          <FieldSection title="Identity Certification">
            <FieldRow>
              {/* Note: container propr will enable flexbox, so we can use alignItems */}
              <FieldSlot>
                <CertifyAnswer answer={section.getAnswerByField(FIELDS.CERTIFY_IDENTITY)} isRequired={true} label="I certify that I am the person named above." />
              </FieldSlot>
            </FieldRow>
          </FieldSection>
   
        </FormSection>
      </form>
    </FormProvider>
  );
}

export default EditBasicInfo;
