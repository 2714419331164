import { useFormContext } from "react-hook-form";
import Input from "../Input";
import React, { Fragment } from "react";
import NumericDecimal from "../NumericDecimal";
import { FieldRow, FieldSlot } from "../Forms/FieldSection";
import { repeatableFieldName, } from "../Utility/RepeatableField";
import MaskedDatePicker from "../MaskedDatePicker";
import Checkbox from "../Checkbox";
import CodeDropdown from "../DataDriven/CodeDropdown";
import HiddenInput from "../Utility/HiddenInput";
import { AnswerTypeActivityProps } from "../../store/form/fields";
import AnswerLock from "./status/AnswerLock";
/**
 * render one activity from it's answer
 * @param {*} param0 
 */
function ActivityAnswer({ answer, disabled, ...props }) {

    const methods = useFormContext({ mode: "onBlur", });
    const { getValues, watch } = methods;

    const id = answer.Id;
    const name = answer.FieldKey;
    const index = answer.Index;
    const type = answer.ActivityTypeCode;
    const activityIdFieldName = repeatableFieldName(name, index, AnswerTypeActivityProps.ID);
    const activityTypeFieldName = repeatableFieldName(name, index, AnswerTypeActivityProps.TYPE);
    const titleFieldName = repeatableFieldName(name, index, AnswerTypeActivityProps.TITLE);
    const startDateFieldName = repeatableFieldName(name, index, AnswerTypeActivityProps.START_DATE);
    const endDateFieldName = repeatableFieldName(name, index, AnswerTypeActivityProps.END_DATE);
    const freqFieldName = repeatableFieldName(name, index, AnswerTypeActivityProps.FREQUENCY);
    const weeklyHrsFieldName = repeatableFieldName(name, index, AnswerTypeActivityProps.HOURS_PER_UNIT);
    const totalHrsFieldName = repeatableFieldName(name, index, AnswerTypeActivityProps.TOTAL_HOURS);
    const responsibilitiesFieldName = repeatableFieldName(name, index, AnswerTypeActivityProps.ACCOMPLISHMENTS);

    const isDisabled = disabled || answer.IsLocked;
    
    return (
        //Note: Add a unique key to mapped components to get correct updates
        <Fragment key={id}>
            {/* Assuming that Id: 0 triggers the system to set an id for a new activity when saved, set activity id to 0 if activity has new flag */}
            <HiddenInput name={activityIdFieldName} value={answer.isNew? 0 : answer.Id} />
            <HiddenInput name={activityTypeFieldName} defaultValue={type} />
            <FieldRow>
                <FieldSlot>
                    <Input
                        disabled={isDisabled}
                        name={titleFieldName}
                        key={titleFieldName}
                        defaultValue={getValues(titleFieldName)}
                        inputLabel={<>Title <AnswerLock answer={answer} /></>}
                        inputProps={{ maxLength: 50 }}
                        placeholder="Only 50 characters allowed."
                        isRequired={true}
                    />
                    
                </FieldSlot>
            </FieldRow>
            <div>
                <Checkbox
                    disabled={isDisabled}
                    name={`${type}${id}${index}`}
                    defaultValue={answer.StopDate ? false : !answer.isNew }
                    label="I am currently involved in this activity"
                />
            </div>
            <FieldRow>
                <FieldSlot>
                    <MaskedDatePicker
                        name={startDateFieldName}
                        key={startDateFieldName}
                        defaultValue={getValues(startDateFieldName)}
                        label="Start Date"
                        isRequired={true}
                        disabled={isDisabled}
                    />
                </FieldSlot>
                <FieldSlot>
                    {watch(`${type}${id}${index}`) ? (
                        <div style={{ paddingTop: "3rem" }}>- Present</div>
                    ) : (
                        <MaskedDatePicker
                            name={endDateFieldName}
                            key={endDateFieldName}
                            defaultValue={getValues(endDateFieldName)}
                            label="End Date"
                            isRequired={true}
                            disabled={isDisabled}
                        />
                    )}
                </FieldSlot>
            </FieldRow>
            <FieldRow>
                <FieldSlot md={4}>
                    <CodeDropdown
                        codeType="ActivityFrequency"
                        name={freqFieldName}
                        key={freqFieldName}
                        defaultValue={getValues(freqFieldName)}
                        label="Frequency"
                        isRequired={true}
                        disabled={isDisabled}
                    />
                </FieldSlot>
                <FieldSlot md={4}>
                    <NumericDecimal
                        precision={5}
                        scale={1}
                        allowNegative={false}
                        name={weeklyHrsFieldName}
                        key={weeklyHrsFieldName}
                        defaultValue={getValues(weeklyHrsFieldName)}
                        inputLabel="Estimated Weekly Hours"
                        isRequired={true}
                        disabled={isDisabled}
                    />
                </FieldSlot>
                <FieldSlot md={4}>
                    <NumericDecimal
                        precision={10}
                        scale={1}
                        allowNegative={false}
                        name={totalHrsFieldName}
                        key={totalHrsFieldName}
                        defaultValue={getValues(totalHrsFieldName)}
                        inputLabel="Total Estimated Hours"
                        isRequired={true}
                        disabled={isDisabled}
                    />
                </FieldSlot>
            </FieldRow>
            <FieldRow>
                <FieldSlot md={12}>
                    <Input
                        name={responsibilitiesFieldName}
                        inputLabel="Responsibilities"
                        inputProps={{ maxLength: 115 }}
                        placeholder="Only 115 characters allowed."
                        isRequired={true}
                        disabled={isDisabled}
                        defaultValue={getValues(responsibilitiesFieldName)}
                    />
                </FieldSlot>
            </FieldRow>
        </Fragment>
    );
}

export default ActivityAnswer;