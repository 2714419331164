import { useForm, FormProvider } from "react-hook-form";
import { useState, useEffect } from 'react';
import { FieldSection } from '../../components/Forms/FieldSection';
import FormSection from '../../components/FormSection';
import _get from 'lodash.get';
import { useDispatch } from 'react-redux';
import DegreesQuestion from "../../components/answer/question/DegreesQuestion";

/**
 * college plans as relevant to renewal applications
 * @param {*} param0 
 * @returns 
 */
function RenewEditCollegePlan({ onCancel, section, status }) {
       
    const methods = useForm({
        mode: 'onBlur'
    });
    
    const { getValues, handleSubmit} = methods;

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();

    const onSubmit = async (data) => {
      section.saveAnswers(data, dispatch);
    };
  
    const onError = (errors, e) => {
      // console.log('ERROR',errors, e);
    }

    useEffect(() => {
      window.addEventListener('beforeunload', alertUser)
      return () => {
        window.removeEventListener('beforeunload', alertUser)
      }
    }, [])
    const alertUser = e => {
      e.preventDefault()
      e.returnValue = ''
    } 

    return (
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <FormSection
            title="College Plans"
            isEditable={true}
            onSaveClick={handleSubmit(onSubmit, onError)}
            onCancelClick={onCancel}
            loading={loading}
            status={status}
          >
            {/* Use field section component to add form section padding to area */}
            <FieldSection title="Degree Plan" isRequired>
                <DegreesQuestion section={section} />
            </FieldSection>
      
          </FormSection>
        </form>
      </FormProvider>
    );
  }

export default RenewEditCollegePlan;
