import { useForm, FormProvider } from "react-hook-form";
import { useState, useEffect } from 'react';
import { FieldSection, FieldRow, FieldSlot } from '../../components/Forms/FieldSection';
import FormSection from '../../components/FormSection';
import { useDispatch } from 'react-redux';
import DropdownAnswer from '../../components/answer/DropdownAnswer';
import { FIELDS } from "../../store/form/fields";
import { Alert } from "@material-ui/core";
import RequirementSet from "../../utils/dtos/RequirementSet";
import apiClient from "../../utils/API";

function EditCitizenShip({ onChange, onCancel, section }) {

    const methods = useForm({  mode: 'onBlur'});
    const { errors, formState, getValues, setValue, handleSubmit, watch } = methods;
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [completionData, setCompletionData] = useState(null);
    const sectionCompletion = new RequirementSet(completionData); 
    const completion = sectionCompletion.getField(FIELDS.CITIZENSHIP_STATUS);
    
    async function getCompletionReport() {
        const data = await apiClient.getCompletionReport("SCH", section.year, [FIELDS.CITIZENSHIP_STATUS])
        setCompletionData(data);
        return new RequirementSet(data).getField(FIELDS.CITIZENSHIP_STATUS);
    }


    const onSubmit = async (data, e) => {
        await section.saveAnswers(data, dispatch);                          
        if (typeof onChange === 'function'){
            onChange(true);
        }        
    };

    const onError = (errors, e) => {
        // console.log('ERROR',errors, e);
    }

    useEffect(() => {
        getCompletionReport();
    }, []);

    useEffect(() => {
      
        window.addEventListener('beforeunload', alertUser)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }        
    }, [])
    const alertUser = e => {
        e.preventDefault()
        e.returnValue = ''
    }

    return (
        <FormProvider {...methods} >
            <form onSubmit={handleSubmit(onSubmit, onError)} >
                <FormSection title='Citizenship Status' isEditable={true} onSaveClick={handleSubmit(onSubmit, onError)} onCancelClick={onCancel} loading={loading}>

                    {completion?.isDisqualifying === true &&
                        <Alert severity="error" className="alert alert--error">
                            <p>                          
                                International students (such as F-1, M-1, and J-1 visas) are not eligible to receive OSAC Scholarships.                             
                            </p>
                        </Alert>
                    }

                    <FieldSection title="Select Your Citizenship Status" withDivider={true}>
                        <FieldRow>
                            <FieldSlot md={8}>
                                <DropdownAnswer answer={section.getAnswerByField(FIELDS.CITIZENSHIP_STATUS)} label='Citizenship Status' variant='outlined' isRequired={true} />
                            </FieldSlot>
                        </FieldRow>
                    </FieldSection>
                </FormSection>
            </form>
        </FormProvider>
    );
}

export default EditCitizenShip;
