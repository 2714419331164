import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useEffect } from "react";
import {
  FieldSection,
  FieldRow,
  FieldSlot,
} from "../../components/Forms/FieldSection";
import { FIELDS, getField } from "../../store/form/fields";
import FormSection from "../../components/FormSection";
import { useDispatch } from "react-redux";
import { useState } from "react";
import TextAnswer from "../../components/answer/TextAnswer";
import DegreesQuestion from "../../components/answer/question/DegreesQuestion";

/**
 * standard issue college
 * @param {SectionHelper} param0.section 
 * @param {Component} param0.status status display
 * @returns 
 */
function EditCollegePlan({ onCancel, onChange, status, section }) {
  const methods = useForm({ mode: "onBlur", });
  const { getValues, handleSubmit } = methods;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    await section.saveAnswers(data, dispatch);

    if (typeof onChange === 'function') {
        onChange(true);
    }  
  };

  const onError = () => {
    // console.log('ERROR',errors, e);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <FormSection
          title="College Plans"
          isEditable={true}
          onSaveClick={handleSubmit(onSubmit, onError)}
          onCancelClick={onCancel}
          loading={loading}
          status={status}
        >
          {/* Use field section component to add form section padding to area */}
          <FieldSection title="Degree Plan" isRequired>
            <DegreesQuestion section={section} />
          </FieldSection>

          <FieldSection withDivider={true}>
            <FieldRow>
              <FieldSlot md={6}>
                <TextAnswer
                  answer={section.getAnswerByField(FIELDS.CAREER_GOAL)}
                  inputLabel={getField(FIELDS.CAREER_GOAL).question}
                  key={FIELDS.CAREER_GOAL}
                  variant="outlined"
                  isRequired={true}
                  inputProps={{ maxLength: 40 }} 
                />
              </FieldSlot>
            </FieldRow>
          </FieldSection>

        </FormSection>
      </form>
    </FormProvider>
  );
}

export default EditCollegePlan;
