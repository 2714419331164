import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Button from "../../components/Button";
import AppHeader from "../../components/application/AppHeader";
import PortalView from "./../../components/Global/PortalView";
import ProgressBar from "../../components/progress/ProgressBar";
import ProgressBarLabel from "../../components/progress/ProgressBarLabel";
import CompleteSubmission from "../../components/CompleteSubmission";
import { Alert, AlertTitle } from '@material-ui/lab';
import { Checkbox } from "@material-ui/core";
import { FIELDS, TRANSCRIPT_QUESTIONS } from "../../store/form/fields";
import { goBack } from "../../utils";
import apiClient from "../../utils/API";
import AnswerOption from "../../utils/dtos/AnswerOption"
import ProgramInfo from "../../utils/dtos/ProgramInfo"
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { open } from "../../store/snack/actions";
import { HIGHSCHOOL_CODE_TYPES } from "./../../components/DataDriven/HighschoolTypeDropdown"
import { FieldSection, FieldSlot, FieldRow } from "../../components/Forms/FieldSection";
import { get } from 'lodash'
import FormSerializer from "../../store/form/serializer";
import moment from "moment";
import _get from "lodash.get";
import CohortTable from "./CohortTable";
import ApplicationSubmitButton from "../../components/Utility/ApplicationSubmitButton";
import ApplicationInfo from "../../utils/dtos/ApplicationInfo";
import SectionFormHelper from "../../utils/helper/SectionHelper";
import DecimalAnswer from "../../components/answer/DecimalAnswer";
import TranscriptQuestion from "../../components/answer/question/TranscriptQuestion";
import GpaVerificationFormLink from "./GpaVerificationDocument";
import { useRef } from "react";
import SectionStateIcon from "../../components/progress/SectionStateIcon";
import FormSection from "../../components/FormSection";
import { useGetTranscriptInstructions } from "../../components/TranscriptInstructions";


const PROGRAM_CODE = "ORP";

/**
 * Oregon Promise application
 * @returns {Component}
 */
function OregonPromise() {

    const history = useHistory();
    const dispatch = useDispatch();
    const handleCancel = () => {
        goBack({
            history,
            defaultRoute: "dashboard",
        });
    };
    const methods = useForm({
        mode: "onBlur",
    });
    const { setValue, handleSubmit, watch } = methods;
    const formState = useSelector((state) => state.form);

    const getReduxField = (fieldKey) => {
        return _get(formState, `fields.${fieldKey}.value`);
    };

    const sectionGroup = new SectionFormHelper(useState(null), PROGRAM_CODE);
    const cohortSection = sectionGroup.create(useState(null), useRef(), [
        FIELDS.COMM_COLLEGE_START_TERM,
    ], 'Community College Start Term');
    const gpaSection = sectionGroup.create(useState(null), useRef(), [
        FIELDS.HIGHSCHOOL_TYPE,
        FIELDS.PROOF_GPA__UPLOAD,
        FIELDS.PROOF_GPA__MAIL,
        FIELDS.PROOF_GPA__REGISTRAR,
        FIELDS.HIGHSCHOOL_GPA,
        FIELDS.HIGHSCHOOL
    ], 'GPA/Transcripts');

    const [grantDetails, setGrantDetails] = useState(null);
    const program = new ProgramInfo(grantDetails);
    const year = program.recentSeason?.year;

    const minimumGpa = program.recentSeason?.getConfiguration("MinimumGpa");


    const [cohortOptionsData, setCohortOptions] = useState(null);
    const cohortOptions = cohortOptionsData?.map(x => new AnswerOption(x));

    const [applicationData, setApplicationData] = useState(null);
    const app = new ApplicationInfo(applicationData);

    /**
     * pull initial info from API
     */
    useEffect(async () => {

        const result = await apiClient.getProgram(PROGRAM_CODE);
        setGrantDetails(result);
        const program = new ProgramInfo(result);
        const year = program.recentSeason.year;

        const [appResult, optionResult, _] = await Promise.all([
            apiClient.getApplication(PROGRAM_CODE, year),

            apiClient.get("/answer/options", {
                "lookup.questionKey": FormSerializer.getQuestionKey(FIELDS.COMM_COLLEGE_START_TERM),
                "lookup.year": year
            }),
            sectionGroup.fetchState(year)
        ]);
        setApplicationData(appResult);
        setCohortOptions(optionResult);

        setDisableLetterGrades(get(formState, `fields.${FIELDS.HIGHSCHOOL_GPA}.value`) == '0.01')
        setNoLetterGrades(!get(formState, `fields.${FIELDS.HIGHSCHOOL_GPA}.value`) == '0.01')

    }, []);


    const watchSemester = watch(FIELDS.COMM_COLLEGE_START_TERM)
    const [selectedDeadline, setSelectedDeadline] = useState(null);
    useEffect(() => {
        if (watchSemester && cohortOptions) {

            const selectedOption = cohortOptions.find(option => option.value === watchSemester);
            const deadline = selectedOption.getNote("deadline");
            setSelectedDeadline(deadline);

            // now a quick slight-of-hand to avoid having to load the full application all over
            if (cohortOptions.some(option => option.Deadline === applicationData.Deadline)) {
                //if the applicationData deadline is one of the preset deadline, update it. Otherwise it's probably an extension
                applicationData.Deadline = deadline;
                setApplicationData(applicationData);
            }
        }
    }, [watchSemester, cohortOptionsData])

    const [disableLetterGrades, setDisableLetterGrades] = useState(false)
    const [noLetterGrades, setNoLetterGrades] = useState(false)


    const toggleLetterGrades = () => {
        setDisableLetterGrades(!disableLetterGrades)
    }

    const [showConfirmation, setShowConfirmation] = useState(false);

    const onError = (errors, e) => {
        console.log(errors, e);
        dispatch(
            open({
                message: "There has been a validation error. Please scroll up and look for missing required fields.",
                severity: "success",
            })
        );
    };



    const cohortWatch = watch(FIELDS.COMM_COLLEGE_START_TERM);
    const cohortAnswer = cohortSection.getAnswerByField(FIELDS.COMM_COLLEGE_START_TERM);

    useEffect(() => {
        if (!cohortWatch)
            return;
        if (cohortAnswer.IsLoading)
            return;

        if (cohortWatch === cohortAnswer.Response)
            return;

        const data = { [FIELDS.COMM_COLLEGE_START_TERM]: cohortWatch };

        cohortSection.saveAnswers(data, dispatch);

    }, [cohortWatch, cohortAnswer.IsLoading]);

    /**
     * save all the GPA/transcript changes
     * @param {*} data 
     * @param {*} e 
     */
    const onSave = async (data) => {

        if (disableLetterGrades === true) {
            data[FIELDS.HIGHSCHOOL_GPA] = "0.01"
        }

        gpaSection.legacySaveAnswers(data, dispatch);
    };


    const [submitLoading, setSubmitLoading] = useState(false);

    /**
     * submit the Oregon Promise application,
     * 
     * includes saving any recent changes as well
     */
    const actualSubmit = async () => {
        const actualSubmission = async (data) => {
            if (disableLetterGrades === true) {
                data[FIELDS.HIGHSCHOOL_GPA] = "0.01"
            }

            await gpaSection.legacySaveAnswers(data, dispatch);
            await apiClient.submitApplication(PROGRAM_CODE, year);
            // console.log(data, e);
            setShowConfirmation(true);
        };

        try {
            setSubmitLoading(true)
            const execSubmission = handleSubmit(actualSubmission, onError);
            await execSubmission();
            dispatch(
                open({
                    message: "Submitted Successfully",
                    severity: "success",
                })
            );
        } catch (e) {
            console.error(e);
            dispatch(
                open({
                    message: e.message,
                    severity: "success",
                })
            );
        } finally {
            setSubmitLoading(false);
        }
    };


    const highSchoolType = getReduxField(FIELDS.HIGHSCHOOL_TYPE, formState);

    const [isGed, setIsGed] = useState(false);
    useEffect(() => {
        const gedTypes = [
            HIGHSCHOOL_CODE_TYPES.GED,
            HIGHSCHOOL_CODE_TYPES.GED_AND_HOME_SCHOOL
        ]

        setIsGed(highSchoolType && gedTypes.includes(highSchoolType));

    }, [highSchoolType])

    const HIGHSCHOOl_GPA = get(formState, `fields.${FIELDS.HIGHSCHOOL_GPA}.value`)
    const [gpaWarning, setGpaWarning] = useState(false);

    useEffect(() => {
        const GPA = parseInt(HIGHSCHOOl_GPA);
        if (isNaN(GPA)) {
            return
        }
        if (GPA < minimumGpa) {
            setGpaWarning(true);
        }
    }, [HIGHSCHOOl_GPA])

    const watchGpa = watch(FIELDS.HIGHSCHOOL_GPA)

    useEffect(() => {
        setGpaWarning(watchGpa < minimumGpa)
    }, [watchGpa])


    const onEdit = () => {
        setShowConfirmation(false);
        window.location.reload(true); /* 3722/SP236: Calling components aren't keeping application state so, reload the page from server. */
    }


    const submitRef = useRef();

    const hsGpaAnswer = gpaSection.getAnswerByField(FIELDS.HIGHSCHOOL_GPA);
    const pecentComplete = sectionGroup.isComplete && app.isSubmitted ? 100 : Math.round(25 + 74 * sectionGroup.percentComplete / 100 + (app.isSubmitted ? 1 : 0));

    const { transcriptInstructions } = useGetTranscriptInstructions({ programCode: PROGRAM_CODE });

    return (
        <FormProvider {...methods} >
            <PortalView>

                <div name="ApplicationsPage" className="oregon-promise">
                    <AppHeader program={program} year={year} />
                    {showConfirmation ? (
                        <CompleteSubmission onEdit={onEdit} details={{
                            title: program.name,
                            year: year,
                            description: program.description,
                            code: PROGRAM_CODE
                        }} />
                    ) : (
                        <div className="wrapper">

                            <div className="progress-bar rhythm">
                                <h3 className="heading heading--h3">
                                    Application Progress
                                </h3>

                                <ProgressBar progress={pecentComplete} />

                                <div className="progress-bar__labels">
                                    <ProgressBarLabel title="Profile" state="complete" />
                                    {sectionGroup.sections.filter(x => x.title != "Transcripts").map((section, index) =>
                                        <ProgressBarLabel key={`section${section.title}${index}`} title={section.title} state={section.completionState} sectionRef={section.ref} />
                                    )}
                                    <ProgressBarLabel title="Submit" state={app.isSubmitted ? "complete" : "incomplete"} sectionRef={submitRef} />
                                </div>
                            </div>


                            {/* Questions */}
                            <div className="form-wrapper rhythm" ref={cohortSection.ref}>

                                <FormSection title='Community College Start Term' isEditable={true} loading={false}
                                    status={<SectionStateIcon section={cohortSection} />}>

                                    <div className="table-wrapper">
                                        <CohortTable name={FIELDS.COMM_COLLEGE_START_TERM} listItems={cohortOptions || []} />
                                    </div>

                                </FormSection>

                            </div>

                            <div className="form-wrapper rhythm"
                                ref={gpaSection.ref}>
                                <FormSection title='GPA/Transcripts' isEditable={true} loading={false} status={<SectionStateIcon section={gpaSection} />}>

                                    <div className="grid grid--2col">
                                        <div className="rhythm">
                                            <h4 className="heading heading--h4">GPA Requirement:</h4>
                                            <p><strong>{parseFloat(minimumGpa).toFixed(1)}</strong> or higher</p>
                                            <em>(cumulative, unweighted GPA)</em>
                                        </div>

                                        <div className="rhythm">
                                            <h4 className="heading heading--h4">GED<sup style={{ verticalAlign: "super", fontSize: ".9rem" }}>&reg;</sup> Requirement:</h4>
                                            <p><strong>0 - 800</strong></p>
                                            <em>(all four tests)</em>
                                        </div>
                                    </div>

                                    {/* Input Field - High School Transcript */}
                                    <div className="component" >

                                        {!isGed &&
                                            <FieldSection title="High School GPA (cumulative, unweighted through 7th semester)">
                                                <FieldRow>
                                                    <FieldSlot>
                                                        {!disableLetterGrades &&
                                                            <DecimalAnswer disabled={disableLetterGrades}
                                                                isRequired={!disableLetterGrades}
                                                                answer={hsGpaAnswer}
                                                                inputLabel='High School GPA' placeholder='High School GPA' />
                                                        }
                                                    </FieldSlot>
                                                    <FieldSlot container alignItems="flex-end">
                                                        <div>
                                                            <Checkbox onChange={toggleLetterGrades}
                                                                value={noLetterGrades}
                                                                disabled={hsGpaAnswer.IsLocked}
                                                            /> <span>My high school did not assign letter grades.</span>
                                                        </div>
                                                    </FieldSlot>
                                                </FieldRow>
                                            </FieldSection>
                                        }

                                        {gpaWarning === true && selectedDeadline ?
                                            <div>
                                                <Alert severity="warning">
                                                    <AlertTitle>GPA Warning</AlertTitle>
                                                    You must have at least a {parseFloat(minimumGpa).toFixed(1)} GPA by {moment(selectedDeadline).format("MMMM D, YYYY h:mm A")}
                                                </Alert>
                                            </div> : ""
                                        }

                                        <div className="component rhythm">
                                            <h5 className="heading heading--h5">
                                                OSAC needs to check your GPA or verify your HS/GED<sup style={{ verticalAlign: "super", fontSize: ".9rem" }}>&reg;</sup> transcript. Choose one option to complete this.
                                            </h5>
                                        </div>
                                        {transcriptInstructions}
                                        <TranscriptQuestion
                                            section={gpaSection}
                                            transcriptField={TRANSCRIPT_QUESTIONS.PROOF_OF_GPA}
                                            isRequired={true}
                                            verifyOptionLabel="I will request my high school registrar to verify my GPA."
                                            formLink={
                                                <div>
                                                    You may optionally print this
                                                    <GpaVerificationFormLink app={applicationData} />
                                                    and submit it to your registrar
                                                </div>
                                            }
                                        />

                                    </div>

                                </FormSection>

                            </div> {/* form-wrapper */}

                            <div ref={submitRef}>
                                <div className="grant-action-buttons" ref={submitRef}>
                                    <Button
                                        name="Cancel"
                                        label="Cancel"
                                        variant="text"
                                        onClick={handleCancel}
                                    />
                                    <Button                                        
                                        name="Save"
                                        label="Save"                                       
                                        type="submit"
                                        onClick={handleSubmit(onSave, onError)}
                                    />
                                    <ApplicationSubmitButton                                   
                                        app={app}
                                        sections={sectionGroup}
                                        onClick={actualSubmit}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </PortalView>
        </FormProvider>
    );
}




export default OregonPromise;
