import {FieldRow, FieldSection, FieldSlot} from "../../Forms/FieldSection";
import _get from "lodash.get";
import DropdownAnswer from "../DropdownAnswer";

/**
 * render standing class stading / year in college question
 * 
 * @param {*} param0.answer dto as provided by the API
 * @returns {Component}
 */
function ClassStanding({answer}){
        
    return (
        <FieldSection title={`College Standing as of Fall Term ${answer.Year}`} withDivider={true}>
            <FieldRow>
                <FieldSlot>
                    <DropdownAnswer isRequired={true} answer={answer} label="College Standing" placholder="College Standing" />
                </FieldSlot>
            </FieldRow>
        </FieldSection>
    );
}

export default ClassStanding;