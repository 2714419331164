import ReadOnlyField from '../ReadOnlyField';
import { FIELDS, getField } from '../../store/form/fields';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actions } from '../../store';
import Checkbox from '../Checkbox';
import ConvertCode from '../Utility/ConvertCode';

export default function AppSnapshotGuard ({ year }) {

  const formState = useSelector((state) => state.form);
  
  const fieldKeys = [
    FIELDS.FIRST_NAME,
    FIELDS.LAST_NAME,
    FIELDS.OTHER_NAMES,
    FIELDS.MIDDLE_INITIALS,
    FIELDS.BIRTHDATE,
    FIELDS.PRIMARY_CONTACT,
    FIELDS.ALTERNATE_PHONE,
    FIELDS.HOME_PHONE,
    FIELDS.PERMANENT_ADDRESS,
    FIELDS.MAILING_ADDRESS,
    FIELDS.SSN,
    FIELDS.PRIVACY_AGREEMENT,
    FIELDS.CORRECTIONAL_FACILITY_CC,
    FIELDS.FINISHED_BACHELORS_DEGREE,
    FIELDS.NATIONAL_GUARD__UNIT,
    FIELDS.NATIONAL_GUARD__CREDITS_PRESUMMER,
    FIELDS.NATIONAL_GUARD__CREDITS_FALL,
    FIELDS.NATIONAL_GUARD__CREDITS_WINTER,
    FIELDS.NATIONAL_GUARD__CREDITS_SPRING,
    FIELDS.NATIONAL_GUARD__CREDITS_POSTSUMMER,
    FIELDS.COLLEGE_DUAL_ENROLLED
  ];

  useEffect(() => {
    actions.fetchBasicFields(fieldKeys, year);
  },[]);

  const get = (fieldKey) => formState.fields[fieldKey]?.value;

  const getAddress = (fieldKey) => {
    const street = formState.fields[fieldKey]?.Address.value;
    const city = formState.fields[fieldKey]?.CityId.value;
    const county = formState.fields[fieldKey]?.CountyId.value;
    const zip = formState.fields[fieldKey]?.Zip.value;

    return { street, city, county, zip};
  };

  const getNames = (fieldKey) => {
    const stateObject = formState.fields[fieldKey];
    let string = '';
    for (const i in stateObject) {
        string += stateObject[i].FirstName.value+' '+stateObject[i].LastName.value+'\n'
    }
    return string;
  };

  return (
    <>
      <ReadOnlyField 
        name="name" 
        value={get(FIELDS.FIRST_NAME)+' '+get(FIELDS.LAST_NAME)}
      />
      <ReadOnlyField 
        name={getField(FIELDS.OTHER_NAMES).question}
        value={getNames(FIELDS.OTHER_NAMES)}
      />
      <ReadOnlyField 
        name="Birthday" 
        value={get(FIELDS.BIRTHDATE)}
      />
      <ReadOnlyField 
        name="Phone number" 
        value={
          ( get(FIELDS.ALTERNATE_PHONE) ? get(FIELDS.ALTERNATE_PHONE)+' (Mobile)\n' : '') + 
          ( get(FIELDS.HOME_PHONE) ? get(FIELDS.HOME_PHONE)+' (Home)\n' : '')
        }
      />
      <ReadOnlyField 
        name="permanent address"
        value={
          <ConvertCode 
            isAddress={true} 
            address={getAddress(FIELDS.PERMANENT_ADDRESS)}
          />
        }
      />
      <ReadOnlyField 
        name="mailing address"
        value={
          <ConvertCode 
            isAddress={true} 
            address={getAddress(FIELDS.MAILING_ADDRESS)}
          />
        }
      />
      <ReadOnlyField 
        name="social security number"
        value={get(FIELDS.SSN) ? '*********' : ''} 
      />

      <ReadOnlyField 
        name={`will you have earned your bachelor's degree by fall ${year}`}
        value={
          ( get(FIELDS.FINISHED_BACHELORS_DEGREE) === "True" || get(FIELDS.FINISHED_BACHELORS_DEGREE) === true ) ? 'Yes' : 
          ( get(FIELDS.FINISHED_BACHELORS_DEGREE) === "False" || get(FIELDS.FINISHED_BACHELORS_DEGREE) === false ) ? 'No' : ''
        }
      />  
      <ReadOnlyField
        name="Assigned unit"
        value={get(FIELDS.NATIONAL_GUARD__UNIT)}
      />
      <ReadOnlyField
        name={`Anticipated credits summer ${year}`}
        value={
          get(FIELDS.NATIONAL_GUARD__CREDITS_PRESUMMER) === null ? 'Not Attending' :
          get(FIELDS.NATIONAL_GUARD__CREDITS_PRESUMMER)
        }
      />
      <ReadOnlyField
        name={`Anticipated credits fall ${year}`}
        value={
          get(FIELDS.NATIONAL_GUARD__CREDITS_FALL) === null ? 'Not Attending' :
          get(FIELDS.NATIONAL_GUARD__CREDITS_FALL)
        }
      />
      <ReadOnlyField
        name={`Anticipated credits winter ${year + 1}`}
        value={
          get(FIELDS.NATIONAL_GUARD__CREDITS_WINTER) === null ? 'Not Attending' :
          get(FIELDS.NATIONAL_GUARD__CREDITS_WINTER)
        }
      />
      <ReadOnlyField
        name={`Anticipated credits spring ${year + 1}`}
        value={
          get(FIELDS.NATIONAL_GUARD__CREDITS_SPRING) === null ? 'Not Attending' :
          get(FIELDS.NATIONAL_GUARD__CREDITS_SPRING)
        }
      />
      <ReadOnlyField
        name={`Anticipated credits summer ${year + 1}`}
        value={
          get(FIELDS.NATIONAL_GUARD__CREDITS_POSTSUMMER) === null ? 'Not Attending' :
          get(FIELDS.NATIONAL_GUARD__CREDITS_POSTSUMMER)
        }
      />
      <div style={{margin: '2rem 1rem'}}>
        <Checkbox 
          useController={false} 
          name={FIELDS.COLLEGE_DUAL_ENROLLED}
          value={get(FIELDS.COLLEGE_DUAL_ENROLLED)}
          disabled
          label="I will be dual enrolled with another school while attending this school."
        />
      </div>
      <div style={{margin: '2rem auto'}} className='divider'/>
      <div style={{margin: '2rem 0 0 1rem', textTransform: 'uppercase', fontSize: '14px', color: '#333333', letterSpacing: '1px'}}>
        <b>Privacy Agreement</b>
      </div>
      <ul className="list">
        <li className="li">
          I authorize OSAC to share my application with scholarship processing and review staff, 
          donors, high school partners, and selection committees for the purpose of determining 
          eligibility and selecting awardees. 
        </li>
        <li className="li">
          I understand that by providing contact information, I am giving my 
          permission to receive application status updates or general reminders.
        </li>
      </ul>
      <div style={{marginLeft: '1rem'}}>
        <Checkbox 
          useController={false} 
          name={FIELDS.PRIVACY_AGREEMENT}
          value={get(FIELDS.PRIVACY_AGREEMENT)}
          disabled
          label="I certify that I have read, understood, and agree to the above certification statements"
        />
      </div>
      <div style={{margin: '2rem auto'}} className='divider'/>
    </>
  );
};